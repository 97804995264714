import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ButtonsModal, Flex, FlexEnd, ModalContent, Overlay, P } from './AddEibStyle';
import GIF1 from '../../../Const/assets/loadingg.gif'
import GIF2 from '../../../Const/assets/done.gif'
import { ImgGid, Success, Success1, SucDiv } from '../../MainFundCollector/InactiveMemberFund/inactiveMemberFundStyle';
import { ImgGidLoad } from './CreateBmiIdStyled';
const LoadingModal = ({ setShowModal, mail }) => {
    const navigation = useNavigate()
   
    // useEffect(() => {
      
    //     const timer = setTimeout(() => {
    //         setShowModal(false)
    //       }, 6000);
    //       return () => clearTimeout(timer);
      
    // }, [])
    
    return (
        <Overlay 
        // onClick={() => { setShowModal(false) }} 
        >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                { mail ? setShowModal(false)
                // <SucDiv>
                //     <ImgGid src={GIF2} alt="gif" />
                //     <Success1>Mail Sent</Success1>
                // </SucDiv> 
                :<SucDiv>
                    <ImgGidLoad src={GIF1} alt="gif" />
                    <Success1>Sending Mail...</Success1>
                </SucDiv>}
            </ModalContent>
        </Overlay>
    )
}
export default LoadingModal

