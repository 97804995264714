import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  getActiveMembersList,
  getAllBmiId,
  getCompanyDetails,
  getSipById,
  getUserExpenseDetails,
  InsertCompanyTransaction,
  InsertUserTransaction,
  ListAmount,
  selectfundcollector,
} from '../../../Const/Api/Api';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import {
  Button,
  CollecDiv,
  CollectedDiv,
  Container,
  ContentDiv,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  FormGroup,
  FormRow,
  FormSubRow,
  ImgDiv,
  Label,
  MenuBar,
  MenuText,
  SubHeading,
  TableContainer,
  TableDiv,
} from '../MainFundCollector.styled';
import { Dropdown1 } from './FundCollection.styled';
import Select from 'react-select';
import { Colors } from '../../../Const/Colors/Theme';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import { CollectorDiv } from '../FundRecievedCollectors/FundRecievedCollectorsStyles';
import ConfirmMemberTransactions from '../../Treasurer/Modals/ConfirmMemberTransactions';
import { numberFormatter } from '../../../NumberFormatter';

function FundCollection() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [type, setType] = useState('text');
  const [amountType, setAmountType] = useState(null);
  const [bmiId, setBmiId] = useState('');
  const [sipDetails, setSipDetails] = useState([]);
  const [expenseDetails, setExpenseDetails] = useState([]);
  const [dropDowns, setDropDowns] = useState([]);
  const [amountCat, setAmountCat] = useState(0);
  const [amount, setAmount] = useState();
  const [remarks, setRemarks] = useState('');
  const [dateOfPayment, setDateOfPayment] = useState('');
  const [compId, setCompId] = useState('');
  const [collectedFrom, setCollectedFrom] = useState('');
  const [fundColl, setFundColl] = useState([]);
  const [allBmi, setAllBmi] = useState([]);
  const [id, setId] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [compData, setCompData] = useState([]);
  const [selectedComp, setSelectedComp] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // console.log("fundColl[0].id", fundColl[0].id); 

  const user = useSelector((state) => state.login.user);
  const dropDownRef = useRef(null);
  const getSIPById = async () => {
    const resp = selectedOption?.value && (await axios.get(`${getSipById}/${selectedOption?.value}`));

    setSipDetails(resp?.data);
  };

  const getExpenseById = async () => {
    const resp = selectedOption?.value && (await axios.get(`${getUserExpenseDetails}/${selectedOption?.value}`));

    setExpenseDetails(resp?.data);
  };

  // console.log('fundColl: ', user?.id);
  // const getFundCollect = () => {
  //   axios
  //     .get(`${selectfundcollector}/${localStorage.getItem('userid')}`)
  //     .then((resp) => {
  //       setFundColl(resp?.data);
  //     });
  // };

  const getCompDet = () => {
    const data = [];
    axios.get(getCompanyDetails).then((resp) => {

      resp?.data?.filter((item) => item?.status == 'Active').map((i) => {
        data.push({
          value: i?.company_id,
          label: i?.company_name,
        });
        setCompData(data);
      })




      // resp?.data?.map((i) => {
      //   data.push({
      //     value: i?.company_id,
      //     label: i?.company_name,
      //   });
      //   setCompData(data);
      // });
    });
  };

  const getAllBmi = () => {
    const data1 = [];
    axios.get(getActiveMembersList).then((resp) => {
      // let array = resp?.data?.filter((i) => i?.verify == 1)
      // console.log('array: ', array);
      resp?.data?.map((i) => {
        console.log('i:333 ', i);
        data1.push({
          value: i?.id,
          label: `${i?.full_name}-${i?.bmi_id}`,
        });
      });
      setAllBmi(resp?.data);
      setId(data1);
    });
  };

  const updateHandler = async () => {
    // setDisableButton(true)
    setShowModal(true);
  };

  const approvedTransaction = async () => {
    if (amountType === 'Investment Return' || amountType == 'Profit') {
      setClicked(true)
      const data = {
        company_id: selectedComp?.value,
        amount,
        date: dateOfPayment.split('-').reverse().join('-'),
        fund_collector_id: user?.id,
        amount_cat_id: amountCat,
        remarks,
        collected_from: selectedOption?.value,
      };
      console.log('data: ', data);
      await axios.post(InsertCompanyTransaction, data).then((res) => {
        setClicked(false)
        console.log(res);
        if (res?.data?.code == 200) {
          swal({ text: res?.data?.message, icon: 'success' });
          setCompId('');
          setAmount('');
          setDateOfPayment('');
          setAmountCat('');
          setRemarks('');
          setSelectedOption(null);
          setAmountType(null);
          // setDisableButton(false)
        } else {
          swal({ text: res?.data?.message, icon: 'warning' });
        }
      });
    } else {
      setClicked(true)
      const data = {
        bmi_id: selectedOption?.value,
        amount,
        date: dateOfPayment.split('-').reverse().join('-'),
        fund_collector_id: user?.id,
        amount_cat_id: amountCat,
        remarks,
      };
      console.log('d  ', data);
      await axios.post(InsertUserTransaction, data).then((res) => {
        setClicked(false)
        console.log(res);
        if (res?.data?.code == 200) {
          swal({ text: res?.data?.message, icon: 'success' });
          setCompId('');
          setAmount('');
          setBmiId('');
          setDateOfPayment('');
          setAmountCat('');
          setRemarks('');
          setSelectedOption(null)
          setAmountType(null)
          // setDisableButton(false)
          console.log('hdhgdsjgjklgsajgsd');
        } else if (res?.data?.code == 401) {
          swal({ text: res?.data?.message, icon: 'warning' });
        }
      });
    }
  }

  const getDropDowns = async () => {
    const resp = await axios.get(ListAmount);
    setDropDowns(resp.data);
  };

  useEffect(() => {
    getAllBmi();
    getCompDet();
    if (amountType == 'Sip') {
      getSIPById();
    }
    if (amountType == 'Expense') {
      getExpenseById()
    }
  }, [selectedOption?.value, selectedComp?.value]);

  useEffect(() => {
    getDropDowns();
    // getFundCollect();

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
      setOpenDropDown(false);
    }
  };

  return (
    <Container>
      {
        showModal && <ConfirmMemberTransactions setShowModal={setShowModal} confirmed={approvedTransaction} />
      }
      <MenuBar>
        <MenuText>Fund Collection</MenuText>
      </MenuBar>

      <SubHeading>
        <span>Add Collected Details</span>
        <Button disabled={clicked} onClick={updateHandler}>Update</Button>

      </SubHeading>

      <ContentDiv>
        <FormContainer>
          <Label>Amount Type:</Label>
          <FormRow width='370px' flexDirection='column'>
            <FormGroup>
              <DropDown
                ref={dropDownRef}
                onClick={() => setOpenDropDown(!openDropDown)}
                width='100%'
                style={{ position: 'relative' }}
              >
                <span>{amountType ? amountType : 'Select'}</span>
                <ImgDiv>
                  {openDropDown ? (
                    <img src={BlackArrowUp} alt='' />
                  ) : (
                    <img src={BlackArrowDown} alt='' />
                  )}
                </ImgDiv>
              </DropDown>
              :{' '}
              <input
                type='text'
                placeholder='Enter Amount'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </FormGroup>
            {openDropDown ? (
              <DropDownDrawer>
                {dropDowns?.map((item) => {
                  return (
                    <DropDownItem
                      key={item.id}
                      onClick={() => [
                        setAmountType(item.amount_type),
                        setOpenDropDown(false),
                        setAmountCat(item.id),
                        setSelectedComp(''),
                        setSelectedOption(''),
                      ]}
                    >
                      <span style={{ textDecoration: 'capitalize' }}>
                        {item.amount_type}
                      </span>
                    </DropDownItem>
                  );
                })}
              </DropDownDrawer>
            ) : (
              ''
            )}
            <FormGroup>
              <textarea
                rows={5}
                placeholder='Remarks'
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
          </FormRow>
          <Label>
            {amountType === 'Investment Return' || amountType === 'Profit'
              ? 'Enter Company Details'
              : 'Enter Member Details :'}
          </Label>
          <FormRow
            width='100%'
            flexDirection='row'
            style={{ alignItems: 'end' }}
          >
            <FormSubRow width='370px'>
              <FormGroup>
                {amountType === 'Investment Return' ||
                  amountType === 'Profit' ? (
                  // <input
                  //   type='text'
                  //   placeholder='Enter Company Details'
                  //   value={compId}
                  //   onChange={(e) => setCompId(e.target.value)}
                  // />
                  <div
                    style={{
                      width: '300px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      style={{
                        color: `${Colors.grey}`,
                        fontSize: '14px',
                        paddingBottom: '10px',
                      }}
                    >
                      Enter Company Details
                    </label>
                    <Select
                      defaultValue={selectedComp}
                      onChange={setSelectedComp}
                      options={compData}
                      value={selectedComp}
                    />
                  </div>
                ) : (
                  // (
                  //   <input
                  //     type='text'
                  //     placeholder='Enter BMID'
                  //     value={bmiId}
                  //     onChange={(e) => setBmiId(e.target.value)}
                  //   />
                  // )

                  //   <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                  //     <option value="select">Select BMIP ID </option>

                  //     {allBmi?.map((item) => {
                  //         return (
                  //             <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                  //         )
                  //     }) }
                  // </Dropdown1>
                  <div
                    style={{
                      width: '300px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <label
                      style={{
                        color: `${Colors.grey}`,
                        fontSize: '14px',
                        paddingBottom: '10px',
                      }}
                    >
                      Enter BMIP ID
                    </label>
                    <Select
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={id}
                      value={selectedOption}
                    />
                  </div>
                )}
              </FormGroup>
            </FormSubRow>
            <CollectedDiv>
              <FormSubRow width='150px'>
                <FormGroup>
                  <input
                    style={{ width: 'unset' }}
                    type={type}
                    onFocus={() => setType('date')}
                    // onBlur={() => setType("text")}
                    placeholder='Date of payment'
                    value={dateOfPayment}
                    onChange={(e) => setDateOfPayment(e.target.value)}
                  />
                </FormGroup>
              </FormSubRow>
              {amountType === 'Investment Return' || amountType === 'Profit' ? (
                <FormSubRow width='200px' style={{ marginLeft: '30px' }}>
                  <FormGroup style={{ flexDirection: 'column', alignItems: 'center', gap: '0' }}>
                    <CollecDiv>
                      <label
                        style={{
                          color: `${Colors.grey}`,
                          fontSize: '14px',
                          paddingBottom: '10px',
                        }}
                      >
                        Collected From
                      </label>
                      <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={id}
                        value={selectedOption}
                      />
                    </CollecDiv>
                  </FormGroup>
                </FormSubRow>
              ) : (
                ''
              )}
            </CollectedDiv>
          </FormRow>
          <TableContainer>
            {amountType === 'Sip'
              // || amountType === 'Others' 
              ? (
                <TableDiv width='535px'>
                  <tr>
                    <th
                      colSpan={5}
                      style={{ textAlign: 'left', padding: '20px 20px' }}
                    >
                      Details
                    </th>
                  </tr>
                  <tr>
                    <th>Year</th>
                    <th>Month</th>
                    <th>Amount Type</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                  {sipDetails && sipDetails?.map((item) => {
                    const mL = [
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ];
                    return (
                      <tr key={item.id}>
                        <td>{item?.year}</td>
                        <td>{mL[item?.month - 1]}</td>
                        <td style={{ textTransform: 'uppercase' }}>
                          {item?.amountType}
                        </td>
                        <td>AED {numberFormatter(Math.round(Number(item?.amount) * 100) / 100)}</td>
                        <td>{item?.status}</td>
                      </tr>
                    );
                  })}
                </TableDiv>
              ) : (
                ''
              )}

            {amountType === 'Expense' ? (
              <TableDiv width='535px'>
                <tr>
                  <th
                    colSpan={5}
                    style={{ textAlign: 'left', padding: '20px 20px' }}
                  >
                    Details
                  </th>
                </tr>
                <tr>
                  <th>Date</th>
                  <th>Deposit</th>
                  <th>expense</th>
                  <th>Balance</th>
                  <th>Remarks</th>
                </tr>
                {expenseDetails?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item?.date}</td>
                      <td>AED {numberFormatter(Math.round(item?.deposit * 100) / 100)}</td>
                      <td>{numberFormatter(Math.round(item?.expense * 100) / 100)}</td>
                      <td>AED {numberFormatter(Math.round(item?.balance * 100) / 100)}</td>
                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
              </TableDiv>
            ) : (
              ''
            )}
          </TableContainer>
        </FormContainer>
      </ContentDiv>
    </Container>
  );
}

export default FundCollection;
