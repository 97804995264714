import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  CountDiv1,
  Item,
  LinkBtn,
  NavOption,
  Sidenav,
} from './SideNav.styled';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { FundApproval, selectfundcollector } from '../../../Const/Api/Api';
import axios from 'axios';
import {
  Buttons,
  CountDiv,
  RequestsDiv,
} from '../../Treasurer/SideNavbarStyled';
import { useSelector } from 'react-redux';

function SideNav() {
  const [isFundCollector, setIsFundCollector] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  const [data, setData] = useState();
  console.log('data: ', data);
  const user = useSelector((state) => state.login.user);
  const Role = useSelector((state) => state.login.role);
  const fundCollector = () => {
    setIsFundCollector((isFundCollector) => !isFundCollector);
  };
  const { fundRecievedFromCollectors, inactiveMemberFund, InvestmentFund, expenseCount, zakatCount } =
    useSelector((state) => state.notification);

  const [fundColl, setFundColl] = useState([]);
  console.log('fundColl: ', fundColl);
  const { menuOpen } = useSelector((state) => state.others);

  const getFundReq = () => {
    axios
      .get(FundApproval)
      .then((resp) => {
        if (resp?.data?.code == 200) {
          setData(resp?.data);
          console.log('resp?.data?.length: ', resp?.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    axios
      .get(`${selectfundcollector}/${localStorage.getItem('userid')}`)
      .then((resp) => {
        console.log('resp:1211 ', resp);
        setFundColl(resp?.data);
      });
    getFundReq();
  }, []);

  console.log('Role: ', Role);

  console.log('user: ', user);

  return (
    <Container menuOpen={menuOpen}>
      <Sidenav>
        {Role == 'fundcollector' || Role == 'superfundcollector' ? (
          <NavOption>
            <LinkBtn
              click={
                location.pathname === '/fund-collector/fund-collection' ||
                  location.pathname === '/fund-collector/fund-transfer-details'
                  ? true
                  : false
              }
              onClick={fundCollector}
            >
              <AiOutlinePlusSquare />
              Fund Collector
            </LinkBtn>

            <Item>
              <Button
                click={
                  location.pathname == '/fund-collector/fund-collection'
                    ? true
                    : false
                }
                onClick={() => {
                  navigation('fund-collection');
                }}
              >
                <span>-</span> Fund Collection
              </Button>
              <Button
                click={
                  location.pathname == '/fund-collector/fund-transfer-details'
                    ? true
                    : false
                }
                onClick={() => {
                  navigation('fund-transfer-details');
                }}
              >
                <span>-</span> Fund Transfer Details
              </Button>
            </Item>
          </NavOption>
        ) : (
          ''
        )}
        {(Role == 'mainfundcollector' || Role == 'superfundcollector') && (
          <NavOption>
            <LinkBtn
              click={
                location.pathname === '/fund-collector/inactive-member-fund' ||
                  location.pathname ===
                  '/fund-collector/investment-fund-approval' ||
                  location.pathname === '/fund-collector/expense' ||
                  location.pathname === '/fund-collector/zakat'
                  ? true
                  : false
              }
              onClick={fundCollector}
            >
              <AiOutlinePlusSquare />
              Transfer From Bank
            </LinkBtn>
            <Item>
              <RequestsDiv>
                <Buttons
                  click={
                    location.pathname == '/fund-collector/inactive-member-fund'
                      ? true
                      : false
                  }
                  onClick={() => {
                    navigation('inactive-member-fund');
                  }}
                >
                  <span>-</span> Inactive Member fund settlement
                </Buttons>
                {inactiveMemberFund ? (
                  <CountDiv1 style={{ marginLeft: 'unset' }}>
                    <p>{inactiveMemberFund}</p>
                  </CountDiv1>
                ) : null}
              </RequestsDiv>

              <RequestsDiv>
                <Buttons
                  click={
                    location.pathname ==
                      '/fund-collector/investment-fund-approval'
                      ? true
                      : false
                  }
                  onClick={() => {
                    navigation('investment-fund-approval');
                  }}
                >
                  <span>-</span> Investment fund approval
                </Buttons>
                {InvestmentFund ? (
                  <CountDiv style={{ marginLeft: 'unset' }}>
                    <p>{InvestmentFund}</p>
                  </CountDiv>
                ) : null}
              </RequestsDiv>
              <Button
                click={
                  location.pathname == '/fund-collector/expense' ? true : false
                }
                onClick={() => {
                  navigation('expense');
                }}
              >
                <span>-</span>Expense
                {expenseCount ? (
                  <CountDiv1 style={{ marginRight: '1rem' }}>
                    <p>{expenseCount}</p>
                  </CountDiv1>
                ) : null}
              </Button>
              <Button
                click={
                  location.pathname == '/fund-collector/zakat' ? true : false
                }
                onClick={() => {
                  navigation('zakat');
                }}
              >
                <span>-</span>Zakat
                {zakatCount ? (
                  <CountDiv1 style={{ marginRight: '1rem' }}>
                    <p>{zakatCount}</p>
                  </CountDiv1>
                ) : null}
              </Button>
            </Item>
            <LinkBtn
              click={
                location.pathname === '/fund-collector/fund-recieved-collectors'
                  ? true
                  : false
              }
              onClick={fundCollector}
            >
              <AiOutlinePlusSquare />
              Transfer To Bank
            </LinkBtn>{' '}
            <Item>
              <Button
                click={
                  location.pathname ==
                    '/fund-collector/fund-recieved-collectors'
                    ? true
                    : false
                }
                onClick={() => {
                  navigation('fund-recieved-collectors');
                }}
              >
                <span>-</span> Fund received from collectors
                {fundRecievedFromCollectors ? (
                  <CountDiv1 style={{ marginRight: '1rem' }}>
                    <p>{fundRecievedFromCollectors}</p>
                  </CountDiv1>
                ) : null}
              </Button>
            </Item>
          </NavOption>
        )}
      </Sidenav>
    </Container>
  );
}

export default SideNav;
