import React, { useState } from 'react'
import { BsCalendar4, BsX } from "react-icons/bs";
import { DateDiv, ModalContent, Overlay } from './AddEibStyle';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {
  First,
  Second,
  Div
} from './CreateBmiIdStyled'
import axios from 'axios';
import { insertBmiId } from '../../../Const/Api/Api';
import swal from 'sweetalert';
import { displayDate } from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import moment from 'moment';
function CreateBmiId({ setShowModal, getBmiId, len }) {
  let bmi_id = String(len + 1).padStart(3, '0')
  let padded_bmi_id = 'BMIP#' + `${bmi_id}`
  let curr = new Date();
  let date = curr.toISOString().substring(0, 10);
  // let formattedDate = moment(date).format('DD-MM-YYYY')
  console.log("date=>", curr, date);
  const [startDate, setStartDate] = useState(date)
  console.log('startDate: ', startDate);
  // const [values, setValues] = useState({
  //   bmi_id: padded_bmi_id,
  //   name: "",
  //   email: "",
  //   contact: "",
  //   joining_date: date,
  // })
  const [bmiId, setBmiId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  // console.log('joining_date: ', displayDate(values.joining_date));
  // console.log('values343: ', values);
  // console.log("LEngth=>", len);
  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setValues({
  //     ...values,
  //     [name]: value
  //   })
  // }
  const handleSubmit = () => {
    axios({
      method: "post",
      url: `${insertBmiId}`,
      data: {bmi_id : padded_bmi_id, name: name, email: email, contact: contact, joining_date: moment(startDate).format('DD-MM-YYYY')}
    }).then(resp => {
      console.log(resp.data)
      if (resp?.data.code == 200) {
        swal({ text: "Inserted Successfully", icon: "success" })
        setShowModal(false)
        getBmiId()
      }
      if (resp?.data.code == 401) {
        swal({ text: resp.data.message, icon: "error" })
      }
    }).catch((err) => {
      console.log(err);
      swal({ text: "Error", icon: "error" })
    })
  }

 
  return (
    <Overlay onClick={() => { setShowModal(false) }} >
      <ModalContent onClick={(e) => { e.stopPropagation() }}>
        <First>
          <p>Create BMIP ID</p><BsX size={25} onClick={() => { setShowModal(false) }} style={{ cursor: "pointer" }} />
        </First>
        <hr />
        <Second>
          <Div>
            <label>BMIP ID</label>
            {/* <input type='text' name="bmi_id" value={values.bmi_id} onChange={(e) => handleChange(e)} /> */}
            <input type='text' name="bmi_id" value={padded_bmi_id} />
          </Div>
          <Div>
            <label>Member Name</label>
            <input type='text' name="name" value={name} onChange={(e) => setName(e.target.value)} />
          </Div>
          <Div>
            <label>Joining date</label>
            {/* <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            <BsCalendar4 style={{ marginRight: '5px' }} /> */}
            <input type="date" name="joining_date" value = {startDate} onChange={(e) => setStartDate(e.target.value)}/>
          </Div>
          <Div>
            <label>Email Address</label>
            <input type='email' name="email" value={email} onChange={(e) => setEmail(e.target.value) } />
          </Div>
          <Div>
            <label>Contact Number</label>
            <input type='text' name="contact" value={contact} onChange={(e) => setContact(e.target.value)} />
          </Div>
          <Div> <button onClick={() => {
            handleSubmit()
          }}>Create</button></Div>

        </Second>
      </ModalContent>
    </Overlay>
  )
}

export default CreateBmiId
