import axios from 'axios';
import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { EditUserTransaction } from '../../../Const/Api/Api';
import { Container, Modal, First, Second, Divs } from '../../SuperAdmin/Modal/AssignFundStyled';
import swal from 'sweetalert';

function EditAmount({ setShowEditModel, getData, amountCategoryId = '', editAmount = '', transactionId = '', bmiId = '' }) {
    const [amount, setAmount] = useState(editAmount)

    const SumbitHandler = async () => {
        const data = {
            transaction_id: transactionId,
            amount_cat_id: amountCategoryId,
            amount: amount,
            bmi_id: bmiId,
        };

        await axios({
            method: 'POST',
            url: EditUserTransaction,
            data,
        })
            .then((response) => {
                if (response?.data?.code == 200) {

                    setShowEditModel(false)
                    getData()
                    swal({ text: response?.data?.message, icon: 'success' });
                } else {
                    swal({ text: response?.data?.message, icon: 'warning' });
                }
            })
            .catch((err) => {
                swal({ text: 'Something went wrong. Please try later', icon: 'error' });
            });
    };

    return (
        <Container>
            <Modal>
                <First>
                    <p>Edit Amount</p>
                    <BsX size={25} onClick={() => setShowEditModel(false)} />
                </First>
                <hr />
                <Second>
                    <Divs>
                        <label style={{ paddingBottom: '10px' }}>{amountCategoryId == 1 ? 'Edit SIP' : (amountCategoryId == 2 ? 'Edit Expense' : (amountCategoryId == 3 ? 'Edit others' : (amountCategoryId == 4 ? 'Edit investment return' : 'Edit profit')))}</label>
                    </Divs>
                    <Divs>
                        <input
                            type='text'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </Divs>
                    <button onClick={SumbitHandler}>submit</button>
                </Second>
            </Modal>
        </Container>
    );
}

export default EditAmount;
