import styled from "styled-components";
import { Colors, size } from "../../../Const/Colors/Theme";

export const Container = styled.div`
  margin-top: 100px;
  margin-left: 250px;
  width: 100%;
  @media screen and (max-width: ${size.Laptop}) {
    margin-left: 0;
  }
`;

export const MenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
  width: auto;
  background-color: ${Colors.white};
  align-items: center;
  padding: 30px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media screen and (max-width: ${size.Tablet}) {
    flex-direction: ${(props) => (props.secondary ? "column" : "row")};
    row-gap: 20px;
  }
  @media screen and (max-width: ${size.MobileM}) {
    padding: 30px 20px;
  }
`;
export const MenuText = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-right: 10px;
  @media screen and (max-width: ${size.Tablet}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${size.MobileM}) {
    font-size: 17px;
  }
`;
export const MenuRightDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;
export const RightItemsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
export const DateWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  input {
    padding: 8px 5px;
    text-align: center;
    border: 1px solid ${Colors.border};
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
  }
  span {
    @media screen and (max-width: ${size.MobileM}) {
      /* width: 100%; */
    }
  }
  @media screen and (max-width: ${size.MobileM}) {
    flex-direction: column;
  }
`;
export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  padding: 20px;
  margin: 0 30px;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid ${Colors.border};
  @media screen and (max-width: ${size.Tablet}) {
    font-size: 18px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    font-size: 16px;
    margin: 0 10px;
  }
`;
export const Button = styled.button`
  background-color: ${Colors.blue};
  padding: 14px 60px;
  border: none;
  border-radius: 10px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  @media screen and (max-width: ${size.Tablet}) {
    padding: 12px 40px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    padding: 10px 20px;
    font-size: 12px;
  }
`;

export const Buttons = styled.button`
  background-color: ${Colors.blue};
  padding: 10px 60px;
  border: none;
  border-radius: 6px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  @media screen and (max-width: ${size.Tablet}) {
    padding: 12px 40px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    padding: 10px 20px;
    font-size: 12px;
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 50px;
  @media screen and (max-width: ${size.MobileL}) {
    margin: 20px 20px;
  }
`;
export const ContentDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 50px;
  @media screen and (max-width: ${size.MobileL}) {
    margin: 20px 20px;
  }
`;
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;
export const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
`;
export const DropDown = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colors.border};
  padding: 10px 15px;
  width: ${(props) => props.width};
  border-radius: 5px;
  align-items: center;
  span {
    font-size: 14px;
  }
`;
export const DropDownDrawer = styled.div`
  position: absolute;
  display: flex;
  padding-top: 5px;
  flex-direction: column;
  width: auto;
  height: auto;
  margin-top: 50px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.border};
  border-radius: 5px;
  /* box-shadow: 2px 2px 5px ${Colors.border}; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  span {
    cursor: pointer;
    padding: 5px;
  }
`;
export const DropDownItem = styled.div`
  margin: 0 5px;
  padding: 5px;
  width: auto;
  border-bottom: 1px solid ${Colors.border};
  :nth-child(5) {
    border-bottom: none;
  }
`;

export const ImgDiv = styled.div`
  cursor: pointer;
  img {
    width: 12px;
    object-fit: contain;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: ${(props) => props.width};
  input {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    width: 100%;
  }
  textarea {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    resize: none;
    width: 100%;
  }
  input[type="date"] {
    width: 100%;
  }
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  gap: 15px;
  width: ${(props) => props.width};
  flex-wrap: wrap;
  @media screen and (max-width: ${size.Tablet}) {
    width: auto;
  }
`;
export const FormSubRow = styled.div`
  width: ${(props) => props.width};
  @media screen and (max-width: ${size.Tablet}) {
    width: 100%;
  }
`;
export const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow-x: auto; */
  @media screen and (max-width: ${size.Tablet}) {
    overflow-x: auto;
  }
`;
export const TableDiv = styled.table`
  width: ${(props) => props.width};
  border: 1px solid ${Colors.border};
  border-collapse: collapse;
  border-radius: 5px;
  th {
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid ${Colors.border};
    border-top: 1px solid ${Colors.border};
  }
  td {
    text-align: center;
    font-size: 15px;
    font-weight: 400px;
    padding: 10px;
  }
  @media screen and (max-width: ${size.Tablet}) {
    width: 100%;
  }
`;

export const TableTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
  /* position: absolute; */
  margin-top: 10px;
  gap: 20px;
  width: ${(props) => props.width};
  input {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    width: 100%;
  }
  a {
    padding-right: 25px;
  }
  @media screen and (max-width: 1250px) {
    position: relative;
  }
  @media screen and (max-width: ${size.Tablet}) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const TableTopItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  width: auto;
  justify-content: center;

  p {
    font-size:14px;
    color:${Colors.black}
  }
`;

export const Click = styled.span`
font-size:14px;
color:${Colors.blue};
text-decoration-line: underline;
cursor:pointer;
`
export const Clicks = styled.span`
font-size:14px;
color:${Colors.blue};
// text-decoration-line: underline;
`