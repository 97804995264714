import axios from 'axios';
import React, { useState } from 'react'
import { BsX } from "react-icons/bs";
import { getFundCollectorById, InsertMainFundcollector } from '../../../Const/Api/Api';
import { Dropdown1 } from '../../MainFundCollector/FundCollection/FundCollection.styled';
import {
  Container,
  Modal,
  First,
  Second,
  Div,
  Divs
} from './AssignMainFundStyled'
import { DivDate } from './AssignTreasurerStyled';
import Select from 'react-select'
import swal from 'sweetalert';
import { useEffect } from 'react';

function EditMainFund({ setAssign, bmi_id, name, bmipno, starting_date, allBmi, ids, getUsers, fund_collector_activity }) {
  console.log('name: 12', name);
  console.log('ids: ', ids);

  const [bmiId, setBmiId] = useState(bmi_id)
  const [nameE, setNameE] = useState(name)
  const [userData, setUserData] = useState([])
  const [joiningDate, setJoiningDate] = useState(starting_date)
  const [fundActivity, setFundActivity] = useState(fund_collector_activity)
  const [selectedOption, setSelectedOption] = useState({ value: bmiId, label: bmipno });
  console.log('selectedOption: ', selectedOption);

  console.log("fund_collector_activity", fund_collector_activity);
  const SumbitHandler = () => {
    const data = {
      bmi_id: selectedOption.value,
      starting_date: joiningDate,
      previous_bmi_id: bmi_id,
      fund_collector_activity: fundActivity,
      id: userData?.id
    }
    console.log('data:11 ', data);
    axios({
      method: 'POST',
      url: InsertMainFundcollector,
      data: data
    }).then(response => {
      console.log(response, "pop");

      if (response?.data?.code == 200) {
        swal({ text: "Main fund collector assigned", icon: "success" })
        getUsers()
        setAssign(false);
      } else {
        swal({ text: response?.data?.message, icon: "warning" })

      }
    }).catch(err => {
      swal({ text: 'Error', icon: "error" })
      console.log(err.message, "pop")
    })
  }
  let arrr = allBmi?.filter(i => i?.id == selectedOption?.value)
  console.log('arrr: ', arrr);


  return (
    <Container>
      <Modal>
        <First>
          <p>Edit Main Fund Collector</p><BsX size={25} onClick={() => setAssign(false)} style={{ cursor: "pointer" }} />
        </First>
        <hr />
        <Second>
          <Div>
            <input type="radio" id='fund' name='fund' value='1' onChange={(e) => setFundActivity(e.target.value)} checked={fundActivity == 1 && 'checked'} />
            <label htmlFor='fund'>Main Fund Collector + Fund Collector</label>
          </Div>
          <br />
          <Div>
            <input type="radio" id='fund1' name='fund1' value='0' onChange={(e) => setFundActivity(e.target.value)} checked={fundActivity == 0 && 'checked'} />
            <label htmlFor='fund1'>Main Fund Collector </label>
          </Div>

          {/* <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                                    <option value= "">{`${bmipno} - ${name}`} </option>

                                    {allBmi?.map((item) => {
                                          return (
                                                <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                                          )
                                    })}
                              </Dropdown1> */}
          <Divs>
            <label style={{ paddingBottom: "10px" }}>BMIP ID</label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={ids}
              value={selectedOption}
            />
          </Divs>
          <Divs>
            <label>Member Name</label>
            <input type="text" style={{ pointerEvents: "none" }} value={selectedOption?.value ? allBmi?.filter(i => i?.id == selectedOption?.value)[0]?.full_name : name} onChange={e => setNameE(e.target.value)} />
          </Divs>
          <DivDate>
            <label>Starting date</label>
            <input type="date" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
          </DivDate>
          <button onClick={SumbitHandler}>submit</button>
        </Second>

      </Modal>


    </Container>
  )
}

export default EditMainFund