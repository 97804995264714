import styled from "styled-components";
import { Colors, size } from "../../../Const/Colors/Theme";

export const CollectorDiv = styled.div`
    width: 100%;
    height: 100%;
`
export const TransferBtn = styled.button`
    background-color: ${(props) => (props.disabled ? `${Colors.grey}` :`${Colors.blue}`)};
  padding: 14px 10px;
  border: none;
  border-radius: 10px;
  color: ${Colors.white};
  font-size: 14px;
  font-weight: 400;
  
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  @media screen and (max-width: ${size.Tablet}) {
    padding: 12px 2px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    padding: 10px 2px;
    font-size: 12px;
  }
`