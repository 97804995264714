import React, { useEffect, useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
  MailBtn,
} from './BMIDCreationStyled';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import { CreateBMID } from '../../Treasurer/Modals/Portals';
import axios from 'axios';
import { getAllBmiId, reSendmail, sendMailToUser } from '../../../Const/Api/Api';
import { LoadingMod } from '../../Treasurer/Modals/Portals';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import * as XLSX from 'xlsx'
import { displayDate } from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';


function BMIDCreation() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [type, setType] = useState('text');
  const [amountType, setAmountType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bmiId, setBmiId] = useState([]);
  console.log('bmiId: ', bmiId.length);
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState();

  const getBmiId = async () => {
    const data = [];
    const resp = await axios.get(`${getAllBmiId}`);
    console.log(resp.data, 'aeea');
    resp?.data?.map((item) => {
      data.push({
        bmi_id: item?.bmi_id,
        name: item?.name,
        joining_date: displayDate(item?.joining_date),
        email: item?.email,
        contact: item?.contact,
        id: item?.id,
        mail_status: item?.mail_status,
        verify: item?.verify
      });
      setBmiId(data);
    });
  };
  useEffect(() => {
    getBmiId();
  }, []);

  const sendEmail = (id) => {
    console.log('id: ', id);
    axios({
      method: 'get',
      url: `${sendMailToUser}/${id}`,
    }).then((resp) => {
      console.log(resp.data, 'data');
      if (resp?.data?.code == 200) {
        setMail(resp?.data);
        getBmiId();
      }
    });
  };
  const reSendEmail = (id) => {
    console.log('id: ', id);
    axios({
      method: 'get',
      url: `${reSendmail}/${id}`,
    }).then((resp) => {
      console.log(resp.data, 'data');
      if (resp?.data?.code == 200) {
        setMail(resp?.data);
        getBmiId();
      }
    });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id',
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Joining Date',
        accessor: 'joining_date',
      },
      {
        Header: 'Email ID',
        accessor: 'email',
      },
      {
        Header: 'Contact Number',
        accessor: 'contact',
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellInfo) => {
          return (
            <>
              {cellInfo.row.original.mail_status == 1 ? (
                <MailBtn 
                  style={{cursor: cellInfo.row.original.verify == 1 ? 'no-drop' : 'pointer'}}
                  disabled={cellInfo.row.original.verify == 1 ? true : false}
                  onClick={() => {
                    {
                      reSendEmail(cellInfo.row.original.id);
                      setLoading(true);
                      setMail();
                    }
                  }}
                >
                  Re-send Mail
                </MailBtn>
              ) : (
                <Click
                  onClick={() => {
                    {

                      sendEmail(cellInfo.row.original.id);
                      setLoading(true);
                      setMail();
                    }
                  }}
                >
                  Send Mail
                </Click>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(bmiId);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      {loading && <LoadingMod setShowModal={setLoading} mail={mail} />}
      {showModal && (
        <CreateBMID
          setShowModal={setShowModal}
          getBmiId={getBmiId}
          len={bmiId.length}
        />
      )}
      <MenuBar secondary>
        <MenuText>BMIP ID Creation</MenuText>
        <MenuRightDiv>
          {/* <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input type="Date" />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type="Date" />
            </DateWrapper>
          </RightItemsWrapper> */}
          <Button onClick={() => setShowModal(true)}>Create BMIP ID</Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems></TableTopItems>
          <label
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
        </TableTopBar>
        {openDropDown ? (
          <DropDownDrawer>
            <DropDownItem
              onClick={() => [setAmountType('SIP'), setOpenDropDown(false)]}
            >
              <span>SIP</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => [setAmountType('Expense'), setOpenDropDown(false)]}
            >
              <span>Expense</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => [setAmountType('Others'), setOpenDropDown(false)]}
            >
              <span>Others</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => [
                setAmountType('Investment Returns'),
                setOpenDropDown(false),
              ]}
            >
              <span>Investment Returns</span>
            </DropDownItem>
            <DropDownItem
              onClick={() => [setAmountType('Profit'), setOpenDropDown(false)]}
            >
              <span>Profit</span>
            </DropDownItem>
          </DropDownDrawer>
        ) : (
          ''
        )}

        <TableContainer>
          <AdminTable columns={columns} data={bmiId} />
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default BMIDCreation;
