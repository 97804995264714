import React, { useEffect, useState } from 'react'
import { ButtonDiv, FieldDiv, InputField, Inputfield1, Modal, ModalContainer, ModalContainerEd, ModalContent, ModalHeader, StatusDiv, StatusText } from './CompanyDetailsStyles'
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios'
import { UpdateCompany } from '../../../../Const/Api/Api'
import { useSelector } from 'react-redux'
import swal from 'sweetalert';

const EditInvestmentModal = (props) => {
    const [active, setActive] = useState()

    const user = useSelector((state) => state.login.user);
    const [values, setValues] = useState({
        id: props?.compData?.company_id,
        invested_amount: props?.compData?.totalInvestedAmount,
        amount_change: true,
    })

    const handleChange = (e) => {
        const { name, value } = e.target

        setValues({
            ...values,
            [name]: value,
        })
    }
    const handleSubmit = () => {
        axios({
            method: 'post',
            url: UpdateCompany,
            data: values,
        }).then(resp => {
            if (resp?.data?.code == 200) {
                swal({ text: "Updated successfully", icon: "success" })
                props?.getComDetails()
                props.setCompanyModal(false)
            } else {
                swal({ text: resp?.data?.message, icon: "warning" })
            }
        })
    }

    return (
        <Modal onClick={() => props.setEdit(false)}>
            <ModalContainerEd onClick={(e) => { e.stopPropagation() }}>
                <ModalHeader>
                    <h1>Edit Company</h1>
                    <span onClick={() => props.setEdit(false)} ><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>
                    <form onSubmit={() => { handleSubmit(); props.setEdit(false) }}>
                        <FieldDiv>
                            <p>Investment Amount</p>
                            <InputField type="text" name="invested_amount" value={values?.invested_amount} onChange={(e) => handleChange(e)} />
                        </FieldDiv>
                        <ButtonDiv>
                            <button type='submit'>Update</button>
                        </ButtonDiv>
                    </form>
                </ModalContent>
            </ModalContainerEd>
        </Modal>
    )
}

export default EditInvestmentModal