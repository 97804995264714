import React, { useEffect, useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Clicks,
  Buttons,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../../Const/assets';
import axios from 'axios';
import { getCompanyInvestmentBankOut } from '../../../../../Const/Api/Api';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import MembersModal from '../../../CompanyDetails/CompanyDetails/MembersModal';
import { Colors } from '../../../../../Const/Colors/Theme';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../../NumberFormatter';

function AllInvestment() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('');

  const columns = [
    {
      Header: 'Serial No.',
      accessor: 'no',
    },
    {
      Header: 'Invested Date',
      accessor: 'invested_date',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Invested Amount (AED)',
      accessor: 'invested_amount',
    },
    {
      Header: 'Investment Return (AED)',
      accessor: 'investment_return',
    },
    {
      Header: 'Net Investment (AED)',
      accessor: 'net_investment',
    },
    {
      Header: 'No.of Members',
      accessor: 'noOfMembers',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];

  const getAllInvestmentData = () => {
    axios.get(`${getCompanyInvestmentBankOut}`).then((res) => {
      setBankAmount(res?.data?.amount);
      const data1 = [];
      setLoading(true);
      res?.data?.details.reverse().map((item, index) => {
        data1.push({
          no: index + 1,
          invested_date: displayDate(item?.invested_date),
          company_name: item?.company_name,
          invested_amount:item?.invested_amount ? numberFormatter(Math.round(Number(item?.invested_amount) * 100) / 100) : '0',
          investment_return: item?.investment_return ? numberFormatter(Math.round(Number(item?.investment_return) * 100) / 100) : '0',
          net_investment: item?.net_investment ? numberFormatter(Math.round(Number(item?.net_investment) * 100) / 100) : '0',
          // noOfMembers: item?.noOfMembers,
          noOfMembers: (
            <Clicks
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setMemberData(item?.members_list);
                setIsOpen(true);
              }}
            >
              {item?.noOfMembers}
            </Clicks>
          ),
          status: item?.status == 1 ? 'Active' : 'De-active',
        });
        setData(data1);
      });
    });
  };

  useEffect(() => {
    getAllInvestmentData();
  }, []);

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.invested_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  //console.log("bankAmount", bankAmount);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      {isOpen && (
        <MembersModal setMembers={setIsOpen} membersList={memberData} />
      )}
      <MenuBar>
        <MenuText>All Investment</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          {/* <TableTopItems> */}
          {/* <Buttons style={{ padding: "10px 15px" }}>Add Expense</Buttons> */}
          {/* </TableTopItems> */}
          <TableTopItems>
            <p>Amount Available in bank</p>
            <div
              style={{
                width: 'fit-content',
                outline: 'none',
                pointerEvents: 'none',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                padding: '7px',
                borderRadius: '5px',
                fontSize: '14px',
              }}
            >
              {'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}
            </div>
          </TableTopItems>

          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBar>
        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default AllInvestment;
