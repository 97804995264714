import React, { useRef, useState } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { BiSort } from "react-icons/bi";
import { InputElement, SelectElement } from "../../../Const/Input/Input";
import { SaveButtonElement } from "../../../Const/Button/Button";
import { Styles } from "./TableStyles";

function FundCollectorTable({ data, columns, one }) {
  if(Array.isArray(columns[2]?.columns)){

    // columns[2]?.columns.map((value, index) => {
    //   if(columns[2]?.columns[index]){
    //     columns[2]?.columns[index] = {...columns[2]?.columns[index], ...{"columns": 
    //         {
    //           "Header": "Investment",
    //           "accessor": "1",
    //           "status": 1
    //         }
    //       }
    //     }
        
    //   }
    // })
  }

  // console.log(columns)
  const inputRef = useRef(null);
  const [value1, setValue1] = useState();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const settingFilter = (e) => {
    setGlobalFilter(e.target.value);
    setValue1(e.target.value);
    inputRef.current?.focus();
  };

  React.useEffect(() => {
    // inputRef.current?.focus();
  }, []);

  return (
    <Styles>
      {/* <h3>{heading}</h3> */}

      <div className="search-div">
        <InputElement
          ref={inputRef}
          placeholder="Search"
          value={value1}
          onChange={(e) => settingFilter(e)}></InputElement>
      </div>
      {/* </div> */}
      <div style={{ overflowX: 'scroll' }}>
        <table {...getTableProps()} id="my-table" >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {

                  return (
                    <th
                      {...column.getHeaderProps()}
                      rowSpan={`${column.rowSpan ?? 1}`}
                      style={{ display: column.displayNone ? "none" : '' , backgroundColor:  column.status == 2 ? "#fd5a5a" : 'white'  , color: column.status ==2 ? 'white': ''}}
                    >
                      {column.render('Header')}
                    </th>
                  )
                  
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td style={{
                   backgroundColor:  cell.row.original.status == 3 ? '#d0d0d0' : 'white'
                }} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <div className="pagination-buttons">
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canPreviousPage ? "#c4c4c4" : "#c4c4c4"}
            onClick={(e) => {
              e.preventDefault()
              gotoPage(0)
            }}
            disabled={!canPreviousPage}>
            {"<<"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canPreviousPage ? "#c4c4c4" : "#999999"}
            onClick={(e) => {
              e.preventDefault()
              previousPage()
            }}
            disabled={!canPreviousPage}>
            {"<"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canNextPage ? "#c4c4c4" : "#999999"}
            onClick={(e) => {
              e.preventDefault()
              nextPage()
            }}
            disabled={!canNextPage}>
            {">"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canNextPage ? "#c4c4c4" : "#999999"}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}>
            {">>"}
          </SaveButtonElement>
        </div>
        &nbsp;
        <div className="pagination-buttons">
          <span>
            Page&nbsp;
            <strong>
              {pageIndex + 1}&nbsp; of &nbsp; {pageOptions.length}
            </strong>
            &nbsp;
          </span>
          <span>|&nbsp; Go to page: </span>&nbsp;
          <InputElement
            type="number"
            style={{ width: "auto", height: "auto" }}
            placeholder=""
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}></InputElement>
          &nbsp;
          <SelectElement
            style={{ width: "auto", height: "auto" }}
            placeholder=""
            dropdown="true"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </SelectElement>
        </div>
      </div>
    </Styles>
  );
}
export default FundCollectorTable;
