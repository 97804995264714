import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { CheckTable, CheckTableDiv, Modal, ModalContainerR, ModalContent, ModalHeader } from '../../Treasurer/Bank/Approval/ApprovalModalStyled'
import { numberFormatter } from '../../../NumberFormatter'

const ProfitModal = (props) => {
    const Months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <Modal>
            <ModalContainerR>
                <ModalHeader>
                    <h1>{props?.profitData[0]?.company_name}</h1>
                    <span onClick={() => props.setProfitMod(false)}><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>

                    <CheckTableDiv>
                        <CheckTable>
                            <tr>
                                <th>Year</th>
                                <th>Month</th>
                                <th>Profit</th>

                            </tr>
                            {props?.profitData?.map((item, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            {/* <td>{item?.company_name}</td> */}
                                            <td>{item?.year}</td>
                                            <td>{Months[item?.month - 1]}</td>
                                            <td>{numberFormatter(Math.round(Number(item?.profit) * 100) / 100)}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </CheckTable>
                    </CheckTableDiv>
                </ModalContent>
            </ModalContainerR>
        </Modal>

    )
}

export default ProfitModal