import styled from "styled-components";

export const Main = styled.div`
  padding:30px;
  max-width:720px;
  .pagination{
    display:none;
  }
  .search-div{
    width: 52%;
    float: right;
    padding: 1rem;
    padding-right: 1rem;
    padding-right: 0;
    input{
      width:100%
    }
  }
` 

export const JustEnd = styled.div`
  display:flex;
  justify-content:flex-end;
  @media (max-width: 768px) {
    width:180%;
  }
`