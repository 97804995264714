import styled from "styled-components";
import { Colors, fonts, size } from "../../../../Const/Colors/Theme";

export const Amount = styled.div`
 display:flex;
 flex-direction:row;
 span{
    margin-inline:10px;
 }
`



export const Modal = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0,0,0, 0.6);
`
export const ModalContainerR = styled.div`
  width: 60%;
    height: auto;
    padding: 1.1rem;

    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid grey;
    min-width: 300px;
`
export const ModalHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${Colors.blackish_grey};
  margin-bottom: 1rem;
  background-color: ${Colors.white};
  h1{
    font-size: 20px;
    font-weight: 400;
  }
  span{
    cursor: pointer;
  }
`
export const ModalContent = styled.div`
  width: 100%;
  height: auto;
  display:flex;
  flex-direction:row;
  @media (max-height: 540px){
    height: 280px;
    overflow-y: scroll;
    ::-webkit-scrollbar{
    display: none;
  }
  }
  
`
export const CheckTableDiv = styled.div`
  width:100%;
  height: 400px;
  /* background-color: pink; */
  /* display: flex; */
  overflow-x: auto;
  ::-webkit-scrollbar{
    display: none;
  }
  /* justify-content: center; */
  padding-top: 2rem;
`
export const CheckTable = styled.table`
  width: 96%;
  margin: 1rem;
  border: 1px solid ${Colors.blackish_grey};
  text-align: center;
  border-collapse: collapse;
  tr{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
  }
  th{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
    font-weight: 400;
    color: ${Colors.dark_grey};
  }
  td{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
    select{
      border: none;
      outline: none;
      font-size: 16px;
    }
    input{
      border: none;
      outline: none;
      width:100px;
      text-align: center;
      /* background-color: pink; */
    }
  }
  ;
`
export const Icon = styled.div`
   padding-top: 115px;
`