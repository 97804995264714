import React from 'react';
import { Styles } from './AdminTable.styled';
import { numberFormatter } from '../../NumberFormatter';

function GrandTotalTable({ allGrandTotalData }) {

    return (
        <Styles>
            <div>
                <p style={{paddingTop: '10px', paddingBottom: '2px', textDecoration: 'underline'}}>Grand total from all data</p>
            </div>

            <table>
                {/* <thead>
                    <th></th>
                    <th></th>
                    <th>Grand Total of Monthly SIP</th>
                    <th>Grand Total of Total Profit</th>
                    <th>Grand Total of Profit %</th>
                    <th>Grand Total of Total Fund</th>
                    <th>Grand Total of Total Investment</th>
                    <th>Grand Total of Zakat</th>
                    <th>Grand Total of Fund available for Investment</th>
                    <th>Grand Total of Total Networth</th>
                </thead> */}
                <tbody>
                    <tr>
                    <td></td>
                    <td></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalMonthlySip * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalProfit * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.profitPercentage * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalFund * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalInvestment * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalZakat * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.fundAvailableToInvest * 100) / 100)}</b></td>
                    <td><b>{numberFormatter(Math.round(allGrandTotalData?.totalNetworth * 100) / 100)}</b></td>
                    </tr>
                </tbody>
            </table>
        </Styles>
    )
}

export default GrandTotalTable