import styled from 'styled-components';
import { Colors } from '../../../Const/Colors/Theme';

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* top: 0; */
  /* left: 0; */

  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  /* min-height: 1000px; */
  /* padding-top:100px; */
`;

export const ModalContent = styled.div`
  min-width: 300px;
  width: 400px;
  /* padding: 15px; */

  @media (max-width: 380px) {
    width: 82%;
  }
  background-color: ${Colors.white};
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  h4 {
    font-weight: 400;
  }
  hr {
    border-top: none;
    margin-bottom: 8px;
  }
`;
export const Label = styled.label`
  padding: 5px 0px;
  display: block;
`;
export const Amount = styled.input`
  height: 40px;
  position: relative;
  margin: 5px 0px;
  border: 1px solid ${Colors.medgrey};
  width: 360px;
  outline: none;
  padding: 0 10px;
  @media (min-width: 330px) and (max-width:380px) {
    width: 265px;
  }
  @media (min-width:300px) and (max-width:328px){
    width:260px;
  }
`;
export const DateDiv = styled.div`
  height: 40px;
  position: relative;
  margin: 5px 0px;
  border: none;
  font-size: 16px;
  outline: none;
  border: 1px solid ${Colors.medgrey};
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
  input {
    border: none;
    height: 100%;
    width: 96%;
    font-size: 16px;
    padding: 0 10px;
    cursor: pointer;
    outline: none;
  }
  svg {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 9px;
    right: 10px;
    pointer-events: none;
  }
`;
export const Pdiv = styled.div`
  padding: 20px;
`;

export const Update = styled.button`
  height: 40px;
  width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.blue};
  color: ${Colors.white};
  border-radius: 4px;
  border: none;
  margin: 20px 0 0;
  cursor: pointer;
  @media (min-width:361px) and (max-width:380px){
    width:265px;
  }
  @media (max-width: 360px) {
    width: 230px;
  }
`;
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px;
  /* padding: 20px 0 10px; */
  svg {
    cursor: pointer;
  }
`;
export const Sub = styled.div`
  padding: 20px;
  padding-top: 10px;

`
export const P = styled.p`
  display: block;
  padding: 10px 20px 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.7);
`;
export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 1rem;
`;
export const ButtonsModal = styled.span`
  color: ${Colors.blue};
  cursor: pointer;
`;
export const FlexR = styled.div`
  display: flex;
  align-items: center;
`;
