import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  ContentDiv1,
  MenuBar,
  MenuRightDiv,
  MenuText,
  TableTopBar,
  TableTopItems,
  Lis,
  General,
  Head,
  Points,
  Linediv,
  EditorDiv,
  BtnDiv,
  TermsDiv,
} from './TermsStyled';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import axios from 'axios';
import {
  getTermsConditions,
  insertTermsConditions,
  updateTermsConditions,
} from '../../../Const/Api/Api';
import parse from 'html-react-parser';
import { DefaultEditor } from 'react-simple-wysiwyg';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Colors } from '../../../Const/Colors/Theme';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import { LoadingContainer } from '../../Treasurer/Report/FundCollectorActivity/FundCollectorActivityStyles';
import swal from 'sweetalert';

// const list = [
//   {
//     id: 1,
//     listitem: "The objective of BMIP group is strictly for investment purpose for Retirement Income/future goals."

//   },
//   {
//     id: 2,
//     listitem: "Each share value AED - 50.00/- Mandatory count of share be 4 no's and maximum 8 no's those who completed in one year with BMIP team."

//   },
//   {
//     id: 3,
//     listitem: "Monthly paymentshould be done on/before 10thof every month to the selected fund coordinators."

//   },
//   {
//     id: 4,
//     listitem: "The objective of BMIP group is strictly for investment purpose for Retirement Income/future goals."

//   },
//   {
//     id: 5,
//     listitem: "The objective of BMIP group is strictly for investment purpose for Retirement Income/future goals."

//   },
//   {
//     id: 6,
//     listitem: "The objective of BMIP group is strictly for investment purpose for Retirement Income/future goals."

//   },
//   {
//     id: 7,
//     listitem: "The objective of BMIP group is strictly for investment purpose for Retirement Income/future goals."

//   },
// ]

function Terms() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [type, setType] = useState('text');
  const [amountType, setAmountType] = useState(null);
  const [terms, setTerms] = useState([]);
  const [editor, setEditor] = useState(false);
  const [edit, setEdit] = useState(false);
 
  const [condition, setCondition] = useState('');
  // console.log('condition: ', condition);
  const [loading, setLoading] = useState(false)

  const getTermsCon = async () => {
    const resp = await axios.get(getTermsConditions);
    setLoading(true)
    setTerms(resp?.data);
    setCondition(resp?.data[0]?.description);
  };
  useEffect(() => {
    getTermsCon();
  }, []);
  const [description, setDescription] = useState();
  console.log('discription: ', description);

  function onChange(e) {
    setDescription(e.target.value);
  }

  const handleSubmit = () => {
    axios({
      method: 'post',
      url: insertTermsConditions,
      data: { description: description },
    }).then((resp) => {
      console.log(resp?.data, ':asfafafasd');
      if (resp?.data?.code == 200) {
        swal({ text: "Terms and conditions Added", icon: "success" })
        getTermsCon();
      }
    });
  };
  function handleChange(e) {
    setCondition(e.target.value);
  }

  const updateDescptn = () => {
    axios({
      method: 'post',
      url: updateTermsConditions,
      data: { description: condition, id: terms[0]?.id },
    }).then((resp) => {
      console.log('resp: ', resp);
      if (resp?.data?.code == 200) {
        swal({ text: "Terms and conditions updated", icon: "success" })
      }
      // else{

      //   swal({text: resp?.data?.message, icon: "warning"})
      // }
      getTermsCon();
    });
  };
  const exportPage = () => {
    html2canvas(document.querySelector('#terms')).then((canvas) => {
      // document.body.appendChild(canvas);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', [350, 800]);
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save("download.pdf");
    });
  };
  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Terms & Conditions</MenuText>
        <MenuRightDiv>
          {terms[0]?.id ? (
            <Button
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </Button>
          ) : null}
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems></TableTopItems>
          <Download onClick={() => exportPage()}>Download</Download>
        </TableTopBar>
        <General id='terms'>
          <Head>
            <p>GENERAL RULES & REGULATIONS</p>
          </Head>
          {loading ?
            terms[0]?.id ? (
              <TermsDiv>
                {edit ? (
                  <EditorDiv>
                    <DefaultEditor value={condition} onChange={handleChange} />
                    <BtnDiv>
                      <Button
                        onClick={() => {
                          updateDescptn();
                          setEdit(false);
                        }}
                      >
                        Add
                      </Button>
                    </BtnDiv>
                  </EditorDiv>
                ) : (
                  parse(terms[0]?.description)
                )}
                {console.log(
                  'parse(terms[0]?.description): ',
                  parse(terms[0]?.description)
                )}
              </TermsDiv>
            ) : (
              <EditorDiv>
                <DefaultEditor value={description} onChange={onChange} />
                <BtnDiv>
                  <Button
                    onClick={() => {
                      setEditor(true);
                      setEdit(false);
                      handleSubmit();
                    }}
                  >
                    Add
                  </Button>
                </BtnDiv>
              </EditorDiv>
            )
            // {editor ? null :

            // }
            : <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>}


        </General>
      </ContentDiv1>
    </Container>
  );
}

export default Terms;
