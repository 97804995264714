import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Container,
  ContentDiv,
  DateWrapper,
  DropDownDrawer,
  FormContainer,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  ContainerFund,
  FundCDropDown,
  LoadingContainer,
  TableContainerF,
} from '../FundCollectorActivity/FundCollectorActivityStyles';
import {
  AdvanceDropdown,
  PendingAdviceDiv,
  PendingItem,
} from '../PendingAdvance/PendingAdvanceStyles';
import AdminTable from '../../../../Components/Table/AdminTable';
import { AccSummaryDiv, FormGroup1 } from './AccountSummaryStyles';
import axios from 'axios';
import { getAccountSummary } from '../../../../Const/Api/Api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../Const/Colors/Theme';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const AccountSummary = () => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [monthly, setMonthly] = useState('All');
  const modalRef = useRef(null);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [allGrandTotalData, setAllGrandTotalData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const columns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id', // accessor is the "key" in the data
    },
    {
      Header: 'Member Name',
      accessor: 'name',
    },
    {
      Header: 'Total Monthly SIP (AED)',
      accessor: 'totalMonthlySip',
    },
    {
      Header: 'Total Profit Obtained (AED)',
      accessor: 'totalProfit',
    },
    {
      Header: 'Profit %',
      accessor: 'profitPercentage',
    },
    {
      Header: 'Total Fund (SIP + Profit) (AED)',
      accessor: 'totalFund',
    },
    {
      Header: 'Total Investment (AED)',
      accessor: 'totalInvestment',
    },
    {
      Header: 'Zakat (AED)',
      accessor: 'zakat',
    },
    {
      Header: 'Fund available for Investment (AED)',
      accessor: 'fundAvailableToInvest',
    },
    {
      Header: 'Total Networth',
      accessor: 'totalNetworth',
    },
  ];
  const MonthlyColumns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id',
    },
    {
      Header: 'Member Name',
      accessor: 'name',
    },
    {
      Header: 'Total Monthly SIP (AED)',
      accessor: 'totalMonthlySip',
    },
    {
      Header: 'Month and Year',
      accessor: 'month_year',
    },
    {
      Header: 'Total Profit Obtained (AED)',
      accessor: 'totalProfit',
    },
    {
      Header: 'Profit %',
      accessor: 'profitPercentage',
    },
    {
      Header: 'Total Fund (SIP + Profit) (AED)',
      accessor: 'totalFund',
    },
    {
      Header: 'Total Investment (AED)',
      accessor: 'totalInvestment',
    },
    {
      Header: 'Zakat (AED)',
      accessor: 'zakat',
    },
    {
      Header: 'Fund available for Investment (AED)',
      accessor: 'fundAvailableToInvest',
    },
    {
      Header: 'Total Networth',
      accessor: 'totalNetworth',
    },
  ];
  const sendReq = async () => {
    await axios
      .get(`${getAccountSummary}?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        console.log('res: ', res);
        setLoading(true);
        const data1 = [];

        if(res?.data?.all_grand_total){
          setAllGrandTotalData(res?.data?.all_grand_total)
        }

        res.data?.all.map((item) => {
          data1.push({
            bmi_id: item?.bmi_id,
            name: item?.name,
            totalMonthlySip: item?.totalMonthlySip ? numberFormatter((Math.round(Number(item?.totalMonthlySip) * 100) / 100)) : '0',
            totalProfit: item?.totalProfit ? numberFormatter((Math.round(Number(item?.totalProfit) * 100) / 100)) : '0',
            profitPercentage:  item?.profitPercentage ? numberFormatter((Math.round(Number(item?.profitPercentage) * 100) / 100)) : '0',
            totalFund:  item?.totalFund ? numberFormatter((Math.round(Number(item?.totalFund) * 100) / 100)) : '0',
            totalInvestment:  item?.totalInvestment ? numberFormatter(Number(Math.round(Number(item?.totalInvestment) * 100) / 100)) : '0',
            zakat: item?.zakat ? numberFormatter((Math.round(Number(item?.zakat) * 100) / 100)) : '0',
            fundAvailableToInvest:
            item?.fundAvailableToInvest ? numberFormatter((Math.round(Number(item?.fundAvailableToInvest) * 100) / 100)) : '0',
            totalNetworth: item?.totalNetworth ? numberFormatter((Math.round(Number(item?.totalNetworth) * 100) / 100)) : '0',
            status: item?.status
          });
          setData(data1);
        });
        const data2 = [];
        res.data?.monthly.map((item) => {
          data2.push({
            bmi_id: item?.bmi_id,
            name: item?.name,
            totalMonthlySip: item?.totalMonthlySip ? numberFormatter((Math.round(Number(item?.totalMonthlySip) * 100) / 100)) : '0',
            totalProfit: item?.totalProfit ? numberFormatter((Math.round(Number(item?.totalProfit) * 100) / 100)) : '0',
            profitPercentage:  item?.profitPercentage ? numberFormatter((Math.round(Number(item?.profitPercentage) * 100) / 100)) : '0',
            totalFund:  item?.totalFund ? numberFormatter((Math.round(Number(item?.totalFund) * 100) / 100)) : '0',
            totalInvestment:  item?.totalInvestment ? numberFormatter((Math.round(Number(item?.totalInvestment) * 100) / 100)) : '0',
            zakat: item?.zakat ? numberFormatter((Math.round(Number(item?.zakat) * 100) / 100)) : '0',
            fundAvailableToInvest:
            item?.fundAvailableToInvest ? numberFormatter((Math.round(Number(item?.fundAvailableToInvest) * 100) / 100)) : '0',
            totalNetworth: item?.totalNetworth ? numberFormatter((Math.round(Number(item?.totalNetworth) * 100) / 100)) : '0',
            month_year: item?.month_year,
            status: item?.status
            
          });
          setData2(data2);
        });
      });
  };
  useEffect(() => {
    sendReq();

    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(monthly == 'Monthly' ? data2 : data );
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Account Summary</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setFromDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type='Date' onChange={(e) => setToDate(e.target.value)} />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={sendReq}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <ContainerFund>
        <AccSummaryDiv>
          <AdvanceDropdown>
            <FormGroup1>
              <FundCDropDown
                ref={modalRef}
                onClick={() => setOpenDropDown(!openDropDown)}
                width='150px'
                style={{ position: 'relative' }}
              >
                <span>{monthly ? monthly : 'All'}</span>
                <ImgDiv>
                  {openDropDown ? (
                    <img src={BlackArrowUp} alt='' />
                  ) : (
                    <img src={BlackArrowDown} alt='' />
                  )}
                </ImgDiv>
              </FundCDropDown>
              <label
                style={{
                  float: 'right',
                  color: Colors.blue,
                  cursor: 'pointer',
                }}
                onClick={() => exportToExcel()}
              >
                Download
              </label>
            </FormGroup1>
            {openDropDown ? (
              <DropDownDrawer>
                <PendingItem
                  onClick={() => [setMonthly(null), setOpenDropDown(false)]}
                >
                  <span>All</span>
                </PendingItem>
                <PendingItem
                  onClick={() => [
                    setMonthly('Monthly'),
                    setOpenDropDown(false),
                  ]}
                >
                  <span>Monthly</span>
                </PendingItem>
              </DropDownDrawer>
            ) : (
              ''
            )}
          </AdvanceDropdown>
        </AccSummaryDiv>
        <ContentDiv>
          {loading ? (
            <TableContainerF>
              {
                monthly == 'Monthly' ? 
                (
                  <AdminTable columns={MonthlyColumns} data={data2} />
                ) 
                : 
                (
                  <AdminTable columns={columns} data={data} allGrandTotalData={allGrandTotalData} />
                )
              }
            </TableContainerF>
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
        </ContentDiv>
      </ContainerFund>
    </Container>
  );
};

export default AccountSummary;
