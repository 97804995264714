import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { BsX } from "react-icons/bs";
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import { InsertExpense, selecttreasurer } from '../../../../Const/Api/Api';
import {
  Container,
  Modal,
  First,
  Second,
  Fst,
  Scnd,
  Lft,
  Ryt
} from './AddExpenseStyled'

function AddExpense({ setAddexpen, getExpenses }) {
 
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});
  console.log("Inputs=>", inputs);
  const [treasurer, setTreasurer] = useState([])
  console.log('treasurer: ', treasurer);

//   const getTreasurer = () => {
//     axios.get(`${selecttreasurer}/${localStorage.getItem('userid')}`).then(resp => {
//         setTreasurer(resp?.data)
//     })
// }
// useEffect(() => {
//   getTreasurer()
// },[])
const user = useSelector((state) => state.login.user);

  const handleClick = (e) => {
    // e.preventDefault()
    axios.post(`${InsertExpense}`, {
      date: (inputs?.date).split("-").reverse().join("-"),
      amount: inputs?.amount,
      treasurer: user.id,
      purpose: inputs?.purpose
    }).then((resp) => {
      console.log({treasurer: user.id}, "lllll")
      if(resp?.data?.code == 200){
        swal({text: "Expense added" , icon: "success"})
        setAddexpen()
        getExpenses()
      }else{
        swal({text: resp?.data?.message , icon: "warning"})
      }
    })
      .catch((err) => {
       swal({text: "Error" , icon: "error"})
        console.log(err)
      })

  }

  return (
    <Container>
      <Modal>
        <First>
          <p>Add Expense </p>
          <BsX size={25} style={{ cursor: 'pointer' }} onClick={() => setAddexpen(false)} />
        </First>
        <hr />
        <Second>
          <Fst>
            <Lft>
              <label>Date</label>
              <input type="date" value={inputs?.date} onChange={(e) => {
                setInputs((prev) => ({ ...prev, date: e.target.value }))
              }} />
            </Lft>
            <Ryt>
              <label>Amount</label>
              <input type="number" value={inputs?.amount} onChange={(e) => {
                setInputs((prev) => ({ ...prev, amount: e.target.value }))
              }} />
            </Ryt>

          </Fst>
          <Scnd>
            <label>Purpose</label>
            <input type="text" value={inputs.purpose} onChange={(e) => {
              setInputs((prev) => ({ ...prev, purpose: e.target.value }))
            }} />
          </Scnd>
          <button onClick={handleClick}>Add</button>
        </Second>

      </Modal>
    </Container>
  )
}

export default AddExpense