import axios from 'axios';
import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { BsCalendar4, BsX } from "react-icons/bs";
import { getAllBmiId, InsertFundCollector } from '../../../Const/Api/Api';
import { Div } from '../../Treasurer/Modals/CreateBmiIdStyled';
import "react-datepicker/dist/react-datepicker.css"

import {
  Container,
  Modal,
  First,
  Second,
  Divs
} from './AssignFundStyled'
import swal from 'sweetalert';
import { useEffect } from 'react';
import { Dropdown1 } from '../../MainFundCollector/FundCollection/FundCollection.styled';
import Select from 'react-select';


function AssignFunds({ setOpenModal, getUsers, allBmi, ids }) {
  console.log('ids: ', ids);
  const [bmiId, setBmiId] = useState();
  console.log('bmiId: ', bmiId);
  const [nameE, setNameE] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  console.log('joiningDate: ', joiningDate);
  const [selectedOption, setSelectedOption] = useState(null);
  console.log('selectedOption: ', selectedOption);

  const SumbitHandler = async () => {
    const data = {
      bmi_id: selectedOption?.value,
      starting_date: joiningDate,
    }
    console.log("🚀 ~ file: AssignFunds.js:23 ~ SumbitHandler ~ data", data)
    axios({
      method: 'POST',
      url: InsertFundCollector,
      data: data
    }).then(response => {
      console.log(response)
      if (response?.data.code == 401) {
        swal({ text: response?.data?.message, icon: "warning" })
      }
      if (response?.data.code == 200) {
        getUsers()
        setOpenModal(false);
        swal({ text: "Fund collector asssigned", icon: "success" })
      }
    }).catch(error => {
      console.log(error.message)
    })
  }



  return (
    <Container>
      <Modal>
        <First>
          <p>Assign</p><BsX size={25} onClick={() => setOpenModal(false)} style={{ cursor: "pointer" }} />
        </First>

        <hr />
        <Second>
          {/* <Divs>
            <label>BMIP ID</label>
            <input type="text" value={bmiId} onChange={e => setBmiId(e.target.value)} />
          </Divs> */}
          {/* <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                  <option value="select">Select BMIP ID </option>

                  {allBmi?.map((item) => {
                      return (
                          <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                      )
                  }) }
              </Dropdown1> */}
          <Divs>
            <label style={{ paddingBottom: "10px" }}>BMIP ID</label>

            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={ids}
            />
          </Divs>

          <Divs>
            <label>Member Name</label>
            <input type="text" style={{ pointerEvents: "none" }} value={selectedOption?.value && allBmi?.filter(i => i?.id == selectedOption?.value)[0]?.full_name} onChange={e => setNameE(e.target.value)} />
          </Divs>

          <Div>
            <label>Starting date</label>
            <input type="date" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
          </Div>

          <button onClick={SumbitHandler}>submit</button>
        </Second>

      </Modal>

    </Container>
  )
}

export default AssignFunds