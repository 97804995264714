import styled from "styled-components";
import { Colors } from "../../../Const/Colors/Theme";


export const Container = styled.div`
top:0;
bottom:0;
left:0;
right:0;
z-index: 3;
display:flex;
align-items:center;
justify-content:center;
background-color:rgba(0, 0, 0, 0.6);
position:absolute;
`
export const Modal = styled.div`
 background-color:${Colors.white};
 display:flex;
 flex-direction:column;
 width:335px;
 border-radius:4px;
 height:auto;
@media (min-width:300px) and (max-width:330px){
    width:280px;
}
`
export const First = styled.div`
//  background-color:orange;
//  height:30px;
 display:flex;
 flex-direction:row;
 border-top-left-radius:4px;
 border-top-right-radius:4px;
 justify-content:space-between;
 padding:15px;
 svg{
   cursor: pointer;
 }
 p{
    padding-top:5px;
    font-size:18px;
    font-weight:500;
 }

`
export const Second = styled.div`
 display:flex;
 flex-direction:column;
 padding:15px;

 button{
    margin-top:20px;
    height:35px;
    border:none;
    border-radius:4px;
    background-color:${Colors.blue};
    color:${Colors.white};
    cursor:pointer;
 }

`
export const Divs = styled.div`
 display:flex;
 flex-direction:column;
 label{
    padding-top:10px;
    font-size:12px;
    font-weight:500;
    padding-left: 5px;
 }
 input{
    height:30px;
    margin-top:10px;
    border:1px solid ${Colors.lightGrey};
    border-radius:4px;
    outline:none;
    padding-left:5px;
 }
`