import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Approvalreq from '../Screens/Treasurer/Bank/Approval/Approvalreq';
import Expense from '../Screens/Treasurer/Bank/IN/Expense/Expense';
import InvestmentReturn from '../Screens/Treasurer/Bank/IN/InvestmentReturn/InvestmentReturn';
import MonthlySIP from '../Screens/Treasurer/Bank/IN/MonthlySIP/MonthlySIP';
import Profit from '../Screens/Treasurer/Bank/IN/Profit/Profit';
import AddZakat from '../Screens/Treasurer/Bank/Modal/AddZakat';
import AllInvestment from '../Screens/Treasurer/Bank/OUT/AllInvestment/AllInvestment';
import Expenses from '../Screens/Treasurer/Bank/OUT/Expense/Expenses';
import Zakat from '../Screens/Treasurer/Bank/OUT/Zakat/Zakat';
import AccountSummary from '../Screens/Treasurer/Report/AccountSummary/AccountSummary';
import ExpenseSummary from '../Screens/Treasurer/Report/ExpenseSummary/ExpenseSummary';
import FundCollectorActivity from '../Screens/Treasurer/Report/FundCollectorActivity/FundCollectorActivity';
import InvestmentSummary from '../Screens/Treasurer/Report/InvestmentSummary/InvestmentSummary';
import PendingAdvance from '../Screens/Treasurer/Report/PendingAdvance/PendingAdvance';
import ProfitSummary from '../Screens/Treasurer/Report/ProfitSummary/ProfitSummary';
import SideNavbar from '../Screens/Treasurer/SideNavbar';
import ActiveMembers from '../Screens/Treasurer/ActiveMembers/ActiveMembers/ActiveMembers';
import ViewMembers from '../Screens/Treasurer/ActiveMembers/ViewMembers/ViewMembers';
import InactiveMembers from '../Screens/Treasurer/InactiveMembers/InactiveMembers';
import EibKunooz from '../Screens/Treasurer/EibKunooz/EibKunooz';
import AssignSip from '../Screens/Treasurer/AssignSip/AssignSip';
import CompanyDetails from '../../src/Screens/Treasurer/CompanyDetails/CompanyDetails/CompanyDetails';
import { useState } from 'react';
import Others from '../Screens/Treasurer/Bank/IN/Others/Others';
import { selecttreasurer } from '../Const/Api/Api';
import { useEffect } from 'react';
import axios from 'axios';

function BMITreasurer() {

  return (
    <div className='sidebar__container'>
      <SideNavbar />
      <Routes>
        <Route
          path='/request'
          element={
            <Approvalreq
              
            />
          }
        />
        <Route path='/monthly-sip' element={<MonthlySIP />} />
        <Route path='/expense' element={<Expense />} />
        <Route path='/profit' element={<Profit />} />
        <Route path='/others' element={<Others/>}/>
        <Route path='/investment-return' element={<InvestmentReturn />} />
        <Route path='/expense-out' element={<Expenses />} />
        <Route path='/investment' element={<AllInvestment />} />
        <Route path='/zakat' element={<Zakat />} />
        <Route path='/account-summary' element={<AccountSummary />} />
        <Route path='/expense-summary' element={<ExpenseSummary />} />
        <Route
          path='/fund-collector-activity'
          element={<FundCollectorActivity />}
        />
        <Route path='/investment-summary' element={<InvestmentSummary />} />
        <Route path='/pending-advance' element={<PendingAdvance />} />
        <Route path='/profit-summary' element={<ProfitSummary />} />
        <Route path='/active-members' element={<ActiveMembers />} />
        <Route path='/active-members/view' element={<ViewMembers />} />
        <Route path='/inactive-members' element={<InactiveMembers />} />
        <Route path='/inactive-members/view' element={<ViewMembers />} />
        <Route path='/eib-kunooz' element={<EibKunooz />} />
        <Route path='/assign-sip' element={<AssignSip />} />
        <Route path='/company-details' element={<CompanyDetails />} />
      </Routes>
    </div>
  );
}

export default BMITreasurer;
