import React, { useEffect, useState } from 'react'
import { ButtonDiv, FieldDiv, InputField, Inputfield1, Modal, ModalContainer, ModalContainerEd, ModalContent, ModalHeader, StatusDiv, StatusText } from './CompanyDetailsStyles'
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios'
import { UpdateCompany } from '../../../../Const/Api/Api'
import { useSelector } from 'react-redux'
// import { User } from '../../../Login/AuthContext'
import swal from 'sweetalert';

const CompanyEditModal = (props) => {
    const [active, setActive] = useState()

    //     const getStatus = (e) => {
    //         setActive(e.target.value)
    //   }
    // const { treasurer, getTreasurer } = User()
    // console.log('treasurer: ', treasurer[0]);
    // const id = treasurer[0]?.id
    const user = useSelector((state) => state.login.user);
    const [values, setValues] = useState({
        name: props?.compData?.company_name,
        treasure_id: user?.id,
        location: props?.compData?.location,
        id: props?.compData?.company_id,
        invested_amount: props?.compData?.totalInvestedAmount,
        investment_starting_date: props?.compData?.invested_date,
        inactivated_date: '',
        status: props?.compData?.status == 'Active' ? "1" : "2",

    })

    console.log('values: ', values);
    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value,
        })
    }
    const handleSubmit = () => {
        axios({
            method: 'post',
            url: UpdateCompany,
            data: values,
        }).then(resp => {
            if (resp?.data?.code == 200) {
                swal({ text: "Updated successfully", icon: "success" })
                props?.getComDetails()
                props.setCompanyModal(false)
            } else {
                swal({ text: resp?.data?.message, icon: "warning" })
            }
        })
    }
    // useEffect(() => {
    //     getTreasurer()
    // }, [])
    return (
        <Modal onClick={() => props.setEdit(false)}>
            <ModalContainerEd onClick={(e) => { e.stopPropagation() }}>
                <ModalHeader>
                    <h1>Edit Company</h1>
                    <span onClick={() => props.setEdit(false)} ><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>
                    <form onSubmit={() => { handleSubmit(); props.setEdit(false) }}>
                        <StatusDiv>
                            <StatusText><p>Status</p>
                                <p>:</p></StatusText>
                            <StatusText>
                                <Inputfield1 type="radio" id="Active" name="status" value="1" checked={values?.status == "1" && 'checked'} onChange={(e) => handleChange(e)} />
                                <label>Active</label></StatusText>

                            <StatusText>
                                <Inputfield1 type="radio" id="Inactive" name="status" value="2" checked={values?.status == "2" && 'checked'} onChange={(e) => handleChange(e)} />
                                <label>Inactive</label>
                            </StatusText>
                        </StatusDiv>

                        <FieldDiv>
                            <p>Company Name</p>
                            <InputField type="text" name="name" onChange={(e) => handleChange(e)} value={values.name} />
                        </FieldDiv>
                        <FieldDiv>
                            <p>Location</p>
                            <InputField type="text" name="location" value={values?.location} onChange={(e) => handleChange(e)} />
                        </FieldDiv>
                        {values?.status == "1" ? <FieldDiv>
                            <p>Investment Starting Date</p>
                            <InputField type="date" name="investment_starting_date" value={values?.investment_starting_date} onChange={(e) => handleChange(e)} required />
                        </FieldDiv> :
                            <FieldDiv>
                                <p>Inactive Date </p>
                                <InputField type="date" name="inactivated_date" value={values?.inactivated_date} onChange={(e) => handleChange(e)} required />
                            </FieldDiv>}
                        <ButtonDiv>
                            <button type='submit'>Update</button>
                        </ButtonDiv>
                    </form>
                </ModalContent>
            </ModalContainerEd>
        </Modal>
    )
}

export default CompanyEditModal