import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from './../../../Components/Table/AdminTable';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Container,
  Click,
} from './../../MainFundCollector/MainFundCollector.styled';
import {
  Overflow,
  Table,
} from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { SuccessModals } from '../../Treasurer/Modals/Portals';
import { Button } from '../../MainFundCollector/MainFundCollector.styled';
import axios from 'axios';
import { ListExpense, TransferExpense } from '../../../Const/Api/Api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import swal from 'sweetalert';
import { expenseCount } from '../../../features/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx'
import { FaEdit } from 'react-icons/fa';
import EditExpenseAmount from '../Modal/EditExpenseAmount';
import { numberFormatter } from '../../../NumberFormatter';

const Expense = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const current = new Date();
  const [showEditModel, setShowEditModel] = useState(false);
  const [editData, setEditData] = useState({});
  const currentDate = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;


  const getExpenses = () => {
    const data1 = [];
    axios.get(ListExpense).then((resp) => {
      resp?.data?.reverse()?.map((item, index) => {
        data1.push({
          sl_no: index + 1,
          date: item?.date,
          treasurer_name: item?.treasurer_name,
          purpose: item?.purpose,
          amount: (
            <>
              {numberFormatter(Math.round(Number(item?.amount) * 100) / 100)} &nbsp;
              <Click
                onClick={() => {
                  setEditData(item)
                  setShowEditModel(true)
                }}
              >
                <FaEdit />
              </Click>
            </>
          ),


          bmi_id: item?.bmi_id,
          col: (
            <Button style={{ padding: '14px 30px' }} onClick={() => fromBank(item?.id)}>
              Transfer from Bank
            </Button>
          ),
        });
      });
      setData(data1);
    });
  };
  useEffect(() => {
    getExpenses();
  }, []);
  const fromBank = (id) => {
    console.log('id: ', id);
    axios({
      method: 'post',
      url: TransferExpense,
      data: { id },
    }).then((resp) => {
      console.log(resp.data, 'transfer to bank');
      if (resp?.data?.code == 200) {
        getExpenses()
        dispatch(expenseCount(resp.data.length));
        swal({ text: resp?.data?.message, icon: "success" })
      } else {
        swal({ text: resp?.data?.message, icon: "warning" })
      }
    }).catch(e => {
      swal({ text: "error", icon: "error" })
    })
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sl No.',
        accessor: 'sl_no', // accessor is the "key" in the data
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: ' Amount',
        accessor: 'amount',
      },
      {
        Header: 'Treasurer Name',
        accessor: 'treasurer_name',
      },
      {
        Header: 'Purpose',
        accessor: 'purpose',
      },
      {
        Header: '',
        accessor: 'col',
        // Cell: cellInfo => {
        //     return (
        //       <Button style={{padding:'14px 30px'}}>Transfer to Bank</Button>
        //     )
        //   }
      },
    ],
    []
  );

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
       {showEditModel && (
        <EditExpenseAmount setShowEditModel={setShowEditModel} getExpenses={getExpenses} data={editData} />
      )}
      <MenuBar secondary>
        <MenuText>Expense</MenuText>
        <MenuRightDiv>
          {/* <Button>Apply</Button> */}
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
          <label
            style={{
              textAlign: 'right',
              color: Colors.blue,
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
          <AdminTable columns={columns} data={data} />
        </Table>
      </Overflow>
    </Container>
  );
};

export default Expense;
