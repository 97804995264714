import styled from "styled-components";
import { Colors, fonts, size } from "../../../../Const/Colors/Theme";

export const CheckAvaiDiv = styled.div`
    a{
        color:${Colors.blue};
        cursor: pointer;
    }
`
export const AddBtnDiv = styled.div`

`
export const AddBtn = styled.button`
   background-color: #346ECA;
    padding: 14px 40px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    font-weight: 400;
    cursor: pointer;
    align-items: center;
    gap: 5px;
  @media screen and (max-width: ${size.Tablet}) {
    padding: 12px 40px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    padding: 10px 20px;
    font-size: 12px;
  }
`
export const ActiveItem = styled.div`
     margin: 0 5px;
  padding: 5px;
  width: 180px;
  border-bottom: 1px solid ${Colors.border};
  :nth-child(4) {
    border-bottom: none;
  }
`
export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    height: '52%',
    minHeight: '320px',
    width: '25%',
    minWidth: '250px',
    fontSize: '70%',
    padding: '0',
  },
};
export const ModalHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${Colors.blackish_grey};
  margin-bottom: 1rem;
  background-color: ${Colors.white};
  h1{
    font-size: 20px;
    font-weight: 400;
  }
  span{
    cursor: pointer;
  }
`
export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
  width: auto;
  background-color: ${Colors.white};
  align-items: center;
  padding: 30px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media screen and (max-width: 1170px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px;
  }
  @media screen and (max-width: ${size.Tablet}) {
    display: flex;
    flex-direction: ${(props) => (props.secondary ? "column" : "row")};
    row-gap: 20px;
  }
  @media screen and (max-width: ${size.MobileM}) {
    padding: 30px 20px;
  }
`;
export const FieldDiv = styled.div`
  padding: 10px;
    p{
        font-size: 12px;
        color: ${Colors.black};
        padding-bottom: 5px;
        /* padding-left: 10px; */
    }
`
export const InputField = styled.input`
  border: 1px solid ${Colors.lightGrey};
    /* width: 100%;    */
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    width: 100%;
    
`
export const InputFieldCheck = styled.input`
  border: 1px solid ${Colors.lightGrey};
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    /* width: 100%; */
`
export const ButtonDiv = styled.div`
    padding: 10px;
button{
  background-color: #346ECA;
    padding: 14px 40px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    font-weight: 400;
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
  @media screen and (max-width: ${size.Tablet}) {
    padding: 12px 40px;
  }
  @media screen and (max-width: ${size.MobileL}) {
    padding: 10px 20px;
    font-size: 12px;
  }
}

`
export const investmentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-35%, -50%)',
    height: '52%',
    minHeight: '320px',
    width: '60%',
    minWidth: '250px',
    fontSize: '70%',
    padding: '0',
  },
};
export const ModalContainer = styled.div`
      width: 22%;
    height: auto;
    padding: 1.1rem;

    min-width: 300px;
    background: white;
    border: 1px solid grey;
`
export const ModalContainerI = styled.div`
   width: 60%;
    height: auto;
    padding: 1.1rem;

    background: white;
    border: 1px solid grey;
    min-width: 300px;
`
export const ModalContent = styled.div`
  width: 100%;
  height: auto;
  @media (max-height: 540px){
    height: 280px;
    overflow-y: scroll;
    ::-webkit-scrollbar{
    display: none;
  }
  }
  
`
export const AmountDiv = styled.div`
      display: flex;
    width: 40%;
    gap: 1rem;  
    padding-left: 2rem;
    @media screen and (max-width: 570px){
      flex-direction: column;
    }
`
export const CheckTable = styled.table`
  width: 96%;
  margin: 1rem;
  border: 1px solid ${Colors.blackish_grey};
  text-align: center;
  border-collapse: collapse;
  tr{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
  }
  th{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
    font-weight: 400;
    color: ${Colors.dark_grey};
  }
  td{
    border: 1px solid ${Colors.blackish_grey};
    padding: 10px;
  }
  ;
`
export const CheckTableDiv = styled.div`
  width:100%;
  height: 350px;
  /* display: flex; */
  overflow-x: scroll;
  ::-webkit-scrollbar{
    display: none;
  }
  /* justify-content: center; */
  padding-top: 2rem;
`
export const ModalContainerEd = styled.div`
  width: 25%;
    height: auto;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid grey;
    min-width: 300px;
    top: 0;
    padding: 1.1rem;
`
export const StatusDiv = styled.div`
  width: 100%;
display: flex ;
justify-content: space-around;
align-items: center;
    label{
        color: #c4c4c4;
        font-size: 12px;
    }
    p{
        font-size: 12px;
        color: #c4c4c4;
    }
    /* @media  screen and (max-width: ${size.Tablet}){
      flex-direction: column;
    } */
`
export const StatusText = styled.div`
      gap: 8px;
  display: flex;
    label{
        padding: 10px;
        @media (max-width: 768px){    
        padding: 0px;
        font-size: 12px;
        }
    }
`
export const Inputfield1 = styled.input`
  width: 100%;
  outline: none;
    padding: 10px 20px;
`
export const ModalContainerR = styled.div`
  width: 60%;
    height: auto;
    padding: 1.1rem;

    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid grey;
    min-width: 300px;
`
export const Modal = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0,0,0, 0.6);
`
export const TableTopBarZakat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
  /* position: absolute; */
  margin-top: 10px;
  gap: 20px;
  width: ${(props) => props.width};
  input {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    width: 100%;
  }
  a {
    padding-right: 25px;
  }
  @media screen and (max-width: ${size.Tablet}) {
  }
`