import styled from "styled-components";
import { Colors } from "../../../Const/Colors/Theme";

export const Select = styled.select`
    border:2px solid ${Colors.medgrey};
    padding:10px;
    background-color:${Colors.white};
    border-radius:4px;
`

export const Success = styled.span`
    color: ${Colors.green};
    font-size:20px;
    text-align:center;
    display:block;
    margin-top:1rem;
`
export const Success1 = styled.span`
    color: ${Colors.blue};
    font-size:20px;
    text-align:center;
    display:block;
    margin-top:1rem;
`
export const ImgGid = styled.img`
    height:200px;
`   
export const SucDiv = styled.div`
    height:100%;
    /* width:100%; */
    padding:2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`