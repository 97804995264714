import React, { useState } from 'react';
import {
  Amount,
  CheckTable,
  CheckTableDiv,
  Modal,
  ModalContainerR,
  ModalContent,
  ModalHeader,
  Icon,
} from './ApprovalModalStyled';
import { AiOutlineClose } from 'react-icons/ai';
// import { CheckTable, CheckTableDiv, Modal, ModalContainerR, ModalContent, ModalHeader } from '../../CompanyDetails/CompanyDetails/CompanyDetailsStyles'
import { CiCirclePlus } from 'react-icons/ci';
import { Colors } from '../../../../Const/Colors/Theme';
import { useEffect } from 'react';
import {
  AddBtnDiv,
  AddBtn,
} from '../../CompanyDetails/CompanyDetails/CompanyDetailsStyles';
import axios from 'axios';
import { insertcompanyprofit } from '../../../../Const/Api/Api';
import swal from 'sweetalert';



function ApprovalModal(props) {
  const [profileData, setProfileData] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [currentAmount, setCurrentAmount] = useState(0);
  console.log(currentAmount, 'total');
  console.log('profileData: ', JSON.stringify(profileData));
  const array = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  let years = [currentYear, currentYear + 1];
  console.log('years: ', years);
  // for (let i = currentYear; i >= parseInt(startingYear); i--) {
  //   years.push(i);
  //   console.log('i: ', i);
  // }

  const handleClick = () => {
    if (
      values?.profit != '' &&
      totalAmount - currentAmount >= +values?.profit
    ) {
      //   setTotalAmount(totalAmount - values?.profit);
      profileData.push(values);
      setCurrentAmount(currentAmount + +values?.profit);
      setValues({ ...values, profit: '' });
    } else {
      alert(`Enter a valid amount!`);
    }
  };

  const [values, setValues] = useState({
    month: '1',
    year: currentYear,
    profit: '',
  });
  console.log('VAlues', values);
  const data = {
    profit_details: JSON.stringify(profileData),
    company_id: props?.amounts?.company_id,
    company_transaction_id: props?.amounts?.id,
  };

  console.log('data: ', data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  useEffect(() => {
    setTotalAmount(props?.amounts?.amount);
  }, []);
  const handleSubmit = () => {
    axios
      .post(insertcompanyprofit, data)
      .then((resp) => {
        console.log('resp.data', resp);
        props?.setApprovalModal(false);
        if (resp?.data?.code == 200) {
          swal({ text: resp?.data?.message, icon: 'success' });
          props?.getCollectionCompany();
        } else {
          swal({ text: resp?.data?.message, icon: 'warning' });
        }
      })
      .catch((e) => {
        swal({ text: 'Error', icon: 'error' });
        console.log('error', e);
      });
  };

  return (
    <Modal>
      <ModalContainerR>
        <ModalHeader>
          <h1>{props?.companyName}</h1>
          <span onClick={() => props.setApprovalModal(false)}>
            <AiOutlineClose size={20} />
          </span>
        </ModalHeader>
        <Amount>
          <p>Total Amount</p>
          <span>:</span>
          <p>AED {totalAmount - currentAmount}</p>
        </Amount>
        <ModalContent>
          <CheckTableDiv>
            <CheckTable>
              <tr>
                <th>Serial No.</th>
                <th>Month</th>
                <th>Year</th>
                <th>Amount</th>
              </tr>

              <tr>
                <td>{profileData.length + 1}</td>
                <td>
                  <select onChange={(e) => handleChange(e)} name='month'>
                    <option value='1'>January</option>
                    <option value='2'>February</option>
                    <option value='3'>March</option>
                    <option value='4'>April</option>
                    <option value='5'>May</option>
                    <option value='6'>June</option>
                    <option value='7'>July</option>
                    <option value='8'>August</option>
                    <option value='9'>September</option>
                    <option value='10'>October</option>
                    <option value='11'>November</option>
                    <option value='12'>December</option>
                  </select>
                </td>
                <td>
                  <select onChange={(e) => handleChange(e)} name='year'>
                  {years?.map((i) => {
                    return(
                    <option value={i} defaultChecked={values?.year}>{i}</option>
                    
                    )
                  })}
                  </select>
                  
                </td>
                <td>
                  <input
                    type='text'
                    value={values?.profit}
                    placeholder='Enter amount'
                    name='profit'
                    onChange={(e) => handleChange(e)}
                  />
                </td>
              </tr>
              {profileData?.map((item, index) => {
                console.log('item: ', item);
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{array[item?.month - 1]}</td>
                    <td>{item?.year}</td>
                    <td>{item?.profit}</td>
                  </tr>
                );
              })}
            </CheckTable>
          </CheckTableDiv>
          <Icon
            onClick={() => {
              handleClick();
            }}
          >
            <CiCirclePlus
              size={20}
              style={{
                color: `${Colors.white}`,
                background: `${Colors.blue}`,
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            />
          </Icon>
        </ModalContent>
        <AddBtnDiv
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginRight: '30px',
          }}
        >
          {' '}
          <AddBtn onClick={() => ((totalAmount - currentAmount) == 0 ? handleSubmit() : alert(`Specify the remaining AED ${totalAmount-currentAmount}`)) }>Add</AddBtn>
        </AddBtnDiv>
      </ModalContainerR>
    </Modal>
  );
}

export default ApprovalModal;
