import React, { useEffect, useRef, useState } from 'react';
import AdminTable from '../../../../Components/Table/AdminTable';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  Container,
  ContentDiv,
  DateWrapper,
  Button,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { FormGroup1 } from '../AccountSummary/AccountSummaryStyles';
import {
  ContainerFund,
  FundCDropDown,
  LoadingContainer,
  TableContainerF,
} from '../FundCollectorActivity/FundCollectorActivityStyles';
import {
  AdvanceDropdown,
  ContainerPending,
  PendingAdviceDiv,
  PendingItem,
} from './PendingAdvanceStyles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../Const/Colors/Theme';
import axios from 'axios';
import { pending_advance } from '../../../../Const/Api/Api';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const PendingAdvance = () => {
  const [pendinData, setPendingData] = useState([]);
  const [advanceData, setAdvanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);

  const modalRef = useRef(null);
  const columns = React.useMemo(
    () => [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id', // accessor is the "key" in the data
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Advance',
        accessor: 'advance',
      },
      {
        Header: 'Date of Payment',
        accessor: 'date_of_payment',
      },
      {
        Header: 'Fund Collector',
        accessor: 'fund_collector',
      },
      {
        Header: 'Amount',
        accessor: 'totalamount_advance',
      },
    ],
    []
  );
  const PendingColumns = React.useMemo(
    () => [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id', // accessor is the "key" in the data
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Pending',
        accessor: 'pending',
      },
      {
        Header: 'Date of Payment',
        accessor: 'date_of_payment',
      },
      {
        Header: 'Fund Collector ID',
        accessor: 'fund_collector',
      },
      {
        Header: 'Amount',
        accessor: 'totalamount_pending',
      },
    ],
    []
  );

  const getData = () => {
    axios.get(pending_advance).then((res) => {
      const data1 = [];
      const data2 = [];
      res?.data?.map((item) => {
        if (item?.totalamount_pending > 0) {
          let unique1 = [];
          let unique2 = [];

          item?.pending?.map((item) => {
            if (unique1.includes(`${item?.month} ${item?.year}`)) {
            } else {
              unique1.push(`${item?.month} ${item?.year}`);
              unique2.push(item);
            }
          });
          data1?.push({
            bmi_id: item?.bmi_id,
            name: item?.name,
            date_of_payment: displayDate(item?.date_of_payment),
            fund_collector: item?.fund_collector,
            totalamount_pending: item?.totalamount_pending ? numberFormatter(Math.round(Number(item?.totalamount_pending) * 100) / 100) : '0',
            pending: item?.pending?.map((i) => {
              const Months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ];
              return (
                <p>
                  <span>{Months[i?.month - 1]}</span>
                  <span> {i?.year}</span>
                </p>
              );
            }),
          });
        }
        if (item?.totalamount_advance > 0) {
          let unique1 = [];
          let unique2 = [];
          item?.advance?.map((item) => {
            if (unique1.includes(`${item?.month} ${item?.year}`)) {
            } else {
              unique1.push(`${item?.month} ${item?.year}`);
              unique2.push(item);
            }
          });
          // console.log(
          //   `🚀 ~ file: PendingAdvance.js:170 ~ res?.data?.map ~ unique1`,
          //   unique1,
          //   unique2
          // );
          data2?.push({
            bmi_id: item?.bmi_id,
            name: item?.name,
            date_of_payment: displayDate(item?.date_of_payment),
            fund_collector: item?.fund_collector,
            totalamount_advance: item?.totalamount_advance ? numberFormatter(Math.round(Number(item?.totalamount_advance) * 100) / 100) : '0',
            ad: item?.advance,
            advance: unique2?.map((i) => {
              const Months = [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ];
              return (
                <p>
                  <span>{Months[i?.month - 1]}</span>
                  <span> {i?.year}</span>
                </p>
              );
            }),
          });
        }
        setPendingData(data1);
        setAdvanceData(data2);
        setLoading(true);
      });
    });
  };

  useEffect(() => {
    getData();
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };

  const [openDropDown, setOpenDropDown] = useState(false);
  const [advance, setAdvance] = useState('Advance');

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (advance == 'Pending') {
      const filteredData = pendinData.filter((item) => {
        const check = new Date(formatDate(item?.date_of_payment));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    } else {
      const filteredData = advanceData.filter((item) => {
        const check = new Date(formatDate(item?.date_of_payment));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(advance == 'Pending' ? (dateFilt ? dateFilteredData : pendinData) : (dateFilt ? dateFilteredData : advanceData));
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Pending/Advance</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <ContainerPending>
        <PendingAdviceDiv>
          <AdvanceDropdown>
            <FormGroup1>
              <FundCDropDown
                ref={modalRef}
                onClick={() => setOpenDropDown(!openDropDown)}
                width='150px'
                style={{ position: 'relative' }}
              >
                <span>{advance ? advance : 'All'}</span>
                <ImgDiv>
                  {openDropDown ? (
                    <img src={BlackArrowUp} alt='' />
                  ) : (
                    <img src={BlackArrowDown} alt='' />
                  )}
                </ImgDiv>
              </FundCDropDown>

              <label
                style={{
                  float: 'right',
                  color: Colors.blue,
                  cursor: 'pointer',
                }}
                onClick={() => exportToExcel()}
              >
                Download
              </label>
            </FormGroup1>
            {openDropDown ? (
              <DropDownDrawer>
                <PendingItem
                  onClick={() => [
                    setAdvance('Advance'),
                    setOpenDropDown(false),
                    setDateFilteredData([]),
                    setDateFilt(false),
                    setEndDate(''),
                    setStartDate(''),
                  ]}
                >
                  <span>Advance</span>
                </PendingItem>

                <PendingItem
                  onClick={() => [
                    setAdvance('Pending'),
                    setOpenDropDown(false),
                    setDateFilteredData([]),
                    setDateFilt(false),
                    setEndDate(''),
                    setStartDate(''),
                  ]}
                >
                  <span>Pending</span>
                </PendingItem>
              </DropDownDrawer>
            ) : (
              ''
            )}
          </AdvanceDropdown>
        </PendingAdviceDiv>
        <ContentDiv>
          <TableContainerF>
            {loading ? (
              <TableContainer>
                {advance == 'Pending' ? (
                  <AdminTable
                    columns={PendingColumns}
                    data={dateFilt ? dateFilteredData : pendinData}
                  />
                ) : (
                  <AdminTable
                    columns={columns}
                    data={dateFilt ? dateFilteredData : advanceData}
                  />
                )}
              </TableContainer>
            ) : (
              <LoadingContainer>
                <lottie-player
                  src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                  background='transparent'
                  speed='.5'
                  style={{ width: '100px', height: '100px' }}
                  loop
                  autoplay
                ></lottie-player>
              </LoadingContainer>
            )}
          </TableContainerF>
        </ContentDiv>
      </ContainerPending>
    </Container>
  );
};

export default PendingAdvance;
