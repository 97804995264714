import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from './../../../Components/Table/AdminTable';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
  Container,
} from './../../MainFundCollector/MainFundCollector.styled';
import {
  Overflow,
  Table,
} from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { SuccessModals } from '../../Treasurer/Modals/Portals';
import { Select } from './inactiveMemberFundStyle';
import axios from 'axios';
import { getDeactiveMembersList, UpdateSettled } from '../../../Const/Api/Api';
import { useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../FundTransferDetails/FundTransferDetails';
import { useDispatch } from 'react-redux';
import { inactiveMemberFund } from '../../../features/notifications/notificationSlice';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../NumberFormatter';

const InactiveMemberFund = () => {
  const navigation = useNavigate();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isDateFilterActive, setDateFilterActive] = useState(false);
  const [count1, setCount1] = useState(0);
  const dispatch = useDispatch();
  const statusChange = (e, bmi_id) => {
    if (e.target.value === 'Settled') {
      axios
        .post(`${UpdateSettled}`, { bmi_id })
        .then((res) => {
          if (res.status == 200) {
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
            }, 1540);
            getDeactiveMembersData();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const columns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id', // accessor is the "key" in the data
    },
    {
      Header: 'Member Name',
      accessor: 'full_name',
    },
    {
      Header: 'Starting Date',
      accessor: 'joining_date',
    },
    {
      Header: 'Inactive Date',
      accessor: 'inactive_date',
    },
    {
      Header: 'Treasurer Name',
      accessor: 'treasurer',
    },
    {
      Header: 'Total Fund',
      accessor: 'total_amount',
    },
    {
      Header: 'Amount Available to Withdraw',
      accessor: 'availableToWithdraw',
    },
    {
      Header: 'Status',
      accessor: 'col8',
    },
  ];
  useEffect(() => {
    dispatch(inactiveMemberFund(count1));
  }, [count1]);
  const getDeactiveMembersData = () => {
    axios.get(`${getDeactiveMembersList}`).then((res) => {
      const count = res?.data.filter((i) => i?.status == 2);
      setCount1(count.length);
      const data1 = [];
      res?.data?.reverse()?.map((item) => {
        data1.push({
          availableToWithdraw:
          numberFormatter(Math.round(item?.availableToWithdraw * 100) / 100),
          bmi_id: item?.bmi_id,
          full_name: item?.full_name,
          id: item?.id,
          inactive_date: displayDate(item?.inactive_date),
          joining_date: displayDate(item?.joining_date),
          status: item?.status,
          total_amount: numberFormatter(Math.round(item?.total_amount * 100) / 100),
          treasurer: item?.treasurer?.name,
          col8:
            item?.status == 3 ? (
              <p style={{ color: 'green' }}>Settled</p>
            ) : item?.status == 1 ? (
              <p style={{ color: 'blue' }}>Pending</p>
            ) : (
              <Select
                name='s'
                id=''
                onChange={(e) => statusChange(e, item?.id)}
              >
                <option value='Pending'>Pending</option>
                <option value='Settled'>Settled</option>
              </Select>
            ),
        });
        setData(data1);
      });
    });
  };
  useEffect(() => {
    getDeactiveMembersData();
  }, []);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      setDateFilterActive(true);
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.inactive_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  return (
    <Container>
      <MenuBar secondary>
        {success && (
          <SuccessModals setShowModal={setSuccess} text={'Settled'} />
        )}
        <MenuText>Inactive member fund settlement</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                name='startDate'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                name='endDate'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={() => dateFilterChangeHandler()}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
          <label
            style={{
              textAlign: 'right',
              color: Colors.blue,
              cursor: 'pointer',
            }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
          <AdminTable
            columns={columns}
            data={isDateFilterActive ? dateFilteredData : data}
          />
        </Table>
      </Overflow>
    </Container>
  );
};

export default InactiveMemberFund;
