import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  inactiveMemberFund: 0,
  InvestmentFund: 0,
  approvalReq: 0,
  PendingReq: 0,
  fundRecievedFromCollectors: 0,
  expenseCount: 0,
  zakatCount: 0
};

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    inactiveMemberFund: (state, action) => {
      state.inactiveMemberFund = action.payload;
    },
    InvestmentFund: (state, action) => {
      state.InvestmentFund = action.payload;
    },
    approvalReq: (state, action) => {
      state.approvalReq = action.payload;
    },
    PendingReq: (state, action) => {
      state.PendingReq = action.payload;
    },
    fundRecievedFromCollectors: (state, action) => {
      state.fundRecievedFromCollectors = action.payload;
    },
    expenseCount: (state, action) => {
      state.expenseCount = action.payload
    },
    zakatCount: (state, action) => {
      state.zakatCount = action.payload
    }
  },
});

export const {
  inactiveMemberFund,
  InvestmentFund,
  approvalReq,
  PendingReq,
  fundRecievedFromCollectors,
  zakatCount,
  expenseCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
