import styled from "styled-components";
import { Colors, size } from "../../../../Const/Colors/Theme";

const Container = styled.div`
top:0;
bottom:0;
left:0;
right:0;
z-index: 3;
display:flex;
align-items:center;
justify-content:center;
background-color:rgba(0, 0, 0, 0.6);
position:absolute;
/* @media (max-width:340px){
  margin-top: 100px;
} */
`

const Subcontainer = styled.div`
padding:10px 0;
/* align-items:center; */
justify-content:center;
background-color:${Colors.white};
display:flex;
flex-direction:column;
border-radius:6px;
width: 450px;

@media (max-width:330px){
  width:300px;
}
@media (max-width:490px){
  width:350px;
}
@media (max-width:375px){
  width:auto;
  margin: 5px;
}
`
const Topbar = styled.div`
height:40px;
padding:0px 8px;
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
border-bottom:1px solid ${Colors.border};
cursor:pointer;
p{
    margin:0 12px;
    font-size:14px;
    color:${Colors.black}
}
`
const Editdet = styled.div`
margin:5px auto;
display:flex;
flex-direction:column;
align-items:flex-start;
justify-content:flex-start;
p{
    margin:5px 0;
    font-size:14px;
    color:${Colors.black}
}
input{
    width:250px;
    height:30px;
    border:1px solid ${Colors.border};
    border-radius:2px;
    outline:none;
    padding:0 8px;
}
textarea{
    width:250px;
    height:80px;
    border:1px solid ${Colors.border};
    border-radius:2px;
    outline:none;
    padding:8px;
}
@media (max-width:330px){
    margin:5px 25px 5px 20px;
}
    `

const Details = styled.div`
margin:15px 0 0;
padding:0px 8px;
display:flex;
flex-direction:row;
align-items:flex-start;
justify-content:flex-start;

span{
    margin:0;
    font-size:14px;
    color:${Colors.border}
}
@media (max-width:330px){
  flex-direction:column;
}

`
const Add = styled.div`
    display:flex;
    flex-direction:column;
    margin:10px 10px;
    /* align-items:center; */
    /* justify-content:center; */

    p{
        font-size:12px;
        color:${Colors.black};
        margin:8px 0;
        padding-left:2px;
    }
    input{
        width:110px;
        border:1px solid ${Colors.border};
        outline:none;
    }
    @media (max-width:350px){
    margin: 10px 10px;
    input{
      width:100px;
      
  }
  }
`
const TextField = styled.textarea`
width: auto;
height:80px;
/* resize:none; */
border:1px solid ${Colors.border};
border-radius:4px;
/* margin:0 10px; */
margin-inline:18px;
outline:none;
padding:8px;
`

const Button = styled.button`
    width:120px;
    height:35px;
    margin:6px 20px;
    color:${Colors.white};
    background-color:${Colors.blue};
    border:none;
    border-radius:3px;
    `
const Buttons = styled.button`
    padding:10px 0;
    width:272px;
    margin:10px 20px;
    color:${Colors.white};
    background-color:${Colors.blue};
    border:none;
    border-radius:3px;
    `

const DateWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin:0 8px;
  input {
    padding: 8px 5px;
    text-align: center;
    border: 1px solid ${Colors.border};
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
  }
  span {
    @media screen and (max-width: ${size.MobileM}) {
      /* width: 100%; */
    }
  }
  @media screen and (max-width: ${size.MobileM}) {
    flex-direction: column;
  }
`;
const DateWrappered = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  /* padding-inline: 20px; */
  /* margin-right: 10px; */
  input {
    /* padding: 8px 5px; */
    /* text-align: center; */
    border: 1px solid ${Colors.border};
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 7px 0px;
  }

  span{
    padding-left: 10px;
    color:black;
    /* margin-inline:20px; */
    @media screen and (max-width: ${size.MobileM}) {
      /* width: 100%; */
    }
  }
  @media (max-width:380px){
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
 
`;
const DateWrappers = styled.div`
  display: flex;
  gap: 8px;
  /* justify-content: center; */
  align-items: center;
  margin:0 8px;
  input {
    padding: 8px 5px;
    text-align: center;
    border: 1px solid ${Colors.border};
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
  }
  span {
    @media screen and (max-width: ${size.MobileM}) {
      /* width: 100%; */
    }
  }
//   @media screen and (max-width: ${size.MobileM}) {
//     flex-direction: column;
//   }
`;
export {
  Container,
  Subcontainer,
  Topbar,
  Details,
  DateWrapper,
  Add, TextField,
  Button, Editdet,
  Buttons, DateWrappers, DateWrappered
}
