import styled from "styled-components";

export const NotificatnDiv = styled.div`
      background-color: #ffffff; 
    min-width: 160px;
    width: 400px;
    box-shadow: 0px 1px 6px 0px black;
    z-index: 1;
    position: absolute;
    top: 70px;
    right: 160px;
    border: 1px solid gray;
    padding: 10px;
    transition: transform 2s;
    @media screen and (max-width: 600px){
        width: 300px;
        right: 0;
    }
`
export const NotDropdownMenu = styled.div`
        overflow: auto;
        height: 300px;
    padding-top: 10px;
  
`

export const NotHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    p{
        font-size: 16px;
    }
    span{
        cursor: pointer;
    }
`
export const NotContent = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid #cacaca;
        cursor: pointer;
        /* background: #f5f5f5; */
    :hover{
        background-color: #e0e0e0 !important;
    }
    img{
        width: 40px;
        height: 40px;
    }
`
export const NotDetails = styled.div`
    p{
        font-size: 14px;
    }
    label{
        padding-top: 5px;
        font-size: 12px;
        color: #949494
    }

`