import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpen: true,
};

export const othersSlice = createSlice({
  name: 'others',
  initialState,
  reducers: {
    setMenuOpen: (state, action) => {
      state.menuOpen = action.payload ? action.payload : !state.menuOpen;
    },
  },
});
export const { setMenuOpen } = othersSlice.actions;
export default othersSlice.reducer;
