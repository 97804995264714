import React, { useRef, useState } from 'react';
import { Colors } from '../../../Const/Colors/Theme';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';

import {
  DownloadDiv1,
  FundCDropDown,
  FundCHead,
  FundCollector,
  FundHeader,
  TableContainerF,
} from '../../Treasurer/Report/FundCollectorActivity/FundCollectorActivityStyles';
import {
  Button,
  Container,
  ContentDiv,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
} from '../MainFundCollector.styled';
import { CollectorDiv, TransferBtn } from './FundRecievedCollectorsStyles';
import AdminTable from '../../../Components/Table/AdminTable';
import AmountModal from './AmountModal';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect } from 'react';
import axios from 'axios';
import {
  getTransferCompanyTransactionDetails,
  getVerifiedCompanyTransactionDetails,
  getVerifiedUserTransactionDetails,
  ListAmount,
  transferCompanyTransactionToBank,
  transfertobankUser,
} from '../../../Const/Api/Api';
import swal from 'sweetalert';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../FundTransferDetails/FundTransferDetails';
import { fundRecievedFromCollectors } from '../../../features/notifications/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import ProfitModal from './ProfitModal';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../NumberFormatter';

const FundRecievedCollectors = ({ countUpdate, setCountUpdate }) => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [amountType, setAmountType] = useState('Sip');
  const [code, setCode] = useState(1);
  const [amountModal, setAmountModal] = useState(false);
  const [amountData, setAmountData] = useState([]);
  const [data, setData] = useState([]);
  const [filtData, setFiltData] = useState([]);
  const [compnyData, setCompnyData] = useState([]);
  const [amountDetails, setAmountDetails] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [dateFilteredData, setIsDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [count1, setCount1] = useState(0);
  const [profitMod, setProfitMod] = useState(false);
  const [count2, setCount2] = useState(0);
  const [profitData, setProfitData] = useState([]);
  const dropDownRef = useRef(null);
  const dispatch = useDispatch();

  const getAmountType = () => {
    axios.get(`${ListAmount}`).then((res) => setAmountData(res.data));
  };

  const { fundRecievedFromCollectors: notification } = useSelector(
    (state) => state.notification
  );

  const getUserTransaction = () => {
    const data1 = [];
    axios.get(getVerifiedUserTransactionDetails).then((resp) => {
      let array = resp?.data?.filter((i) => i?.transferToBank == 0);
      setCount1(array.length);
      resp?.data?.map((item, index) => {
        data1.push({
          transfer_date: displayDate(item?.transfer_date),
          fund_collector_id: <div >
            <p>{item?.fund_collector_name}</p>
            <p>{item?.fund_collector_id}</p></div>,
          fund_collector_bmip: item?.fund_collector_bmip,
          total_amount: (
            <a
              onClick={() => {
                setAmountModal(true);
                setAmountDetails(item?.members_list);
              }}
              style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
            >
              {item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0'}
            </a>
          ),
          treasurer_name: item?.treasurer,
          amount_cat_id: item?.amount_cat_id,
          col: (
            <TransferBtn
              disabled={item?.transferToBank == 1 ? true : false}
              onClick={() =>
                transferToBankUser(
                  item?.transactionIdArray,
                  item?.amount_cat_id
                )
              }
            >
              Transfer to bank
            </TransferBtn>
          ),
        });
        if (code == 1) {
          let filteredArray = data1.filter(
            (item) => item?.amount_cat_id === code
          );
          setFiltData(filteredArray);
        }
        setData(data1);
      });
    });
  };
  
  const getCompanyDetails = () => {
    axios.get(getVerifiedCompanyTransactionDetails).then((resp) => {
      let array = resp?.data?.filter((i) => i?.transferToBank == 0);
      setCount2(array.length);

      const data1 = [];
      const Months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      resp?.data?.map((item, index) => {
        const date = parseInt(item?.transfer_date.split('-')[1]);
        let mVal = '';
        for (let x in item?.details) {
          if (item?.details[x]?.month) {
            mVal += (x > 0 ? ', ' : '') + Months[parseInt(item?.details[x]?.month) - 1];
          }
        }

        data1.push({
          transfer_date: displayDate(item?.transfer_date),
          month: mVal,
          company_name: item?.company_name,
          collected_from: item?.collected_from,
          total_amount:
            item?.amount_cat_id == 5 ? (
              <a
                style={{ color: '#346eca', cursor: 'pointer' }}
                onClick={() => {
                  setProfitMod(true);
                  setProfitData(item?.details);
                }}
              >
                {numberFormatter(Math.round(Number(item?.total_amount) * 100) / 100)}
              </a>
            ) : (
              item?.total_amount
            ),
          remarks: item?.remarks,
          amount_cat_id: item?.amount_cat_id,
          treasurer_name: item?.treasurer_name,
          fund_collector_bmip: <div >
            <p>{item?.fund_collector_name}</p>
            <p>{item?.fund_collector_id}</p></div>,
          col: (
            <TransferBtn
              disabled={item?.transferToBank == 1 ? true : false}
              onClick={() => transferToBankComp(item?.company_transaction_id)}
            >
              Transfer to bank
            </TransferBtn>
          ),
        });
        setCompnyData(data1);
      });
    });
  };

  useEffect(() => {
    getAmountType();
    getUserTransaction();
    getCompanyDetails();

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };

  useEffect(() => {
    filterData();
    dispatch(fundRecievedFromCollectors(count1 + count2));
  }, [amountType, data, compnyData]);

  const transferToBankComp = (id) => {
    axios
      .post(transferCompanyTransactionToBank, { id })
      .then((resp) => {
        if (resp?.data.code == 200) {
          swal({ text: resp?.data?.message, icon: 'success' });
          getCompanyDetails();
          setCountUpdate(!countUpdate);
        } else {
          swal({ text: resp?.data?.message, icon: 'error' });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const transferToBankUser = (ids, amount_cat_id) => {
    axios
      .post(transfertobankUser, {
        transactionArray: JSON.stringify(ids),
        amount_cate_id: amount_cat_id,
      })
      .then((resp) => {
        if (resp?.data.code == 200) {
          getUserTransaction();
          swal({ text: resp?.data?.message, icon: 'success' });
          setCountUpdate(!countUpdate);
        } else {
          swal({ text: resp?.data?.message, icon: 'error' });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterData = () => {
    if (code === 4 || code === 5) {
      const filteredArray = compnyData.filter(
        (item) => item?.amount_cat_id === code
      );
      setFiltData(filteredArray);
    } else {
      const filteredArray = data.filter((item) => item?.amount_cat_id === code);
      setFiltData(filteredArray);
    }
  };

  const columns = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'serial_no', // accessor is the "key" in the data
    // },
    {
      Header: 'Transfer Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Fund Collector ID',
      accessor: 'fund_collector_bmip',
    },
    {
      Header: 'Total Fund',
      accessor: 'total_amount',
    },
    {
      Header: 'Treasurer Name',
      accessor: 'treasurer_name',
    },
    {
      Header: 'Remarks',
      accessor: 'col6',
    },
    {
      Header: '',
      accessor: 'col',
    },
  ];

  const SipColumns = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'serial_no',
    // },
    {
      Header: 'Transfer Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Fund Collector ID',
      accessor: 'fund_collector_bmip',
    },
    {
      Header: 'Total Fund(AED)',
      accessor: 'total_amount',
    },
    {
      Header: 'Treasurer Name',
      accessor: 'treasurer_name',
    },

    {
      Header: '',
      accessor: 'col',
    },
  ];

  const ProfitColumns = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'serial_no', // accessor is the "key" in the data
    // },
    {
      Header: 'Transfer Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Received From (Fund collector ID)',
      accessor: 'fund_collector_bmip',
    },
    {
      Header: 'Month',
      accessor: 'month',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },

    {
      Header: 'Collected From',
      accessor: 'collected_from',
    },

    {
      Header: 'Treasurer Name',
      accessor: 'treasurer_name',
    },
    {
      Header: 'Total Fund',
      accessor: 'total_amount',
    },
    {
      Header: '',
      accessor: 'col',
    },
  ];

  const InvestmentColumns = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'serial_no',
    // },
    {
      Header: 'Collected Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Received From (Fund collector ID)',
      accessor: 'fund_collector_bmip',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },

    {
      Header: 'Collected From',
      accessor: 'collected_from',
    },

    {
      Header: 'Treasurer Name',
      accessor: 'treasurer_name',
    },
    {
      Header: 'Total Fund',
      accessor: 'total_amount',
    },
    {
      Header: '',
      accessor: 'col',
    },
  ];

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive ? dateFilteredData : filtData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const datefilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      setIsDateFilterActive(true);
      const filteredData = filtData.filter((item) => {
        const check = new Date(formatDate(item?.transfer_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setIsDateFilteredData(filteredData);
    }
  };

  return (
    <Container>
      {amountModal && (
        <AmountModal
          setAmountModal={setAmountModal}
          name={amountType}
          amountDetails={amountDetails}
        />
      )}
      {profitMod && (
        <ProfitModal setProfitMod={setProfitMod} profitData={profitData} />
      )}
      <MenuBar secondary>
        <MenuText>Fund received from fund collectors </MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={datefilterChangeHandler}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <CollectorDiv>
        <FundCollector>
          <FundCHead>
            <FundHeader>
              <FormGroup>
                <FundCDropDown
                  ref={dropDownRef}
                  onClick={() => setOpenDropDown(!openDropDown)}
                  width='150px'
                  style={{ position: 'relative' }}
                >
                  <span>{amountType ? amountType : 'Sip'}</span>
                  <ImgDiv>
                    {openDropDown ? (
                      <img src={BlackArrowUp} alt='' />
                    ) : (
                      <img src={BlackArrowDown} alt='' />
                    )}
                  </ImgDiv>
                </FundCDropDown>
              </FormGroup>
              {openDropDown ? (
                <DropDownDrawer>
                  {amountData?.map((item) => {
                    console.log(item);
                    return (
                      <DropDownItem
                        onClick={() => [
                          setAmountType(item?.amount_type),
                          setOpenDropDown(false),
                          filterData(item?.code),
                          setIsDateFilterActive(false),
                          setStartDate(''),
                          setEndDate(''),
                          setCode(item?.code),
                        ]}
                      >
                        <span>{item?.amount_type}</span>
                      </DropDownItem>
                    );
                  })}
                </DropDownDrawer>
              ) : (
                ''
              )}
            </FundHeader>
            <label
              style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
              onClick={() => exportToExcel()}
            >
              Download
            </label>
          </FundCHead>
        </FundCollector>
        <ContentDiv>
          <TableContainerF>
            {amountType === 'Sip' ||
              amountType === 'Expense' ||
              amountType === 'Others' ? (
              <AdminTable
                columns={SipColumns}
                data={isDateFilterActive ? dateFilteredData : filtData}
                serial_no={true}
              />
            ) : (
              ''
            )}
            {amountType === 'Investment Return' ? (
              <AdminTable
                serial_no={true}
                columns={InvestmentColumns}
                data={isDateFilterActive ? dateFilteredData : filtData}
              />
            ) : (
              ''
            )}
            {amountType === 'Profit' ? (
              <AdminTable
                columns={ProfitColumns}
                data={isDateFilterActive ? dateFilteredData : filtData}
                serial_no={true}
              />
            ) : (
              ''
            )}
            {amountType == null ? (
              <AdminTable
                columns={columns}
                data={isDateFilterActive ? dateFilteredData : data}
              />
            ) : (
              ''
            )}
          </TableContainerF>
        </ContentDiv>
      </CollectorDiv>
    </Container>
  );
};

export default FundRecievedCollectors;
