import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable, {
  customFilterFunction,
} from '../../../Components/Table/AdminTable';
import { getMonthlySipList } from '../../../Const/Api/Api';
import {
  dateBetweenCheck,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import {
  Container,
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
} from '../../MainFundCollector/MainFundCollector.styled';
import { A, JusEnd, Table } from '../ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { AddSipModal } from '../Modals/Portals';
import { LoadingContainer } from '../Report/FundCollectorActivity/FundCollectorActivityStyles';
import { Main } from './AssignSipStyle';
import * as XLSX from 'xlsx'
import { Colors } from '../../../Const/Colors/Theme';
import { numberFormatter } from '../../../NumberFormatter';

const AssignSip = () => {
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [bmi, setBmi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);

  const getMonthlySip = async () => {
    const data1 = [];
    const resp = await axios.get(getMonthlySipList);
    setLoading(true);
    resp?.data?.map((item) => {
      data1.push({
        bmi_id: item?.bmipno,
        year: item?.Year,
        percentage: numberFormatter(Math.round(Number(item?.Percentage) * 100) / 100),
        amount: item?.Monthly_Sip,
        name: item?.Member_Name,
        col: (
          <A
            onClick={() => {
              setShowModal(true);
              setBmi(item);
            }}
          >
            {item?.Monthly_Sip == '0' ? 'Add' : 'Edit'}
          </A>
        ),
      });
      setData(data1);
      
    });
  };

  useEffect(() => {
    getMonthlySip();
  }, []);

  const getMonthlySipByDate = () => {
    const data1 = []
    axios.get(`${getMonthlySipList}?fromDate=${startDate}&toDate=${endDate}`).then(resp =>{
      console.log('resp: ', resp);
      if(resp?.data?.length == 0) {
        setData([])
      }
      resp?.data?.map((item) => {
      
        data1.push({
          bmi_id: item?.bmipno,
          year: item?.Year,
          percentage: numberFormatter(Math.round(item?.Percentage * 100) / 100),
          amount: item?.Monthly_Sip,
          name: item?.Member_Name,
          col: (
            <A
              onClick={() => {
                setShowModal(true);
                setBmi(item);
              }}
            >
              {item?.Monthly_Sip == '0' ? 'Add' : 'Edit'}
            </A>
          ),
        });
        setData(data1);
        setLoading(true);
    })
  })
}

  const columns =  [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id', 
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Year',
        accessor: 'year',
        filter: customFilterFunction,
      },
      {
        Header: 'Monthly SIP (AED)',
        accessor: 'amount',
      },
      {
        Header: '%',
        accessor: 'percentage',
      },
      {
        Header: '',
        accessor: 'col',
        // Cell: () => <A onClick={() => setShowModal(true)}>Add</A>
      },
    ]
    

  const currentYear = new Date().getFullYear();
  const minYear = data.reduce(
    (min, obj) => (obj?.year < min ? obj?.year : min),
    data[0]?.year
  );
  console.log('current:', currentYear, 'Min Year:', parseInt(minYear));

  let years = [];
  for (let i = currentYear; i >= parseInt(minYear); i--) {
    years.push(i);
  }

  console.log('Years', years);

  // const dateFilterChangeHandler = () => {
  //   if (startDate === '' && endDate === '') {
  //     alert('Please select a date ');
  //   } else if (startDate === '') {
  //     alert('Please select from date ');
  //   } else {
  //     const filteredData = data.filter((item) => {
  //       const check = new Date(formatDate(item?.joining_date));
  //       return dateBetweenCheck(startDate, endDate, check) && item;
  //     });
  //     setDateFilteredData(filteredData);
  //   }
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Assign Monthly SIP</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                name='startDate'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                name='endDate'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={() => getMonthlySipByDate()}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <Main>
      <JusEnd>
            <a
              style={{ float: 'right', color: Colors.blue, cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => exportToExcel()}
            >
              Download
            </a>
          </JusEnd>
      {showModal && (
        <AddSipModal
          setShowModal={setShowModal}
          bmi={bmi}
          getMonthlySip={getMonthlySip}
        />
      )}
      <Table>
        {loading ? (
          <AdminTable columns={columns} data={data} years={years} />
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </Table>
      </Main>
    </Container>
  );
};

export default AssignSip;
