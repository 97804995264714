import styled from "styled-components";
import { Colors } from "../../../../Const/Colors/Theme";

export const AccSummaryDiv = styled.div`
     height: 0;
`
export const FormGroup1 = styled.div`
     display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  width: ${(props) => props.width};
  input {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    width: 100%;
  }
  textarea {
    padding: 12px;
    border: 1px solid ${Colors.border};
    border-radius: 5px;
    resize: none;
    width: 100%;
  }
  input[type="date"] {
    width: 100%;
  }
`