import axios from 'axios';
import React, { useState } from 'react'
import { BsX } from "react-icons/bs";
import swal from 'sweetalert';
import { InsertMainFundcollector } from '../../../Const/Api/Api';
import { Dropdown1 } from '../../MainFundCollector/FundCollection/FundCollection.styled';
import {
      Container,
      Modal,
      First,
      Second,
      Div,
      Divs
} from './AssignMainFundStyled'
import { DivDate } from './AssignTreasurerStyled';
import Select from 'react-select'

function AssignMainFunds({ setAssign, getUSers, allBmi, ids }) {
      const [bmiId, setBmiId] = useState("")
      const [nameE, setNameE] = useState("")
      const [joiningDate, setJoiningDate] = useState("")
      const [selectedOption, setSelectedOption] = useState(null);
      const [fundCollector, setFundCollector] = useState('')
      console.log('fundCollector: ', fundCollector);
      const SumbitHandler = async () => {
            const data = {
                  bmi_id: selectedOption?.value,
                  starting_date: joiningDate,
                  fund_collector_activity: fundCollector
            }
            console.log('data:121 ', data);
            console.log("🚀 ~ file: AssignFunds.js:23 ~ SumbitHandler ~ data", data)
            axios({
                  method: 'POST',
                  url: InsertMainFundcollector,
                  data: data
            }).then(response => {
                  console.log(response,"pop");
                  if (response?.data.code == 200) {
                        swal({ text: "Main fund collector assigned", icon: 'success' })     
                        getUSers()
                        setAssign(false)
                  } else {
                        swal({ text: response?.data.message, icon: 'warning' })
                  }
            }).catch(error => {
                  console.log(error.message,)
            })
      }

      return (
            <Container>

                  <Modal>
                        <First>
                              <p>Assign</p><BsX size={25} onClick={() => setAssign(false)} style={{ cursor: "pointer" }} />
                        </First>
                        <hr />
                        <Second>
                              <Div>
                                    <input type="radio" id='1' name='fund' value='1'  onChange={(e) => setFundCollector(e.target.value) }/>
                                    <label>Main Fund Collector + Fund Collector</label>
                              </Div>
                              <br />
                              <Div>
                                    <input type="radio" id='1' name='fund' value='0' onChange={(e) => setFundCollector(e.target.value) }/>
                                    <label>Main Fund Collector </label>
                              </Div>

                              {/* <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                                    <option value="select">Select BMIP ID </option>

                                    {allBmi?.map((item) => {
                                          return (
                                                <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                                          )
                                    })}
                              </Dropdown1> */}
                              <Divs>
                                    <label style={{ paddingBottom: "10px" }}>BMIP ID</label>
                                    <Select
                                          defaultValue={selectedOption}
                                          onChange={setSelectedOption}
                                          options={ids}
                                    />
                              </Divs>
                              <Divs>
                                    <label>Member Name</label>
                                    <input type="text" style={{ pointerEvents: "none" }} value={selectedOption?.value && allBmi?.filter(i => i?.id == selectedOption?.value)[0]?.full_name} onChange={e => setNameE(e.target.value)} />
                              </Divs>
                              <DivDate>
                                    <label>Starting date</label>
                                    <input type="date" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
                              </DivDate>
                              <button onClick={SumbitHandler}>submit</button>
                        </Second>

                  </Modal>


            </Container>
      )
}

export default AssignMainFunds