import React, { useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { Flex, Label, Overlay } from './AddEibStyle';
import { BsCalendar4 } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'
import { BMIId, FlexR, DateDiv, MarT, Name, Select, TextArea, Update, ModalContent } from './EditModalStyle';
import axios from 'axios';
import { ListAmount, selectfundcollector, UpdateCompanyTransaction } from '../../../Const/Api/Api';
import { useEffect } from 'react';
import { formatDate } from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';

const EditModal = ({ editData, setShowModal, company, getCompData }) => {
    const [startDate, setStartDate] = useState(company?.date);
    const [companyId, setCompanyId] = useState(company?.company_id);
    const [collected, setCollected] = useState(company?.collected_from);
    const [id, setId] = useState(company?.id);
    const [acAmount, setAcAmount] = useState(company?.amount);
    const [acRemarks, setAcRemarks] = useState(company?.remarks || '');
    const [amountData, setAmountData] = useState([]);
    const [amountType, setAmountType] = useState(company?.amount_cat_id);
    const [name, setName] = useState(company?.name)
    const [fundColl, setFundColl] = useState([]);

    // const getFundCollect = () => {
    //     axios.get(`${selectfundcollector}/${localStorage.getItem('userid')}`).then((resp) => {
    //         setFundColl(resp?.data);

    //     });
    // };
    const getAmountType = async () => {
        await axios.get(`${ListAmount}`).then((res) => setAmountData(res.data))
    }

    useEffect(() => {
        getAmountType()
        // getFundCollect()
    }, [])
    const user = useSelector((state) => state.login.user);
    
    const handleSubmit = () => {
        axios.post(UpdateCompanyTransaction, {
            company_id: companyId,
            amount: parseInt(acAmount),
            amount_cat_id: amountType,
            date: startDate,
            fund_collector_id: user?.id,
            collected_from: collected,
            id: id
        }).then(resp => {
            swal({ text: resp?.data?.message, icon: "success" })
        }).catch(e => {

            swal({ text: "error", icon: "error" })
        })
    }

    return (
        <Overlay onClick={() => { setShowModal(false) }} >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <Flex>
                    <h4>Edit details</h4>
                    <GrClose onClick={() => { setShowModal(false) }} />

                </Flex>
                <hr />
                <FlexR>
                    <div>
                        <Label htmlFor='name'>Company Name</Label>
                        <Name value={name} type="text" onChange={e => setName(e.target.value)} />
                    </div>
                    <div>
                        <Label style={{ paddingLeft: 0 }} htmlFor='amount'>Company ID</Label>
                        <BMIId value={companyId} type="text" onChange={e => setCompanyId(e.target.value)} />
                    </div>
                    <div>
                        <Label htmlFor='date'>Date of Payment</Label>
                        <DateDiv>
                            <input type='date' value={formatDate(startDate)} onChange={e => setStartDate(e.target.value)} />
                        </DateDiv>
                    </div>
                </FlexR>
                <FlexR>
                    <div>
                        <Label htmlFor='amount' >Amount Type</Label>
                        <Select value={amountType} onChange={e => setAmountType(e.target.value)}>

                            {company?.amount_cat_id == 4 ? <option value='4' selected>Investment Return</option> : <option value='4'>Investment Return</option>}
                            {company?.amount_cat_id == 5 ? <option value='5' selected>Profit</option> : <option value='5'>Profit</option>}
                        </Select>
                    </div>
                    <div>
                        <Label style={{ paddingLeft: 0 }} htmlFor='amount'>Amount</Label>
                        <BMIId type="text" value={acAmount} onChange={e => setAcAmount(e.target.value)} />
                    </div>
                    {/* <div>
                        <Label style={{ paddingLeft: 0 }} htmlFor='amount'>Amount</Label>
                        <BMIId type="text" value={acAmount} onChange={e=>setAcAmount(e.target.value)} />
                    </div> */}
                </FlexR>
                <MarT>
                    <Label htmlFor='amount'>Remarks</Label>
                    <TextArea style={{ width: '100%' }} value={acRemarks} type="text" onChange={e => setAcRemarks(e.target.value)} />
                </MarT>

                <Update onClick={() => { setShowModal(false); handleSubmit() }}>Update</Update>
            </ModalContent>
        </Overlay>
    )
}
export default EditModal

