import React, { useEffect } from 'react';
import {
  HeaderContainer,
  LogoWrapper,
  LogoConatiner,
  MenuItemContainer,
  HeaderText,
  ImageContainer,
  MenuItemWrapper,
  DrawerIcon,
  DrawerItems,
  ProfileContainer,
  PopupModal,
  UserDiv,
  PopupModal1,
  PendingCount,
} from './Header.styled';
import {
  Logo,
  ProfileIcon,
  BellIcon,
  ArrowDown,
  ArrowUp,
  HamburgerMenu,
} from '../../Const/assets';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Buttons,
  CountDiv,
  Item,
  LinkBtn,
  NavOption,
  RequestsDiv,
  Sidenav,
} from '../Treasurer/SideNavbarStyled';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { GrNotification } from 'react-icons/gr';
import { CiLogout } from 'react-icons/ci';
import { useState } from 'react';
import {
  getNotification,
  getSuperAdmin,
  selectfundcollector,
  selecttreasurer,
} from '../../Const/Api/Api';
import axios from 'axios';
import Notification from './Notification';
import { useRef } from 'react';
import { CountDiv1 } from '../MainFundCollector/SideNav/SideNav.styled';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../features/login/loginSlice';
import { setMenuOpen } from '../../features/others/othersSlice';

function Header() {
  const notRef = useRef(false);
  const logoutRef = useRef(false);
  const closeRef = useRef(false);
  const navigation = useNavigate();
  const location = useLocation();
  const [isFundCollector, setIsFundCollector] = useState(false);
  const [render, setRender] = useState(false);
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPopupOpen, setPopupOpen] = React.useState(false);

  const toggleDrawer = () => {
    dispatch(setMenuOpen());
    // setIsOpen((prevState) => !prevState);
  };

  // const {fundColl, superAdmin, treasurer, getTreasurer, getSuperAdmins, getFundCollect } = User()
  // console.log('treasurer:++++++ ', treasurer);
  // console.log('fundColl: +++++', fundColl[0]?.name);
  const id = localStorage.getItem('userid');
  console.log('id:2222 ', id);
  // const [treasurer, setTreasurer] = useState([]);
  // console.log('treasurer =======> id ', treasurer[0]?.id);
  const [menu, setMenu] = useState(false);
  // console.log('treasurer:app ', treasurer);
  const [notf, setNotf] = useState(false);
  const [count, setCount] = useState([]);
  console.log('count: ', count.length);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);
  console.log('user: ', user);
  const role = useSelector((state) => state.login.role);
  console.log('role: ', role);
  const menuOpen = useSelector((state) => state.others.menuOpen);
  const handleClickOutside = (e) => {
    if (notRef.current && !notRef.current.contains(e.target)) {
      setNotf(false);
    }
    if (logoutRef.current && !logoutRef.current.contains(e.target)) {
      setPopupOpen(false);
    }
    if (closeRef.current && !closeRef.current.contains(e.target)) {
      // toggleDrawer()
      dispatch(setMenuOpen(true));
    }
  };

  useEffect(() => {
    // getTreasurer();
    // getSuperAdmins();
    // getFundCollect();
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [id]);

  useEffect(() => {
    if (user?.id) {
      getNotifications();
    }
  }, [user?.id]);
  // setInterval(() => {
  //   getNotifications()
  // },60000)
  console.log("UserId", user?.id);

  const getNotifications = () => {
    axios.get(`${getNotification}?bmi_id=${role == 'superadmin' ? 0 : user?.id}`).then((resp) => {
      // console.log('`${getNotification}?bmi_id=${user?.id}`: ', `${getNotification}?bmi_id=${user?.id}`);
      console.log(resp.data, 'notification');
      setData(resp?.data);

      const data = resp?.data?.filter((item) => item?.view_status == 0  && item?.status == 0);
      setCount(data);
    });
  };

  return (
    <HeaderContainer>
      <LogoConatiner>
        {location.pathname == '/' || location.pathname == '/signin' ? null : (
          <DrawerIcon ref={closeRef}>
            <div>
              <div
                class={`hamburger  ${menuOpen ? '' : 'is-active'}`}
                id='hamburger-3'
                onClick={toggleDrawer}
              >
                <span class='line'></span>
                <span class='line'></span>
                <span class='line'></span>
              </div>
            </div>
            {/* <img src={HamburgerMenu} onClick={toggleDrawer} alt='' width={30} /> */}
          </DrawerIcon>
        )}

        <LogoWrapper>
          <img src={Logo} alt='' />
        </LogoWrapper>

        <HeaderText>
          {role === 'treasurer' && user?.treasurer == 0 ? 'Joint Treasurer' : user?.treasurer == 1 ?  'Treasurer' : ''}
          {/* {role === '1' && "Main Fund Collector"} */}
          {role === 'superadmin' && 'Super Admin'}
          {role == 'fundcollector' && 'Fund Collector'}
          {(role == 'superfundcollector') &&
            'Main Fund Collector'}
        </HeaderText>
        <HeaderText></HeaderText>
      </LogoConatiner>
      {location.pathname == '/' || location.pathname == '/signin' ? null : (
        <>
          <UserDiv onClick={() => setMenu(!menu)}>
            <ImageContainer>
              <img src={ProfileIcon} alt='' />
            </ImageContainer>
            <ProfileContainer>
              {!menu ? (
                <img src={ArrowDown} alt='' onClick={() => setMenu(!menu)} />
              ) : (
                <img src={ArrowUp} alt='' onClick={() => setPopupOpen(!menu)} />
              )}
            </ProfileContainer>
          </UserDiv>

          {menu ? (
            <PopupModal1 ref={notRef}>
              <span>
                <FiUser />
                {user?.name}
              </span>
              {count?.length == 0 ? null : (
                <PendingCount>
                  {' '}
                  <span> {count?.length}</span>
                </PendingCount>
              )}
              <span onClick={() => setNotf(!notf)}>
                <GrNotification /> Notification
              </span>
              {notf && (
                <Notification
                  data={data}
                  setNotf={setNotf}
                  getNotifications={getNotifications}
                  menu={menu}
                  notId={role == 'superadmin' ? 0 : user?.id}
                />
              )}
              <span
                onClick={() => {
                  localStorage.removeItem('role');
                  localStorage.removeItem('userid');
                  localStorage.removeItem('rl');
                  dispatch(logOut());
                  navigation('/');
                  setPopupOpen(false);
                }}
              >
                <CiLogout /> Logout
              </span>
            </PopupModal1>
          ) : null}

          <MenuItemWrapper ref={notRef}>
            <MenuItemContainer>
              <ImageContainer>
                {count?.length == 0 ? null : (
                  <PendingCount>
                    {' '}
                    <span> {count?.length}</span>
                  </PendingCount>
                )}
                <img src={BellIcon} alt='' onClick={() => setNotf(!notf)} />
              </ImageContainer>
              <>
                {notf && (
                  <Notification
                    data={data}
                    setNotf={setNotf}
                    getNotifications={getNotifications}
                    notId={role == 'superadmin' ? 0 : user?.id}
                  />
                )}
              </>

              <ImageContainer>
                <img src={ProfileIcon} alt='' />
              </ImageContainer>
            </MenuItemContainer>

            <ProfileContainer ref={logoutRef}>
              <span onClick={() => setPopupOpen(!isPopupOpen)}>
                {user?.name}
              </span>
              {!isPopupOpen ? (
                <img
                  src={ArrowDown}
                  alt=''
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              ) : (
                <img
                  src={ArrowUp}
                  alt=''
                  onClick={() => setPopupOpen(!isPopupOpen)}
                />
              )}
              {isPopupOpen ? (
                <PopupModal>
                  <span
                    onClick={() => {
                      localStorage.removeItem('role');
                      localStorage.removeItem('userid');
                      localStorage.removeItem('rl');
                      navigation('/');
                      dispatch(logOut());
                      setPopupOpen(false);
                    }}
                  >
                    Logout
                  </span>
                </PopupModal>
              ) : (
                ''
              )}
            </ProfileContainer>
          </MenuItemWrapper>
        </>
      )}
    </HeaderContainer>
  );
}

export default Header;
