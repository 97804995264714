import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Container,
  ContentDiv,
  DateWrapper,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
} from '../../../MainFundCollector/MainFundCollector.styled';
import {
  ActiveItem,
  AddBtn,
  AddBtnDiv,
  CheckAvaiDiv,
  customStyles,
  investmentStyles,
  MenuItem,
} from './CompanyDetailsStyles';
import { IoAddOutline } from 'react-icons/io5';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  AdvanceDropdown,
  PendingAdviceDiv,
  PendingItem,
} from '../../Report/PendingAdvance/PendingAdvanceStyles';
import { FormGroup1 } from '../../Report/AccountSummary/AccountSummaryStyles';
import {
  FundCDropDown,
  LoadingContainer,
  TableContainerF,
} from '../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import AdminTable from '../../../../Components/Table/AdminTable';
import { FiEdit } from 'react-icons/fi';
import { Colors } from '../../../../Const/Colors/Theme';
import CompanyModal from './CompanyModal';
import InvestmentModal from './InvestmentModal';
import CompanyEditModal from './CompanyEditModal';
import ProfileReportModal from './ProfitReportModal';
import MembersModal from './MembersModal';
import axios from 'axios';
import { getCompanyDetails, InsertCompany } from '../../../../Const/Api/Api';
import { BiEdit } from 'react-icons/bi';
import { Download } from '../../../../dashboards/BMITreasurerStyle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import EditInvestmentModal from './EditInvestmentModal';
import { numberFormatter } from '../../../../NumberFormatter';

const CompanyDetails = () => {
  const [advance, setAdvance] = useState('All');
  const [edit, setEdit] = useState(false);
  const [report, setReport] = useState(false);
  const [members, setMembers] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [companyModal, setCompanyModal] = useState(false);
  const [investmentModal, setInvestmentModal] = useState(false);
  const [compData, setCompData] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [profitList, setProfitList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [showEditInvestmentModal, setShowEditInvestmentModel] = useState(false);

  const getComDetails = async () => {
    const data1 = [];
    axios
      .get(getCompanyDetails)
      .then((resp) => {
        setLoading(true);
        resp?.data.reverse().map((item) => {
          data1.push({
            company_id: item?.company_id,
            company_name: item?.company_name,
            location: item?.location,
            no_of_members: (
              <a
                style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
                onClick={() => {
                  setMembers(true);
                  setMembersList(item?.members_list);
                }}
              >
                {item?.no_of_members}
              </a>
            ),
            invested_date: displayDate(item?.invested_date),
            investment_return: item?.investment_return
              ? numberFormatter(Math.round(Number(item?.investment_return) * 100) / 100) : '0',
            status: item?.status,
            totalInvestedAmount:
            (
              <>
                {( item?.totalInvestedAmount
              ? numberFormatter(Math.round(Number(item?.totalInvestedAmount) * 100) / 100) : '0')}
                <span
                  onClick={() => {
                    setShowEditInvestmentModel(true);
                    setCompData(item);
                  }}
                  style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
                >
                  &nbsp; <BiEdit size={20} />
                </span>
              </>
            )
            ,
            total_profit: (
              <a
                style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
                onClick={() => {
                  setReport(true);
                  setProfitList(item?.profit_list);
                }}
              >
                {numberFormatter(Math.round(item?.total_profit * 100) / 100)}
              </a>
            ),
            inactivated_date: displayDate(item?.inactivated_date),
            col: item?.status == 'Active' && (
              <span
                onClick={() => {
                  setEdit(true);
                  setCompData(item);
                }}
                style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
              >
                <BiEdit size={20} />
              </span>
            ),
          });
          setData(data1);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const filterData = () => {
    let filteredData = data?.filter((item) => item?.status == advance);
    console.log(filteredData, 'filteredData');
    setData2(filteredData);
  };
  useEffect(() => {
    getComDetails();
  }, []);
  useEffect(() => {
    filterData();

    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, [advance]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };

  const columns = [
    {
      Header: 'Company ID',
      accessor: 'company_id',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Invested Date',
      accessor: 'invested_date',
    },
    {
      Header: 'Total Invested Amount (AED)',
      accessor: 'totalInvestedAmount',
    },
    {
      Header: 'Inactivated Date',
      accessor: 'inactivated_date',
    },
    {
      Header: 'Total Profit (AED)',
      accessor: 'total_profit',
    },
    {
      Header: 'Investment Return (AED)',
      accessor: 'investment_return',
    },
    {
      Header: 'No. of Members',
      accessor: 'no_of_members',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: '',
      accessor: 'col',
    },
  ];

  const exportPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#my-table' });
    doc.save('table.pdf');
  };
  

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.invested_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  return (
    <Container>
      {
        showEditInvestmentModal ? (
          <EditInvestmentModal 
            setEdit={setShowEditInvestmentModel}
            compData={compData}
            getComDetails={getComDetails}
          />
        ) : null
      }
      {edit ? (
        <CompanyEditModal
          setEdit={setEdit}
          compData={compData}
          getComDetails={getComDetails}
        />
      ) : null}
      {companyModal ? (
        <CompanyModal
          setCompanyModal={setCompanyModal}
          getComDetails={getComDetails}
          data={data}
        />
      ) : null}

      {report ? (
        <ProfileReportModal setReport={setReport} profitList={profitList} />
      ) : null}
      {members ? (
        <MembersModal setMembers={setMembers} membersList={membersList} />
      ) : null}
      <MenuItem secondary>
        <MenuText>Company Details</MenuText>
        <CheckAvaiDiv>
          <a onClick={() => setInvestmentModal(true)}>
            Check Investment Availability
          </a>
          {investmentModal ? (
            <InvestmentModal setInvestmentModal={setInvestmentModal} />
          ) : null}
        </CheckAvaiDiv>
        <AddBtnDiv>
          <AddBtn onClick={() => setCompanyModal(true)}>
            <IoAddOutline size={20} />
            Add Company
          </AddBtn>
        </AddBtnDiv>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuItem>
     <div style={{display: 'flex' , flexDirection: 'column'}}> 
     <div style={{ display: 'flex', justifyContent: 'flex-end' ,}}>
     <a
          style={{ color: Colors.blue, cursor: 'pointer', padding: '20px' }}
          onClick={() => {
            exportPdf();
          }}
        >
          Download
        </a>
        </div>
      <PendingAdviceDiv>
       
        <AdvanceDropdown>

          <FormGroup1>
            <FundCDropDown
              ref={modalRef}
              onClick={() => setOpenDropDown(!openDropDown)}
              width='175px'
              style={{ position: 'relative' }}
            >
              <span>{advance ? advance : 'All'}</span>
              <ImgDiv>
                {openDropDown ? (
                  <img src={BlackArrowUp} alt='' />
                ) : (
                  <img src={BlackArrowDown} alt='' />
                )}
              </ImgDiv>
            </FundCDropDown>

          </FormGroup1>
          {openDropDown ? (
            <DropDownDrawer>
              <ActiveItem
                onClick={() => [setAdvance('All'), setOpenDropDown(false)]}
              >
                <span>All</span>
              </ActiveItem>

              <ActiveItem
                onClick={() => [
                  setAdvance('Waiting for approval'),
                  setOpenDropDown(false),
                ]}
              >
                <span>Waiting for approval</span>
              </ActiveItem>
              <ActiveItem
                onClick={() => [setAdvance('Active'), setOpenDropDown(false)]}
              >
                <span>Active</span>
              </ActiveItem>
              <ActiveItem
                onClick={() => [setAdvance('Inactive'), setOpenDropDown(false)]}
              >
                <span>Inactive</span>
              </ActiveItem>
            </DropDownDrawer>
          ) : (
            ''
          )}
        </AdvanceDropdown>
      </PendingAdviceDiv>

      <ContentDiv>
        {loading ? (
          <TableContainer>
            {advance == 'All' ? (
              <AdminTable
                columns={columns}
                data={dateFilt ? dateFilteredData : data}
              />
            ) : advance == 'Waiting for approval' ||
              advance == 'Active' ||
              advance == 'Inactive' ? (
              <AdminTable columns={columns} data={data2} />
            ) : (
              ''
            )}
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv>
        </div>
    </Container>
  );
};

export default CompanyDetails;
