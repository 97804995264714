import styled from "styled-components";
import { Colors } from "../../../Const/Colors/Theme";


export const Container = styled.div`
  background-color:gray;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
`
export const Modal = styled.div`
 background-color:${Colors.white};
 width:335px;
 height:475px;
 display:flex;
 flex-direction:column;
 border-radius:4px;
 hr{
    border:0.5px solid ${Colors.lightGrey};
    background-color:${Colors.lightGrey};
 }
 @media (min-width:300px) and (max-width:350px){
    width:290px;
 }
`
export const First = styled.div`
 display:flex;
 flex-direction:row;
 justify-content:space-between;
 padding:15px;
 p{
    font-size:16px;
    padding-top:5px;
    font-weight:500;
 }

`
export const Second = styled.div`
 display:flex;
 flex-direction:column;
 padding:15px;
`

export const Div = styled.div`
 display:flex;
 position: relative;
 flex-direction:column;
 label{
    margin-top:10px;
    font-size:14px;
    padding-left: 5px;
 }
 input{
    margin-top:10px;
    height:30px;
    width:100%;
    padding-left:5px;
    border:1px solid ${Colors.lightGrey};
    border-radius:4px;
    outline:none;
 }
 svg{
   position: absolute;
   top:46px;
   right: 0;
   pointer-events:none;
 }
//  input[type=date]{
//     color:${Colors.grey};
//  }
 button{
    margin-top:15px;
    height:32px;
    background-color:${Colors.blue};
    color:${Colors.white};
    border-radius:4px;
    border:none;
    cursor:pointer;
 }
`
export const ImgGidLoad = styled.img`
   height: 175px;
`