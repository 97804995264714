import React, { useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../../Const/assets';
import { monthlysip_in } from '../../../../../Const/Api/Api';
import { useEffect } from 'react';
import axios from 'axios';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../../../NumberFormatter';

function MonthlySIP() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [type, setType] = useState('text');
  const [amountType, setAmountType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalAmnt, setTotalAmnt] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('')

  const columns = [
    {
      Header: 'Serial No.',
      accessor: 'col1', // accessor is the "key" in the data
    },
    {
      Header: 'User BMID',
      accessor: 'bmip_no',
    },
    {
      Header: 'Name',
      accessor: 'member_name',
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Fund collector Name',
      accessor: 'fund_collector',
    },
    {
      Header: 'Remark',
      accessor: 'remarks',
    },
    {
      Header: 'Amout (AED)',
      accessor: 'amount',
    },
  ];

  const sendReq = async () => {
    let res = await axios.get(`${monthlysip_in}`);
    // console.log(res.data, 'akr');
    setBankAmount(res?.data?.amount)
    const data1 = [];
    let totatAmount = 0;
    res.data?.details?.map((item, index) => {
      data1.push({
        col1: index + 1,
        bmip_no: item?.bmip_no,
        member_name: item?.member_name,
        amount: item?.profit ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0',
        fund_collector: item?.fund_collector,
        date: displayDate(item?.date),
        remarks: item?.remarks,
        status: item?.status
      });
      totatAmount = totatAmount + parseInt(item?.amount);
    });
    setTotalAmnt(totatAmount);
    setData(data1);
    setLoading(true);
  };
  useEffect(() => {
    sendReq();
  }, []);

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Monthly SIP</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <p>Amount Available in bank</p>
            <div style={{
              width: 'fit-content', outline: 'none', pointerEvents: 'none', border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '7px', borderRadius: '5px', fontSize: '14px'
            }}
            >{'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}</div>
          </TableTopItems>
          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBar>

        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default MonthlySIP;
