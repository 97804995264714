import React, { useState } from 'react'
import { AmountDiv, CheckTable, CheckTableDiv, InputFieldCheck, Modal, ModalContainerI, ModalContent, ModalHeader } from './CompanyDetailsStyles'
import { AiOutlineClose } from 'react-icons/ai'
import { Button } from '../../../MainFundCollector/MainFundCollector.styled'
import axios from 'axios'
import { checkInvestmentAvailability } from '../../../../Const/Api/Api'
import swal from 'sweetalert'
import { numberFormatter } from '../../../../NumberFormatter'

const InvestmentModal = (props) => {
    const [check, setCheck] = useState(false)
    const [amount, setAmount] = useState(0)
    const [data, setData] = useState([])
    const handleCheck = () => {
        axios.get(`${checkInvestmentAvailability}/${amount}`).then(resp => {
            if (resp?.data?.code == 401) {
                swal({ text: resp?.data?.message, icon: "warning" });
            }
            setData(resp?.data?.data)
        })
    }


    return (
        <Modal onClick={() => props.setInvestmentModal(false)} >
            <ModalContainerI onClick={(e) => { e.stopPropagation() }}>
                <ModalHeader>
                    <h1>Check Investment Availability</h1>
                    <span onClick={() => props.setInvestmentModal(false)} ><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>
                    <AmountDiv>
                        <InputFieldCheck type="text" name="amount" onChange={(e) => setAmount(e.target.value)} />
                        <Button onClick={() => { setCheck(true); handleCheck() }}>Check</Button>
                    </AmountDiv>
                    {check ? <CheckTableDiv >
                        <CheckTable>
                            <tr>
                                <th>Serial No.</th>
                                <th>BMIP ID</th>
                                <th>Member Name</th>
                                <th>Available Fund to Invest (AED)</th>
                            </tr>
                            {data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item?.bmip_no}</td>
                                        <td>{item?.name}</td>
                                        <td>{numberFormatter(Math.round(item?.invested_amount * 100) / 100)}</td>
                                    </tr>
                                )
                            })}
                            {/* <tr>
                            <td>1</td>
                            <td>BMIID12</td>
                            <td>Adarsh</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>BMIID12</td>
                            <td>Adarsh</td>
                            <td>1000</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>BMIID12</td>
                            <td>Adarsh</td>
                            <td>1000</td>
                        </tr> */}
                        </CheckTable>
                    </CheckTableDiv> : <CheckTableDiv>
                        <CheckTable>
                            <tr>
                                <th>Serial No.</th>
                                <th>BMIP ID</th>
                                <th>Member Name</th>
                                <th>Available Fund to Invest (AED)</th>
                            </tr>
                        </CheckTable>
                    </CheckTableDiv>}




                </ModalContent>
            </ModalContainerI>
        </Modal>
    )
}

export default InvestmentModal