import styled from "styled-components";
import { Colors } from "../../../Const/Colors/Theme";


export const Buttons = styled.button`
    background-color:${Colors.lightgreen};
    border:none;
    border-radius:5px;
    padding:9px 17px;
    color:${Colors.white};
    cursor:pointer;
`