import styled from "styled-components";
import { Colors, size } from "../../Const/Colors/Theme";
const Styles = styled.div`
  padding: 1.5rem;
  ${"" /* width:; */}
  background-color: ${Colors.white};
  @media ${size.tablet} {
    padding: 0.5rem;
  }
  @media ${size.mobileS} {
    padding: 0;
  }
  div {
    width: 100%;
  }
  .search-div {
    width: 20%;
    float: right;
    padding: 1rem;
  }
  h3 {
    font-size: 1.1rem;
    color: ${Colors.black};
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${Colors.disabled};
  }

  table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    height: auto;
    border-top: 1px solid ${Colors.inputborder};
    border-left: 1px solid ${Colors.inputborder};
    border-right: 1px solid ${Colors.inputborder};
    :last-child {
      border-bottom: 0;
    }
  }

  th {
    color: ${Colors.grey};
    font-weight: bold;
  }
  th,
  td {
    font-size: 14px;
    word-wrap: break-word;
    text-align: center;
    vertical-align: middle;
    ${"" /* border-left:1px solid ${colors.inputborder}; */}
    ${"" /* border-top: 1px solid ${colors.inputborder}; */}
    padding:0.5rem;
    width: auto;
    height: auto;
    border-right: 1px solid ${Colors.inputborder};
    border-bottom: 1px solid ${Colors.inputborder};
    :last-child {
      border-right: 0;
    }
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (max-width: ${size.mobileL}) {
      justify-content: center;
      /* justify-content: flex-start; */
    }
    /* @media (max-width: 610px) {
      flex-direction: column;
    } */
  }
  .pagination-buttons {
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    input {
      width: 10% !important;
    }
  }
  .btn {
    width: auto;
    height: auto;
  }

  @media (max-width: ${size.Tablet}) {
    margin-left: 0.5rem;
    overflow: scroll;
    width: 180%;
    table {
      table-layout: auto;
    }
  }
  @media (max-width: ${size.MobileL}) {
    margin-left: 2rem;
    width: 180% !important;
  }
  @media (max-width: ${size.MobileM}) {
    /* margin-left: 4.5rem; */
  }
  @media (max-width: ${size.MobileS}) {
    table {
      margin-left: -10px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    select {
      height: 40px;
      width: 100px;
      padding: 0 10px;
      background: white;
      border: 1px solid ${Colors.medgrey};
      border-radius: 4px;
    }
  }
`;
const Top = styled.div`
  height: 30px;
`;

export { Styles, Top };
