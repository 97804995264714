import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../Screens/Login/ProtectedRoute';
import Activemembers from '../Screens/SuperAdmin/ActiveMem/ActiveMember';
import AssignFund from '../Screens/SuperAdmin/AssignFundCollector/AssignFund';
import AssignMain from '../Screens/SuperAdmin/AssignMain/AssignMain';
import AssignTreasurer from '../Screens/SuperAdmin/AssignTreasurer/AssignTreasurer';
import BMIDCreation from '../Screens/SuperAdmin/BMIDCreation/BMIDCreation';
import DeactiveMember from '../Screens/SuperAdmin/DeactiveMem/DeactiveMember';
import PendingRequest from '../Screens/SuperAdmin/PendingRequest/PendingRequest';
import SuperAdminsidebar from '../Screens/SuperAdmin/Superadminsidebar/SuperAdminsidebar';
import Terms from '../Screens/SuperAdmin/TermsAndConditions/Terms';
import ViewMembers from '../Screens/Treasurer/ActiveMembers/ViewMembers/ViewMembers';

function SuperAdmin() {
  return (
    <div className='sidebar__container'>
      <SuperAdminsidebar />
      <Routes>
        <Route
          path='/bmid-creation'
          element={
            <>
              <ProtectedRoute>
                <BMIDCreation />
              </ProtectedRoute>
            </>
          }
        />
        <Route path='/pending-request' element={<PendingRequest />} />
        <Route path='/pending-request/view' element={<ViewMembers />} />
        <Route path='/activemembers' element={<Activemembers />} />
        <Route path='/deactivate-member' element={<DeactiveMember />} />
        <Route path='/activemembers/view' element={<ViewMembers />} />
        <Route path='/deactivate-member/view' element={<ViewMembers />} />
        <Route path='/assignfund' element={<AssignFund />} />
        <Route path='/assign-treasurer' element={<AssignTreasurer />} />
        <Route path='/assign-main-fundcollector' element={<AssignMain />} />
        <Route path='/terms-and-conditions' element={<Terms />} />
      </Routes>
    </div>
  );
}

export default SuperAdmin;
