import styled from "styled-components";
import { Colors } from "../../../../Const/Colors/Theme";

export const Container = styled.div`
top:0;
bottom:0;
left:0;
right:0;
z-index: 3;
display:flex;
align-items:center;
justify-content:center;
background-color:rgba(0, 0, 0, 0.6);
position:absolute;
`
export const Modal = styled.div`
   background-color:${Colors.white};
   width:425px;
   height:max-content;
   padding-bottom: 20px;
   border-radius:10px;
   hr{
      border:0.5px solid ${Colors.lightGrey};
      background-color:${Colors.lightGrey};
   }
 @media (min-width:400px) and (max-width:500px){
   width:390px;
 }
 @media (min-width:350px) and (max-width:380px){
   width:350px;
 }
 @media (min-width:300px) and (max-width:340px){
   width:295px;
 }
`
export const First = styled.div`
   // background-color:violet;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   height:30px;
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   padding-top:10px;
   padding-inline:20px;
   margin-bottom: 10px;
`
export const Second = styled.div`
   display:flex;
   flex-direction:column;
   padding-inline:20px;
   padding-top:20px;
   button{
       margin-top:15px;
       height:40px;
       border-radius:3px;
       border:none;
       background-color:${Colors.blue};
       color:${Colors.white};
       cursor:pointer;
   }
`
export const Fst = styled.div`
   display:flex;
   flex-direction:row;
   gap: 20px;
   /* width:400px; */
   justify-content:space-between;
/* @media (min-width:400px) and (max-width:500px){
   width:362px;
}
@media (min-width:350px) and (max-width:380px){
   width:330px;
} */
@media (min-width:300px) and (max-width:340px){
   /* width:295px; */
   display:flex;
   flex-direction:column;
}
`
export const Lft = styled.div`
   font-size:14px;
   display:flex;
   flex-direction:column;
   width: 100%;
   input{
      margin-top:10px;
      width: 100%;
      /* width:210px; */
      /* height:30px; */
      border-radius:3px;
      border:1px solid ${Colors.lightGrey};
      outline:none;
      padding:7px;
   }

 @media (min-width:400px) and (max-width:500px){
   input{
      /* width:200px; */
   }
 }  
 @media (min-width:350px) and (max-width:380px){
   input{
      width:180px;
   }
 }
 @media (min-width:300px) and (max-width:340px){
   display:flex;
   flex-direction:row;
   label{
      padding-top:20px;
   }
   input{
      width:195px;
      margin-left:30px;
   }
 }  

`
export const Ryt = styled.div`
   font-size:14px;
   display:flex;
   flex-direction:column;
   width: 74%;
   input{
      margin-top:10px;
      width:100%;
      /* height:30px; */
      border-radius:3px;
      border:1px solid ${Colors.lightGrey};
      outline:none;
      padding:7px;
   }
 @media (min-width:400px) and (max-width:500px){
   input{
      width:100px;
   }
 }  
 @media (min-width:350px) and (max-width:380px){
   input{
      width:100px;
   }
 }  
 @media (min-width:300px) and (max-width:340px){
   display:flex;
   flex-direction:row;
   label{
      padding-top:20px;
   }
   input{
      width:195px;
      margin-left:10px;
   }
 }  
`
export const Scnd = styled.div`
   display:flex;
   flex-direction:column;
   padding-top:10px;
   label{
      font-size:14px;
   }
   input{
      margin-top:10px;
      width:210px;
      /* height:30px; */
      border-radius:3px;
      border:1px solid ${Colors.lightGrey};
      outline:none;
      padding:7px;
   }
 
   @media (min-width:400px) and (max-width:500px){
      input{
         width:200px;
      }
    }
    @media (min-width:350px) and (max-width:380px){
      input{
         width:180px;
      }
    }
    @media (min-width:300px) and (max-width:340px){
      display:flex;
      flex-direction:row;
      label{
         padding-top:18px;
      }
      input{
         width:195px;
         margin-left:6px;
      }
    }    
`