import React, { useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
} from './AssignTreasurerStyled';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import AssignTreasurers from '../Modal/AssignTreasurers';
import EditTreasurer from '../Modal/EditTreasurer';
import axios from 'axios';
import { getActiveMembersList, getAllBmiId, getTreasurer } from '../../../Const/Api/Api';
import { useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import {
  displayDate,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx'

function AssignTreasurer() {
  const [assign, setAssign] = useState(false);
  const [edit, setEdit] = useState(false);
  const [treasurers, setTreasurers] = useState([]);
  console.log('treasurers: ', treasurers);
  const [editUser, setEditUser] = useState({});

  const [nav, setNav] = useState(false);
  const [allBmi, setAllBmi] = useState([]);
  const [id, setId] = useState([]);

  const getUsers = async () => {
    const data = [];
    const resp = await axios.get(getTreasurer);
    console.log('resp: ', resp);
    setTreasurers(resp.data?.filter((item) => console.log(item)));
    resp?.data?.filter((item) => {
      data.push({
        treasurer: item?.treasurer ? 'Treasurer' : 'Joint Treasurer',
        bmi_id: item?.bmi_id,
        name: item?.name,
        bmipno: item?.bmipno,
        starting_date: displayDate(item?.starting_date),
        col: (
          <Click
            onClick={() => {
              setNav(true);
              const data = {
                treasurer: item?.treasurer,
                bmi_id: item?.bmi_id,
                bmipno: item?.bmipno,
                name: item?.name,
                starting_date: formatDate(item?.starting_date),
              };
              setEditUser(data);
            }}
          >
            Edit
          </Click>
        ),
      });
      setTreasurers(data);
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    nav && setEdit(true);
  }, [editUser]);

  const getAllBmi = () => {
    const data1 = [];
    axios.get(getActiveMembersList).then((resp) => {
      resp?.data?.map((i) => {
        data1.push({
          value: i?.id,
          label: `${i?.full_name}-${i?.bmi_id}`,
        });
      });
      setAllBmi(resp?.data);
      setId(data1);
    });
  };

  useEffect(() => {
    getAllBmi();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Role',
        accessor: 'treasurer',
      },
      {
        Header: 'BMIP ID',
        accessor: 'bmipno',
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Starting Date',
        accessor: 'starting_date',
      },
      {
        Header: '',
        accessor: 'col',
      },
    ],
    []
  );
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(treasurers);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      {edit === true ? (
        <EditTreasurer
          setAssign={setEdit}
          {...editUser}
          allBmi={allBmi}
          ids={id}
          getUsers={getUsers}
        />
      ) : (
        ''
      )}
      {assign === true ? (
        <AssignTreasurers
          setAssign={setAssign}
          getUsers={getUsers}
          allBmi={allBmi}
          ids={id}
        />
      ) : (
        ''
      )}
      <MenuBar secondary>
        <MenuText>Assign Treasurer</MenuText>
        <MenuRightDiv>
          <Button onClick={() => setAssign(true)}>Assign Treasurer</Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems></TableTopItems>
          <label
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
        </TableTopBar>

        <TableContainer>
          <AdminTable columns={columns} data={treasurers} />
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default AssignTreasurer;
