import React, { useEffect, useRef, useState } from 'react';
import AdminTable from '../../../../Components/Table/Treasurerapprovaltab';
import {
  Container,
  ContentDiv1,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  ImgDiv,
  MenuBar,
  MenuText,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
} from './ApprovalStyled';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  getTransferUserTransactionDetails,
  getTransferCompanyTransactionDetails,
  ApproveUserTransactionByTreasurer,
  ApproveCompanyTransactionByTreasurer,
  ListAmount,
  selecttreasurer,
} from '../../../../Const/Api/Api';
import axios from 'axios';
import { LoadingContainer } from '../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import swal from 'sweetalert';
import { Colors } from '../../../../Const/Colors/Theme';
import AmountModal from '../../../MainFundCollector/FundRecievedCollectors/AmountModal';
import ApprovalModal from './ApprovalModal';
import { displayDate } from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
  approvalReq,
  fundRecievedFromCollectors,
} from '../../../../features/notifications/notificationSlice';
import { numberFormatter } from '../../../../NumberFormatter';

function Approvalreq() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [amountType, setAmountType] = useState('Sip');
  const [code, setCode] = useState(1);

  const [amountData, setAmountData] = useState(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amountModal, setAmountModal] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [approvalModal, setApprovalModal] = useState(false);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);
  const closeRef = useRef(false);

  useEffect(() => {
    getCollectionUser();
    getAmountType();
    getCollectionCompany();
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (closeRef.current && !closeRef.current.contains(e.target)) {
      setOpenDropDown(false);
    }
  };

  const [amount, setAmount] = useState();
  const changebtn = (ids) => {
    axios
      .post(`${ApproveCompanyTransactionByTreasurer}`, {
        id: ids,
        treasurer_id: user?.id,
      })
      .then((res) => {
        console.log('res=>', {
          id: ids,
          treasurer_id: +user?.id,
        });

        if (res?.data.code == 401) {
          swal({ text: res?.data?.message, icon: 'warning' });
        }
        if (res?.data.code == 200) {
          getCollectionCompany();

          swal({ text: res?.data?.message, icon: 'success' });
        }
      })
      .catch((err) => console.log(err));
  };
  const handleClick = (id) => {
    console.log('Clicked', {transactionArray: JSON.stringify(id),
      treasurer_id: user?.id,});
    axios
      .post(`${ApproveUserTransactionByTreasurer}`, {
        transactionArray: JSON.stringify(id),
        treasurer_id: user?.id,
      })
      .then((res) => {
        console.log('res=>', res);
        if (res?.data.code == 401) {
          swal({ text: res?.data?.message, icon: 'warning' });
        }
        if (res?.data.code == 200) {
          swal({ text: res?.data?.message, icon: 'success' });
          getCollectionUser();
          getCollectionCompany();
        }
      })
      .catch((err) => console.log(err));
  };

  const filterData = () => {
    if (code === 1 || code === 2 || code === 3) {
      let filteredArray = data.filter((item) => item?.amount_cat_id === code);
      setFilteredData(filteredArray);
    }
    if (code === 4 || code === 5) {
      let filteredArray = data2.filter((item) => item?.amount_cat_id === code);
      setFilteredData(filteredArray);
    }
  };
  useEffect(() => {
    filterData();
  }, [code, data2, data]);

  const columns = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'id', // accessor is the "key" in the data
    // },
    {
      Header: 'Transfer Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Fund collector ID',
      accessor: 'fund_collector_id',
    },
    {
      Header: 'Total Amount',
      accessor: 'amount',
    },
    // {
    //   Header: 'Remarks',
    //   accessor: 'remarks',
    // },
    {
      Header: '',
      accessor: 'col6',
    },
  ];
  const companyhead = [
    // {
    //   Header: 'Serial No.',
    //   accessor: 'id', // accessor is the "key" in the data
    // },
    {
      Header: 'Transfer Date',
      accessor: 'transfer_date',
    },
    {
      Header: 'Fund collector ID',
      accessor: 'fund_collector_id',
    },
    {
      Header: 'Total Amount',
      accessor: 'amount',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: '',
      accessor: 'col6',
    },
  ];
  const getCollectionUser = () => {
    axios.get(`${getTransferUserTransactionDetails}`).then((res) => {
      const data1 = [];
      const count = res?.data?.filter((i) => i?.transfer_verification == '0');
      setCount1(count.length);
      res?.data?.map((item, index) => {
        data1.push({
          // id: index + 1,
          bmi_id: item?.bmi_id,
          amount: (
            <a
              style={{ color: `${Colors.blue}`, cursor: 'pointer' }}
              onClick={() => {
                setAmountModal(true);
                setMemberDetails(item?.members_list);
              }}
            >
              {item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0'}
            </a>
          ),
          amount_cat_id: item?.amount_cat_id,
          date: item?.date,
          fund_collector_id: item?.fund_collector_bmip,
          remarks: item?.remarks,
          transfer: item?.transfer,
          transfer_date: item?.transfer_date,
          transfer_verification: item?.transfer_verification,
          transferToBank: item?.transferToBank,
          created_at: item?.created_at,
          company_name: item?.company_name,
          collected_from: item?.collected_from,
          transactionIdArray: item?.item?.transactionIdArray,
          status: item?.transfer_verification == 1 ? 'Paid' : 'Pending',
          col6: (
            <Click
              disabled={item?.transfer_verification == 1 ? true : false}
              onClick={() => {handleClick(item?.transactionIdArray)}}
            >
              {' '}
              {item?.transfer_verification == 1 ? 'Approved' : 'Approve'}
            </Click>
          ),
        });
      });
      if (code == 1) {
        let filteredArray = data1.filter(
          (item) => item?.amount_cat_id === code
        );
        setFilteredData(filteredArray);
      }
      setLoading(true);
      setData(data1);
    });
  };
  useEffect(() => {
    dispatch(approvalReq(count1 + count2));
  }, [count1, count2]);
  const handleProfit = () => {
    setTimeout(setApprovalModal(true), 3000);
  }

  const getCollectionCompany = () => {
    axios.get(`${getTransferCompanyTransactionDetails}`).then((res) => {
      const data5 = [];
      const counnt2 = res?.data?.filter((i) => i?.transfer_verification == '0');
      setCount2(counnt2.length);
      res?.data?.map((item, index) => {
        data5.push({
          amount:
            item?.amount_cat_id == 4 ? (
              item?.amount
            ) : (
              <a
                style={{ color: `${Colors.blue}`, cursor: item?.transfer_verification == 1 ? 'no-drop': 'pointer', pointerEvents: item?.transfer_verification == 1 ?'none' : 'all' }}
                onClick={() => { 
                  item?.profit_status == 1 ? alert('Already specified the month') : setApprovalModal(true);
                  setAmount(item);
                  setCompanyName(item?.name)
                }}
              >
                {item?.amount}
              </a>
            ),
          amount_cat_id: item?.amount_cat_id,
          fund_collector_id: item?.fund_collector_bmipno,
          remarks: item?.remarks,
          company_name: item?.name,
          transfer_date: displayDate(item?.transfer_date),
          transfer_verification: item?.transfer_verification,
          status: item?.transfer_verification == 1 ? 'Paid' : 'Pending',
          col6: (
            <Click style={{width: 'auto', padding: '10px'}}
              disabled={item?.transfer_verification == 1 ? true : false}
              onClick={() => { (item?.amount_cat_id == 4 ? changebtn(item?.id) : (item?.profit_status == 1 ? changebtn(item?.id) : handleProfit()))
                setAmount(item);
                setCompanyName(item?.name) }}
            >
              {item?.transfer_verification == 1 ? 'Approved' : 'Approve'}
            </Click>
          ),
        });
        setData2(data5);
      });
      res.data.length === 0 && setData2([]);
    });
  };
  const getAmountType = () => {
    axios.get(`${ListAmount}`).then((res) => {
      setAmountData(res.data);
    });
  };

  return (
    <Container>
      {amountModal && (
        <AmountModal
          setAmountModal={setAmountModal}
          name={amountType}
          amountDetails={memberDetails}
        />
      )}
      {approvalModal && (
        <ApprovalModal
          companyName={companyName}
          setApprovalModal={setApprovalModal}
          amounts={amount}
          getCollectionCompany={getCollectionCompany}
        />
      )}
      <MenuBar secondary>
        <MenuText>Requests</MenuText>
      </MenuBar>
      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <DropDown
              ref={closeRef}
              onClick={() => setOpenDropDown(!openDropDown)}
              width='150px'
              style={{ position: 'relative' }}
            >
              <span>{amountType ? amountType : 'All'}</span>
              <ImgDiv>
                {openDropDown ? (
                  <img src={BlackArrowUp} alt='' />
                ) : (
                  <img src={BlackArrowDown} alt='' />
                )}
              </ImgDiv>
            </DropDown>
          </TableTopItems>
        </TableTopBar>
        {openDropDown ? (
          <DropDownDrawer>
            {/* <DropDownItem onClick={() => [setAmountType(null), setOpenDropDown(false)]}>
              <span>Select</span>
            </DropDownItem> */}
            {amountData?.map((item) => (
              <DropDownItem
                onClick={() => [
                  setAmountType(item?.amount_type),
                  setCode(item?.code),
                  setOpenDropDown(false),
                  filterData(item?.code),
                ]}
              >
                <span>{item?.amount_type}</span>
              </DropDownItem>
            ))}
          </DropDownDrawer>
        ) : (
          ''
        )}

        {loading ? (
          <TableContainer>
            {code === 1 || code === 2 || code === 3 ? (
              <AdminTable columns={columns} data={filteredData} serial_no = {true}/>
            ) : (
              ''
            )}
            {code === null && <AdminTable columns={columns} data={data}  serial_no = {true}/>}
            {code === 4 || code === 5 ? (
              <AdminTable columns={companyhead} data={filteredData} serial_no = {true}/>
            ) : (
              ''
            )}
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}
export default Approvalreq;
