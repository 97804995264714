import styled from "styled-components";
import { Colors, size } from "../../../../Const/Colors/Theme";

export const PendingAdviceDiv = styled.div`
    /* width: 100%; */
    /* position: absolute;
    left: 18px;
    top: 20%;
    @media screen and (max-width: ${size.MobileL}) {
      top: 110px;
    } */
    height: 0;

`
export const AdvanceDropdown = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 50px; 
    gap: 2rem;
  @media screen and (max-width: ${size.Tablet}) {
    width: auto;
  }

`
export const DropDownDrawerP = styled.div`

`
export const PendingItem = styled.div`
  margin: 0 5px;
  padding: 5px;
  width: 160px;
  border-bottom: 1px solid ${Colors.border};
  :nth-child(2) {
    border-bottom: none;
  }
`
export const ContainerPending = styled.div`
  width: 100%;
  
`