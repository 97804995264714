import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from '../../../../Components/Table/AdminTable';
import { getActiveMembersList } from '../../../../Const/Api/Api';
import { Download } from '../../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
  Container,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { A, JusEnd, Overflow, Table } from './ActiveMembersStyle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../Const/Colors/Theme';
import { LoadingContainer } from '../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const ActiveMembers = () => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [date, setDate] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilterActive, setDateFilterActive] = useState(false);
  const [dateFilt, setDateFilt] = useState(false);
  const getActiveMem = async () => {
    const data1 = [];
    const resp = await axios.get(getActiveMembersList);
    console.log('reaaaa', resp.data);
    setLoading(true);

    resp?.data?.reverse().map((item) => {
      data1.push({
        availableToInvest: numberFormatter(Math.round(item?.availableToInvest * 100) / 100),
        availableToWithdraw: numberFormatter(Math.round(item?.availableToWithdraw * 100) / 100),
        bmi_id: item?.bmi_id,
        full_name: item?.full_name,
        id: item?.id,
        joining_date: displayDate(item?.joining_date),
        total_amount: numberFormatter(Math.round(item?.total_amount * 100) / 100),
        col: (
          <A
            onClick={() => {
              navigation('view', { state: { id: item?.id } });
            }}
          >
            View Details
          </A>
        ),
      });
      setData(data1);
      setDate(data1?.joining_date);
      setDateFilteredData(data1);
    });
  };
  useEffect(() => {
    getActiveMem();
  }, [navigation]);

  useEffect(() => {
    if (dateFilterActive) {
    }
  });
  const columns = React.useMemo(
    () => [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id',
      },
      {
        Header: 'Member Name',
        accessor: 'full_name',
      },
      {
        Header: 'Starting Date',
        accessor: 'joining_date',
      },
      {
        Header: 'Total Amount (AED)',
        accessor: 'total_amount',
      },
      {
        Header: 'Fund Available to Invest (AED)',
        accessor: 'availableToInvest',
      },
      {
        Header: 'Fund Available to Withdrawal (AED)',
        accessor: 'availableToWithdraw',
      },
      {
        Header: '',
        accessor: 'col',
      
      },
    ],
    []
  );
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      setDateFilterActive(true);
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.joining_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Active Members</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
          <JusEnd>
            <a
              style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
              onClick={() => exportToExcel()}
            >
              Download
            </a>
          </JusEnd>
          {loading ? (
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
          {/* <AdminTable columns={columns} data={data} /> */}
        </Table>
      </Overflow>
    </Container>
  );
};

export default ActiveMembers;
