import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  role: '',
  user: [],
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logOut: (state) => {
      state.role = '';
      state.user = [];
    },
  },
});

export const { setRole, setUser, logOut } = loginSlice.actions;

export default loginSlice.reducer;
