import axios from 'axios';
import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { InsertFundCollector } from '../../../Const/Api/Api';
import { Dropdown1 } from '../../MainFundCollector/FundCollection/FundCollection.styled';
import { Div } from '../../Treasurer/Modals/CreateBmiIdStyled';
import { Container, Modal, First, Second, Divs } from './AssignFundStyled';
import Select from 'react-select';
import swal from 'sweetalert';
import { formatDate } from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';

function EditFunds({
  setAssign,
  bmi_id,
  bmipno,
  name,
  starting_date,
  allBmi,
  ids,
  getUsers
}) {
  console.log('allBmi: ', allBmi);
  const [bmiId, setBmiId] = useState();
  const [nameE, setNameE] = useState(name);
  const [joiningDate, setJoiningDate] = useState(starting_date);
  const [selectedOption, setSelectedOption] = useState({
    value: bmiId,
    label: bmipno,
  });
  // const [openModal,setOpenModal] = useState(false);

  const SumbitHandler = async () => {
    const data = {
      bmi_id: selectedOption?.value,
      starting_date: joiningDate,
      previous_bmi_id: bmi_id,
    };
    await axios({
      method: 'POST',
      url: InsertFundCollector,
      data,
    })
      .then((response) => {
        console.log(response);
        console.log('response: ', response);
        if (response?.data?.code == 200) {
          setAssign(false);
          getUsers()

          swal({ text: "Fund collector asssigned", icon: 'success' });
        } else if (response?.data?.code == 401) {
          swal({ text: response?.data?.message, icon: 'warning' });
        }
      })
      .catch((err) => {
        swal({ text: 'Error', icon: 'error' });
        console.log(err.message, "ttt");
      });
  };
  let arrr = allBmi?.filter((i) => i?.id == selectedOption?.value)
  console.log('arrr: ', arrr);
  return (
    <Container>
      <Modal>
        <First>
          <p>Edit Fund Collector</p>
          <BsX size={25} onClick={() => setAssign(false)} />
        </First>
        <hr />
        <Second>
          {/* <Divs>
                    <label>BMIP ID</label>
                    <input type="text" value={`${bmipno} - ${name}`} onChange={e=>setBmiId(e.target.value)} />
              </Divs> */}
          {/* <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                                    <option value="select">{`${bmipno} - ${name}`}</option>

                                    {allBmi?.map((item) => {
                                          return (
                                                <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                                          )
                                    })}
                              </Dropdown1> */}
          <Divs>
            <label style={{ paddingBottom: '10px' }}>BMIP ID</label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={ids}
            />
          </Divs>
          <Divs>
            <label>Member Name</label>
            <input
              type='text'
              style={{ pointerEvents: 'none' }}
              value={
                selectedOption?.value
                  ? allBmi?.filter((i) => i?.id == selectedOption?.value)[0]
                    .full_name
                  : name
              }
              onChange={(e) => setNameE(e.target.value)}
            />
          </Divs>
          <Div>
            <label>Starting date</label>
            <input
              type='date'
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
            />
          </Div>
          <button onClick={SumbitHandler}>submit</button>
        </Second>
      </Modal>
    </Container>
  );
}

export default EditFunds;
