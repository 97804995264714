import React, { useRef, useState } from 'react';
import AdminTable from '../../../../Components/Table/AdminTable';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  Container,
  ContentDiv,
  DateWrapper,
  Button,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
} from '../../../MainFundCollector/MainFundCollector.styled';
import {
  ContainerFund,
  FundCDropDown,
  LoadingContainer,
  TableContainerF,
} from '../FundCollectorActivity/FundCollectorActivityStyles';
import {
  AdvanceDropdown,
  ContainerPending,
  PendingAdviceDiv,
  PendingItem,
} from '../PendingAdvance/PendingAdvanceStyles';
import InvestmentSummaryTable from '../../../../Components/TreasurerTables/InvestmentSummaryTable/InvestmentSummaryTable';
import { FormGroup1 } from '../AccountSummary/AccountSummaryStyles';
import { investmentSummary } from '../../../../Const/Api/Api';
import axios from 'axios';
import { useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../Const/Colors/Theme';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const InvestmentSummary = () => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [monthly, setMonthly] = useState('All');
  const [companyData, setCompanyData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);
  const modalRef = useRef(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const columns = [
    {
      Header: 'BMIP ID',
      columns: [
        {
          Header: 'Not visible header 2',
          displayNone: true,
          accessor: 'bmi_id',
        },
      ],
      rowSpan: '2',
    },
    {
      Header: 'Name',
      columns: [
        {
          Header: 'Not',
          displayNone: true,
          accessor: 'member_name',
        },
      ],
      rowSpan: '2',
    },
    {
      Header: 'Invested Companies',
      columns: companyData,
    },
  ];

  const MonthlyColumns = [
    {
      Header: 'BMIP ID',
      columns: [
        {
          Header: 'Not visible header 2',
          displayNone: true,
          accessor: 'bmi_id',
        },
      ],
      rowSpan: '2',
    },
    {
      Header: 'Name',
      columns: [
        {
          Header: 'Not',
          displayNone: true,
          accessor: 'member_name',
        },
      ],
      rowSpan: '2',
    },
    {
      Header: 'Month & Year',
      columns: [
        {
          Header: 'Not f',
          displayNone: true,
          accessor: 'month_year',
        },
      ],
      rowSpan: '2',
    },
    {
      Header: 'Invested Companies',
      columns: companyData,
    },
  ];

  const getCompanyData = () => {
    axios
      .get(`${investmentSummary}?fromDate=${fromDate}&toDate=${toDate}`)
      .then((data) => {
        let listArray = [];
        for (let i = 0; i < data.data.companyList.length; i++) {
          listArray.push({
            Header: data.data.companyList[i].name,
            accessor: `${data.data.companyList[i].id}`,
            status: data.data.companyList[i].status,
          });
        }

        // for (let i = 0; i < listArray.length; i++) {
        //   listArray[i] = {
        //     ...listArray[i], ...{
        //       "columns":
        //       {
        //         "Header": "Investment",
        //         "accessor": "1",
        //         "status": 1
        //       }
        //     }
        //   }
        // }
        
        setCompanyData(listArray);
        setLoading(true);
        const decData = data.data.monthlyDetails.map((item) => {
          let tempItem = {};
          let dec = '';
          for (const key in item) {
            if (!isNaN(item[key])) {
              dec = numberFormatter(Math.round(item[key] * 100) / 100);
            } else {
              dec = item[key];
            }
            tempItem = {
              ...tempItem,
              [key]: dec,
            };
          }
          return tempItem;
        });

        const monthlyData = data.data.AllInvestment.map((item) => {
          let tempItem = {};
          let dec = '';
          for (const key in item) {
            if (!isNaN(item[key])) {
              dec = numberFormatter(Math.round(item[key] * 100) / 100);
            } else {
              dec = item[key];
            }
            tempItem = {
              ...tempItem,
              [key]: dec,
            };
          }
          return tempItem;
        });
        setMonthlyData(decData);
        setData(monthlyData);
      });
  };

  useEffect(() => {
    getCompanyData();
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Investment Summary</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setFromDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type='Date' onChange={(e) => setToDate(e.target.value)} />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={getCompanyData}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <ContainerPending>
        <PendingAdviceDiv>
          <AdvanceDropdown>
            <FormGroup1>
              <FundCDropDown
                ref={modalRef}
                onClick={() => setOpenDropDown(!openDropDown)}
                width='150px'
                style={{ position: 'relative' }}
              >
                <span>{monthly ? monthly : 'All'}</span>
                <ImgDiv>
                  {openDropDown ? (
                    <img src={BlackArrowUp} alt='' />
                  ) : (
                    <img src={BlackArrowDown} alt='' />
                  )}
                </ImgDiv>
              </FundCDropDown>
              <label
                style={{
                  float: 'right',
                  color: Colors.blue,
                  cursor: 'pointer',
                }}
                onClick={() => exportToExcel()}
              >
                Download
              </label>
            </FormGroup1>
            {openDropDown ? (
              <DropDownDrawer>
                <PendingItem
                  onClick={() => [setMonthly(null), setOpenDropDown(false)]}
                >
                  <span>All</span>
                </PendingItem>
                <PendingItem
                  onClick={() => [
                    setMonthly('Monthly'),
                    setOpenDropDown(false),
                  ]}
                >
                  <span>Monthly</span>
                </PendingItem>
              </DropDownDrawer>
            ) : (
              ''
            )}
          </AdvanceDropdown>
        </PendingAdviceDiv>
        <ContentDiv>
          {loading ? (
            <TableContainerF>
              {monthly === 'Monthly' ? (
                <InvestmentSummaryTable
                  columns={MonthlyColumns}
                  data={monthlyData}
                />
              ) : (
                <InvestmentSummaryTable columns={columns} data={data} />
              )}
            </TableContainerF>
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
        </ContentDiv>
      </ContainerPending>
    </Container>
  );
};

export default InvestmentSummary;
