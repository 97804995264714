import React, { useRef, useState } from 'react';
import {
  Button,
  Container,
  ContentDiv,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormContainer,
  FormGroup,
  FormRow,
  FormSubRow,
  ImgDiv,
  Label,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Select,
  SubHeading,
  TableContainer,
  TableDiv,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../Const/assets';
import {
  ContainerFund,
  DownloadDiv1,
  FundCDropDown,
  FundCHead,
  FundCollector,
  FundHeader,
  LoadingContainer,
  TableContainerF,
  TableDivFundC,
} from './FundCollectorActivityStyles';
import { Colors } from '../../../../Const/Colors/Theme';
import AdminTable from '../../../../Components/Table/AdminTable';
import { FormGroup1 } from '../AccountSummary/AccountSummaryStyles';
import {
  getAllFundCollectors,
  getFundCollectorActivity,
  ListAmount,
} from '../../../../Const/Api/Api';
import axios from 'axios';
import { useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { displayDate } from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const FundCollectorActivity = () => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [amountType, setAmountType] = useState(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [tempData1, setTempData1] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [fundCollectorData, setFundCollectorData] = useState([]);
  const [fcId, setFcId] = useState('none');
  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const columns = [
    {
      Header: 'Fund Collector BMID',
      accessor: 'bmipno',
    },
    {
      Header: 'Transaction Amount',
      accessor: 'amount',
    },
    {
      Header: 'Date of Transaction',
      accessor: 'date',
    },
    {
      Header: 'Fund type',
      accessor: 'amount_category_name',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Collected From',
      accessor: 'collected_from',
    },
    {
      Header: 'Remark',
      accessor: 'remarks',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];
  const SipColumns = [
    {
      Header: 'Fund Collector BMID',
      accessor: 'bmipno', // accessor is the "key" in the data
    },
    {
      Header: 'Transaction Amount',
      accessor: 'amount',
    },
    {
      Header: 'Date of Transaction',
      accessor: 'date',
    },
    {
      Header: 'Remark',
      accessor: 'remarks',
    },
    {
      Header: 'Fund type',
      accessor: 'amount_category_name',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];
  const ProfitColumns = [
    {
      Header: 'Fund Collector BMID',
      accessor: 'id', // accessor is the "key" in the data
    },
    {
      Header: 'Transaction Amount',
      accessor: 'amount',
    },
    {
      Header: 'Date of Transaction',
      accessor: 'date',
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Collected From',
      accessor: 'collected_from',
    },

    {
      Header: 'Remark',
      accessor: 'remarks',
    },

    {
      Header: 'Status',
      accessor: 'status',
    },
  ];

  const getFundCollActivity = () => {
    axios
      .get(`${getFundCollectorActivity}?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        getFundData(res.data);
        setLoading(true);
        console.log('Res=>', res.data);
      });
  };

  const getFundData = (res) => {
    const data1 = [];
    res?.map((item) => {
      data1.push({
        id: item?.bmipno,
        bmipno: item?.bmipno,
        fund_collector_name: item?.fund_collector_name,
        fund_collector_id: item?.fund_collector_id,
        amount: item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0',
        date: displayDate(item?.date),
        amount_category_id: item?.amount_category_id,
        amount_category_name: item?.amount_category_name,
        remarks: item?.remarks,
        transfer: item?.transfer,
        transfer_verification: item?.transfer_verification,
        transferToBank: item?.transferToBank,
        created_at: item?.created_at,
        company_name: item?.company_name,
        collected_from: item?.collected_from,
        status:
          item?.transferToBank == 1
            ? 'Transfered to Bank'
            : item?.transfer_verification == 1
              ? 'Verified'
              : item?.transfer == 1
                ? 'Waiting for Approval'
                : '',
      });
    });
    setData(data1);
    console.log(data1, 'akr');
    setTempData1(data1);
  };
  const filterData = (amount_type) => {
    let filteredArray = data.filter(
      (item) => item?.amount_category_name == amount_type
    );
    console.log('Fil', filteredArray);
    setData2(filteredArray);
    setTempData(filteredArray);
  };

  const getAmountType = () => {
    axios.get(`${ListAmount}`).then((res) => setAmountData(res.data));
  };

  const getAllFundCollector = () => {
    axios.get(getAllFundCollectors).then((res) => {
      console.log('Fund Collectors(res)=>', res.data);
      setFundCollectorData(res.data);
    });
  };
  useEffect(() => {
    getFundCollActivity();
    getAmountType();
    getAllFundCollector();

    // Add event listener for clicks outside of the modal
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const exportPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#my-table' });
    doc.save('table.pdf');
  };

  const handleChange = (e) => {
    console.log('e=>', e.target.value);
    setFcId(e.target.value);
    if (amountType == null) {
      let filteredArray = data.filter(
        (item) => item?.fund_collector_id == e.target.value
      );
      console.log('FC Filter=>', filteredArray);
      setTempData1(filteredArray);
    } else {
      tempData?.map((i) => {
        console.log('item with id', i);
      });
      let filteredArray = tempData.filter(
        (item) => item?.fund_collector_id == e.target.value
      );
      console.log('FC Filter=>', filteredArray);
      setData2(filteredArray);
    }
  };
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(amountType === 'Sip' ||
    amountType === 'Expense' ||
    amountType === 'Others' ? data2  : amountType === 'Investment Return' || amountType === 'Profit' ? data2 : amountType === null ? tempData1 : '');
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Fund Collector Activity</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setFromDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type='Date' onChange={(e) => setToDate(e.target.value)} />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={(e) => {
              getFundCollActivity();
              setAmountType(null);
              setFcId('none');
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <ContainerFund>
        <FundCollector>
          <FundCHead>
            <FundHeader>
              <FormGroup>
                <FundCDropDown
                  ref={modalRef}
                  onClick={() => setOpenDropDown(!openDropDown)}
                  width='150px'
                  style={{ position: 'relative' }}
                >
                  <span>{amountType ? amountType : 'All'}</span>
                  <ImgDiv>
                    {openDropDown ? (
                      <img src={BlackArrowUp} alt='' />
                    ) : (
                      <img src={BlackArrowDown} alt='' />
                    )}
                  </ImgDiv>
                </FundCDropDown>
              </FormGroup>
              {openDropDown ? (
                <DropDownDrawer>
                  <DropDownItem
                    onClick={() => [
                      setAmountType(null),
                      setOpenDropDown(false),
                      setFcId('none'),
                    ]}
                  >
                    <span>All</span>
                  </DropDownItem>
                  {amountData?.map((item) => (
                    <DropDownItem
                      onClick={() => [
                        setAmountType(item?.amount_type),
                        setOpenDropDown(false),
                        filterData(item?.amount_type),
                        setFcId('none'),
                      ]}
                    >
                      <span>{item?.amount_type}</span>
                    </DropDownItem>
                  ))}
                </DropDownDrawer>
              ) : (
                ''
              )}
              <Select onChange={handleChange} value={fcId} placeholder='choose'>
                <option value='none' disabled>
                  Choose Fund Collector
                </option>
                {fundCollectorData?.map((item) => (
                  <option value={item?.bmi_id}>
                    {item?.bmipno} - {item?.name}{' '}
                  </option>
                ))}
              </Select>
            </FundHeader>

            <label
              style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
              onClick={() => exportToExcel()}
            >
              Download
            </label>
          </FundCHead>
        </FundCollector>
        <ContentDiv>
          {loading ? (
            <TableContainerF>
              {amountType === 'Sip' ||
                amountType === 'Expense' ||
                amountType === 'Others' ? (
                <AdminTable columns={SipColumns} data={data2} />
              ) : (
                ''
              )}
              {amountType === 'Investment Return' || amountType === 'Profit' ? (
                <AdminTable columns={ProfitColumns} data={data2} />
              ) : (
                ''
              )}
              {amountType === null ? (
                <AdminTable columns={columns} data={tempData1} />
              ) : (
                ''
              )}
            </TableContainerF>
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
        </ContentDiv>
      </ContainerFund>
    </Container>
  );
};

export default FundCollectorActivity;
