import { server_api } from '../../Api/Api';

const logInSuperAdmin = `${server_api}/api/loginSuperadmin`;
const loginTresurer = `${server_api}/api/loginTreasurer`;
const insertBmiId = `${server_api}/api/insertBmipId`;
const getAllBmiId = `${server_api}/api/getAllBmiId`;
const sendMailToUser = `${server_api}/api/sendMailToUser`;
const reSendmail = `${server_api}/api/re_sendmail`;

const getPendingRequest = `${server_api}/api/getPendingRequest`;
const getUserDetails = `${server_api}/api/getUserDetails`;
const approveUser = `${server_api}/api/verifyUser`;
const getActiveMembersList = `${server_api}/api/getActiveMembersList`;
const getFundCollectors = `${server_api}/api/getFundCollectors`;
const getMainFundCollectors = `${server_api}/api/getMainFundCollectors`;
const InsertFundCollector = `${server_api}/api/InsertFundCollector`;
const EditUserTransaction = `${server_api}/api/EditUserTransaction`;
const getTreasurer = `${server_api}/api/getTreasurer`;
const InsertTreasurer = `${server_api}/api/InsertTreasurer`;
const InsertMainFundcollector = `${server_api}/api/InsertMainFundcollector`;
// const getDeactiveMembersList = `${server_api}/api/getDeactiveMembersList`;
const getTermsConditions = `${server_api}/api/getTermsConditions`;
const insertTermsConditions = `${server_api}/api/insertTermsConditions`;
const updateTermsConditions = `${server_api}/api/updateTermsConditions`;
const deactivatebySuperadmin = `${server_api}/api/deactivatebySuperadmin`;
const listEibKunooz = `${server_api}/api/listEibKunooz`;
const insertEibKunooz = `${server_api}/api/insertEibKunooz`;
const updateMonthlySip = `${server_api}/api/updateMonthlySip`;
const expenseIn = `${server_api}/api/expense_in`;
const investmentreturnIn = `${server_api}/api/investmentreturnIn`;
const getBankInOfProfit = `${server_api}/api/getBankInOfProfit`;
const getFundCollectorActivity = `${server_api}/api/fundcollector_activity_all`;
const ListAmount = `${server_api}/api/ListAmount`;
const investmentSummary = `${server_api}/api/investmentsummary`;
const getTransferUserTransactionDetails = `${server_api}/api/getTransferUserTransactionDetails`;
// const getTransferCompanyTransactionDetails = `${server_api}/api/getTransferCompanyTransactionDetails`
const ApproveUserTransactionByTreasurer = `${server_api}/api/ApproveUserTransactionByTreasurer`;
const ApproveCompanyTransactionByTreasurer = `${server_api}/api/ApproveCompanyTransactionByTreasurer`;
const getAccountSummary = `${server_api}/api/getAccountSummary`;
const monthlysip_in = `${server_api}/api/monthlysip_in`;
const getProfitSummary = `${server_api}/api/getProfitSummary`;
const getExpenseSummary = `${server_api}/api/getExpenseSummary`;
const getExpesnseOut = `${server_api}/api/getExpesnseOut`;
const InsertExpense = `${server_api}/api/InsertExpense`;
const UpdateExpense = `${server_api}/api/UpdateExpense`;
const getCompanyInvestmentBankOut = `${server_api}/api/getCompanyInvestmentBankOut`;
const ListZakat = `${server_api}/api/ListZakat`;
const UpdateZakat = `${server_api}/api/UpdateZakat`;
const InsertZakat = `${server_api}/api/InsertZakat`;
const getCompanyDetails = `${server_api}/api/getCompanyDetails`;
const InsertCompany = `${server_api}/api/InsertCompany`;
const UpdateCompany = `${server_api}/api/UpdateCompany`;
const checkInvestmentAvailability = `${server_api}/api/checkInvestmentAvailability`;
const approved_inactive_members = `${server_api}/api/approved_inactive_members`;
const getSipById = `${server_api}/api/getSipbyId`;
const InsertUserTransaction = `${server_api}/api/InsertUserTransaction`;
const getSuperAdmin = `${server_api}/api/getSuperAdmin`;
const selecttreasurer = `${server_api}/api/selecttreasurer`;
const othersIn = `${server_api}/api/others_in`;
const addZakat = `${server_api}/api/addZakat`;

//====================== Main Fund Collector =====================================
// const selectfundcollector = `${server_api}/api/selectfundcollector`
const LoginFundCollector = `${server_api}/api/LoginFundCollector`;
const getFundCollectorById = `${server_api}/api/getFundCollectorById`
const ListExpense = `${server_api}/api/ListExpense`;
const TransferExpense = `${server_api}/api/TransferExpense`;
const ApprovingZakat = `${server_api}/api/ApprovingZakat`;
const selectfundcollector = `${server_api}/api/selectfundcollector`;
const getDeactiveMembersList = `${server_api}/api/getDeactiveMembersList`;
const UpdateSettled = `${server_api}/api/UpdateSettled`;
const FundApproval = `${server_api}/api/FundApproval`;
const ApprovalCompanyInvestment = `${server_api}/api/ApprovalCompanyInvestment`;
const InsertCompanyTransaction = `${server_api}/api/InsertCompanyTransaction`;
const pending_advance = `${server_api}/api/pending_advance`;
const fundTransferDetailsUser = `${server_api}/api/fundtransferdetails_user`;
const fundTransferDetailsCompany = `${server_api}/api/fundtransferdetails_company`;
const getVerifiedUserTransactionDetails = `${server_api}/api/getVerifiedUserTransactionDetails`;
const getTransferCompanyTransactionDetails = `${server_api}/api/getTransferCompanyTransactionDetails`;
const getMonthlySipList = `${server_api}/api/getMonthlySipList`;
const transferCompanyTransactionToBank = `${server_api}/api/transferCompanyTransactionToBank`;
const transfertobankUser = `${server_api}/api/transfertobankUser`;
const TransferToMainFundCollector_user = `${server_api}/api/TransferToMainFundCollector_user`;
const transferToMainFundCollector_company = `${server_api}/api/transferToMainFundCollector_company`;
const getAllFundCollectors = `${server_api}/api/getAllFundCollectors`;
const getNotification = `${server_api}/api/getNotification`
const viewNotification = `${server_api}/api/viewNotification`
const insertcompanyprofit = `${server_api}/api/insertcompanyprofit`
const UpdateCompanyTransaction = `${server_api}/api/UpdateCompanyTransaction`
const getVerifiedCompanyTransactionDetails = `${server_api}/api/getVerifiedCompanyTransactionDetails`
const getUserExpenseDetails = `${server_api}/api/getUserExpenseDetails`
const getmfCollectors = `${server_api}/api/mf_fundcollector`
const UpdateCompanyInvestment = `${server_api}/api/UpdateCompanyInvestment`
const UpdateExpenseFromMainFundCollector = `${server_api}/api/UpdateExpenseFromMainFundCollector`
const editEIBKunooz = `${server_api}/api/editEIBKunooz`


export {
  getUserExpenseDetails,
  getVerifiedCompanyTransactionDetails,
  getFundCollectorById,
  addZakat,
  reSendmail,
  TransferToMainFundCollector_user,
  fundTransferDetailsCompany,
  fundTransferDetailsUser,
  InsertCompanyTransaction,
  selecttreasurer,
  getSuperAdmin,
  FundApproval,
  InsertUserTransaction,
  EditUserTransaction,
  getSipById,
  InsertZakat,
  UpdateZakat,
  getBankInOfProfit,
  ListZakat,
  investmentreturnIn,
  logInSuperAdmin,
  getFundCollectorActivity,
  insertBmiId,
  getAllBmiId,
  sendMailToUser,
  getPendingRequest,
  getUserDetails,
  approveUser,
  getActiveMembersList,
  getFundCollectors,
  InsertFundCollector,
  getTreasurer,
  InsertTreasurer,
  getMainFundCollectors,
  InsertMainFundcollector,
  getDeactiveMembersList,
  getTermsConditions,
  insertTermsConditions,
  updateTermsConditions,
  deactivatebySuperadmin,
  listEibKunooz,
  insertEibKunooz,
  updateMonthlySip,
  loginTresurer,
  expenseIn,
  ListAmount,
  investmentSummary,
  getTransferUserTransactionDetails,
  getTransferCompanyTransactionDetails,
  ApproveCompanyTransactionByTreasurer,
  ApproveUserTransactionByTreasurer,
  getAccountSummary,
  monthlysip_in,
  getProfitSummary,
  getExpenseSummary,
  getExpesnseOut,
  InsertExpense,
  UpdateExpense,
  getCompanyInvestmentBankOut,
  getCompanyDetails,
  InsertCompany,
  UpdateCompany,
  checkInvestmentAvailability,
  LoginFundCollector,
  ListExpense,
  TransferExpense,
  ApprovingZakat,
  selectfundcollector,
  UpdateSettled,
  approved_inactive_members,
  ApprovalCompanyInvestment,
  pending_advance,
  getMonthlySipList,
  getVerifiedUserTransactionDetails,
  // getTransferCompanyTransactionDetails,
  transferCompanyTransactionToBank,
  transfertobankUser,
  transferToMainFundCollector_company,
  getAllFundCollectors,
  othersIn,
  getNotification,
  viewNotification,
  insertcompanyprofit,
  UpdateCompanyTransaction,
  getmfCollectors,
  UpdateCompanyInvestment,
  UpdateExpenseFromMainFundCollector,
  editEIBKunooz
};
