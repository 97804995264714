import React, { useEffect, useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from '../ActiveMem/ActiveMemberStyled';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import { useNavigate } from 'react-router-dom';
import { A } from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle';
import axios from 'axios';
import { getPendingRequest } from '../../../Const/Api/Api';
import { useDispatch } from 'react-redux';
import { PendingReq } from '../../../features/notifications/notificationSlice';

function PendingRequest({ countUpdate, setCountUpdate }) {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [type, setType] = useState('text');
  const [amountType, setAmountType] = useState(null);
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const pendingRequests = async () => {
    const data1 = []
    const resp = await axios.get(`${getPendingRequest}`)
    console.log(resp, 'fattatataa');
    dispatch(PendingReq(resp?.data.length));
    resp?.data?.map(item => {
      data1.push({
        bmi_id: item?.bmi_id,
        name: item?.name,
        joining_date: item?.joining_date,
        email: item?.email,
        contact: item?.contact,
        col: <A onClick={() => {
          navigation('view', { state: { id: item?.id } });
        }}>View Details</A>
      })
      setData(data1)
    })
  }

  useEffect(() => {
    pendingRequests()
  }, [navigation])


  const columns = React.useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'name',
      },
      {
        Header: 'BMIP Membership No',
        accessor: 'bmi_id',
      },
      {
        Header: 'BMIP Joining Date',
        accessor: 'joining_date',
      },
      {
        Header: 'Mobile No',
        accessor: 'contact',
      },
      {
        Header: 'Email ID',
        accessor: 'email',
      },
      {
        Header: '',
        accessor: 'col',
        // Cell: cellInfo => {
        //   return (
        //     <A onClick={() => {
        //       navigation('view', { state: { userId: cellInfo.value } });
        //     }}>View Details</A>
        //   )
        // }
      },
    ],
    []
  );

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Pending Request</MenuText>
      </MenuBar>

      <ContentDiv1>
        <TableContainer>
          <AdminTable columns={columns} data={data} />
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default PendingRequest;
