import React from 'react'
import { CheckTable, CheckTableDiv, Modal, ModalContainerR, ModalContent, ModalHeader } from './CompanyDetailsStyles'
import { AiOutlineClose } from 'react-icons/ai'
import { numberFormatter } from '../../../../NumberFormatter'

const MembersModal = (props) => {
    console.log("props=>", props.membersList);
    return (
        <Modal onClick={() => props.setMembers(false)}>
            <ModalContainerR onClick={(e) => { e.stopPropagation() }}>
                <ModalHeader>
                    <h1>No. of Members</h1>
                    <span onClick={() => props.setMembers(false)} ><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>
                    <CheckTableDiv>
                        <CheckTable>
                            <tr>
                                <th>Serial No.</th>
                                <th>BMIP ID</th>
                                <th>Member Name</th>
                                <th>Profit (AED)</th>
                                <th>Invested Amount</th>
                            </tr>
                            {props.membersList.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.bmi_id}</td>
                                    <td>{item?.name}</td>
                                    <td>{numberFormatter(Math.round(item?.profit * 100) / 100)}</td>
                                    <td>{numberFormatter(Math.round(item?.invested_amount * 100) / 100)}</td>
                                </tr>
                            ))
                            }
                        </CheckTable>
                    </CheckTableDiv>

                </ModalContent>
            </ModalContainerR>
        </Modal>
    )
}

export default MembersModal