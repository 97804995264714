import React from 'react';
import { server_api } from '../../../../Api/Api';
import { FlexR } from './BeneficiaryStyle';
import SingleRow from './SingleRow';
import {
  A,
  GridFull,
  Key,
  Row1,
  Span1,
  Title,
  Value,
} from './ViewMembersStyle';

const Beneficiary = ({
  national_id_proof_type,
  relation,
  address,
  number,
  share,
  dob,
  bankAcDetails,
  branch,
  bankName,
  ifsc,
  docUrl,
  docName,
  passDetails,
  acc_no,
  document,
  passport_expiry,
  name
  
}) => {
  console.log('ifsc: ', ifsc);
  return (
    <div>
      <Title>Beneficiary</Title>
      <GridFull>
        <div>
          {name && (
            <SingleRow title='Name' value={name} size='250px' />
          )}
          {relation && (
            <SingleRow
              title='relationship with beneficiary'
              value={relation}
              size='250px'
            />
          )}
          {address && (
            <SingleRow title='address(India)' value={address} size='250px' />
          )}
          {number && (
            <SingleRow title='contact number' value={number} size='250px' />
          )}

          {share && (
            <SingleRow
              title='(% of share to be benefitted)'
              value={share}
              size='250px'
            />
          )}
        </div>
        <div>
          {docUrl && (
            <Row1>
              <Key width='250px'>
                <span>ID Details</span>
                <div>:</div>
              </Key>
              <Value>
                {national_id_proof_type ? (
                  <>
                    <span>{national_id_proof_type}</span>
                    {/* <FlexR>
                      <Span1 style={{ display: 'block' }}>
                        Passport No &nbsp;:
                      </Span1>
                      <span>{passDetails}</span>
                    </FlexR> */}
                    <A
                      style={{ justifyContent: 'left' }}
                      download
                      target='_blank'
                      href={`${server_api}/${document}`}
                    >
                      {national_id_proof_type}
                    </A>
                  </>
                ) : null}
              </Value>
            </Row1>
          )}
          {docUrl && (
            <Row1>
              <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
              <Key width='250px'>
                <span>Passport Details</span>
                <div>:</div>
              </Key>
              <Value>
                {passDetails ? (
                  <>
                    <FlexR>
                      <Span1 style={{ display: 'block' }}>
                        Passport No &nbsp;:
                      </Span1>
                      <span>{passDetails}</span>
                    </FlexR>
                    <A
                      href={`${docUrl}`}
                      target='_blank'
                      style={{ justifyContent: 'left' , width: '100px'}}
                      download
                    >
                      Passport
                    </A>
                    <FlexR style={{flexDirection: 'row', width: 'unset'}}>
                      <Span1 style={{ display: 'block' }}>
                      Expiry Date &nbsp;:
                      </Span1>
                      <span>{passport_expiry}</span>
                    </FlexR>
                    
                  </>
                ) : null}
              </Value>
              </div>
            </Row1>
          )}
          {dob && <SingleRow title='date of birth' value={dob} size='250px' />}
          {/* { <SingleRow title='bank account details' value={bankAcDetails} size='250px' />} */}
        </div>
        <div>
          <p style={{ paddingBottom: '15px', fontWeight: 'bold' }}>
            Bank Account Details
          </p>
          {branch && <SingleRow title='branch' value={branch} size='180px' />}
          {bankName && (
            <SingleRow title='bank name' value={bankName} size='180px' />
          )}
          {ifsc && <SingleRow title='branch ifsc' value={ifsc} size='180px' />}
          {acc_no && (
            <SingleRow title='account number' value={acc_no} size='180px' />
          )}
        </div>
      </GridFull>
    </div>
  );
};

export default Beneficiary;
