import React, { useEffect } from 'react';
import {
  Container,
  Subcontainer,
  Topbar,
  Details,
  DateWrappered,
  Add,
  TextField,
  Button,
  DateWrappers,
} from './AddZakatStyled';
import { AiOutlineClose } from 'react-icons/ai';
import { useState } from 'react';
import axios from 'axios';
import {
  addZakat,
  InsertZakat,
  selecttreasurer,
} from '../../../../Const/Api/Api';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';

const AddZakat = ({ setOpen, getZakat }) => {
  const [fromDate, setFromDate] = useState('');
  console.log('fromDatezakat11: ', fromDate);
  const [toDate, setToDate] = useState('');
  console.log('toDatezakat11: ', toDate);
  const [profit, setProfit] = useState('');
  const [zakat, setZakat] = useState('');
  const [zakatDetails, setZakatDetails] = useState('');
  const [treasurer, setTreasurer] = useState([]);
  console.log('treasurer: ', treasurer);

  // const getTreasurer = () => {
  //   axios
  //     .get(`${selecttreasurer}/${localStorage.getItem('userid')}`)
  //     .then((resp) => {
  //       setTreasurer(resp?.data);
  //     });
  // };
  // useEffect(() => {
  //   getTreasurer();
  // }, []);
  const user = useSelector((state) => state.login.user);

  const addHandler = async () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = dd + '-' + mm + '-' + yyyy;
    const data = {
      date: today,
      from_date: fromDate,
      to_date: toDate,
      profit,
      zakat,
      treasurer: user.id,
      zakat_details: zakatDetails,
    };
    profit
      ? await axios.post(InsertZakat, data).then((res) => {
          console.log(data, 'akr');
          if(res?.data?.code == 200){
            setOpen(false);
            getZakat();
            swal({text: "Zakat added", icon: "success"})
          }else{
            swal({text: res?.data?.message, icon: "warning"})
          }
        })
      : swal({ text: 'Profit cannot be 0', icon: 'warning' });
  };
  
  useEffect(() => {
    getProfit()
  },[fromDate, toDate])

  const getProfit = async () => {
    if (fromDate && toDate) {
      const resp = await axios.post(addZakat, { fromDate, toDate });
      console.log('respzakat11: ', resp);
      setProfit(resp.data.profit);
      setZakat(resp.data.zakat);
      console.log("zakat11HIIIIIIITTTT")
    }
    console.log("zakat11NOOOOOO")
  };
  const style = {
    pointerEvents: 'none',
  };
  return (
    <Container>
      <Subcontainer>
        <Topbar>
          <p>Add Zakat</p>
          <AiOutlineClose onClick={() => setOpen(false)} />
        </Topbar>
        <Details>
          <DateWrappered>
            <span>From</span>
            <input
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
                // getProfit();
              }}
              type='Date'
            />
          </DateWrappered>
          <DateWrappered>
            <span>To</span>
            <input
              value={toDate}
              onChange={(e) => {
                // getProfit();
                setToDate(e.target.value);
              }}
              type='Date'
            />
          </DateWrappered>
        </Details>
        <DateWrappers>
          <Add>
            <p>Profit</p>
            <input placeholder='AED' value={profit} style={style} />
          </Add>
          {/* : */}
          <Add>
            <p>Zakat</p>
            <input value={zakat} placeholder='AED' style={style} />
          </Add>
        </DateWrappers>
        <TextField
          value={zakatDetails}
          placeholder='Add zakat details'
          onChange={(e) => setZakatDetails(e.target.value)}
        />
        <Button onClick={addHandler}>Submit</Button>
      </Subcontainer>
    </Container>
  );
};

export default AddZakat;
