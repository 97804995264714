import React, { useEffect, useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv1,
  MenuBar,
  MenuRightDiv,
  MenuText,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
} from './AssignFundStyled';
import AssignFunds from '../Modal/AssignFunds';
import EditFunds from '../Modal/EditFunds';
import axios from 'axios';
import { getActiveMembersList, getAllBmiId, getFundCollectors, getmfCollectors } from '../../../Const/Api/Api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import {
  displayDate,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx'

function AssignFund() {
  const [assign, setAssign] = useState(false);
  const [edit, setEdit] = useState(false);
  const [users, setUsers] = useState([]);
  console.log('users: ', users);
  const [editUser, setEditUser] = useState({});
  const [nav, setNav] = useState(false);
  const [allBmi, setAllBmi] = useState([]);
  const [data, setData] = useState([])
  // console.log('data:23232 ', data);
  // console.log('allBmi: ', allBmi);
  const [id, setId] = useState([]);
  console.log('id: ', id);
  const getUSers = async () => {
    const data = [];
    const resp = await axios.get(getFundCollectors);
    // console.log('🚀 ~ file: AssignFund.js:39 ~ getUSers ~ resp', resp);
    // resp?.data?.filter((item) => {
    //   data.push({
    //     bmi_id: item?.bmi_id,
    //     bmipno: item?.bmipno,
    //     name: item?.name,
    //     starting_date: displayDate(item?.starting_date),
    //     col: (
    //       <Click
    //         onClick={() => {
    //           setNav(true);
    //           const data = {
    //             bmi_id: item?.bmi_id,
    //             name: item?.name,
    //             bmipno: item?.bmipno,
    //             starting_date: formatDate(item?.starting_date),
    //           };
    //           setEditUser(data);
    //         }}
    //       >
    //         Edit
    //       </Click>
    //     ),
    //   });
    //   setUsers(data);
    // });
  };
  const getLists = () => {
    const lists = []
    Promise.all([fetch(getFundCollectors), fetch(getmfCollectors)])
      .then(([api1Response, api2Response]) => {
        return Promise.all([api1Response.json(), api2Response.json()]);
      })
      .then(([api1Data, api2Data]) => {
        console.log('api2Data: ', api2Data);
        console.log('api1Data: ', api1Data);
        const d = api1Data.concat(api2Data)
        d?.map(item => {
          console.log('item: ', item);
          return (

            lists.push({
              bmi_id: item?.bmi_id,
              bmipno: item?.bmipno,
              name: item?.name,
              starting_date: displayDate(item?.starting_date),
              col: (
                <Click
                  onClick={() => {
                    setNav(true);
                    const data = {
                      bmi_id: item?.bmi_id,
                      name: item?.name,
                      bmipno: item?.bmipno,
                      starting_date: formatDate(item?.starting_date),
                    };
                    setEditUser(data);
                  }}
                >
                  Edit
                </Click>
              ),
            })
          )
        })
        setUsers(lists)
      })
  }

  useEffect(() => {

    getLists()

  }, []);


  useEffect(() => {
    getUSers();
  }, []);
  useEffect(() => {
    nav && setEdit(true);
  }, [editUser]);

  const columns = React.useMemo(
    () => [
      // {
      //   Header: 'Role',
      //   accessor: 'role'
      // },
      {
        Header: 'BMIP ID',
        accessor: 'bmipno',
      },
      {
        Header: 'Member Name',
        accessor: 'name',
      },
      {
        Header: 'Starting Date',
        accessor: 'starting_date',
      },
      {
        Header: '',
        accessor: 'col',
      },
    ],
    []
  );
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAllBmi = () => {
    const data1 = [];
    axios.get(getActiveMembersList).then((resp) => {
      resp?.data?.map((i) => {
        data1.push({
          value: i?.id,
          label: `${i?.full_name}-${i?.bmi_id}`,
        });
      });
      setAllBmi(resp?.data);
      setId(data1);
    });
  };

  useEffect(() => {
    getAllBmi();
  }, []);
  return (
    <Container>
      {edit === true ? (
        <EditFunds setAssign={setEdit} {...editUser} allBmi={allBmi} ids={id} getUsers={getLists} />
      ) : (
        ''
      )}
      {assign === true ? (
        <AssignFunds
          setOpenModal={setAssign}
          getUsers={getLists}
          allBmi={allBmi}
          ids={id}
        />
      ) : (
        ''
      )}
      <MenuBar secondary>
        <MenuText>Assign Fund Collector</MenuText>
        <MenuRightDiv>
          <Button onClick={() => setAssign(true)}>Assign Fund Collector</Button>
        </MenuRightDiv>
      </MenuBar>
      <ContentDiv1>
        <TableTopBar>
          <TableTopItems></TableTopItems>
          <label
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
        </TableTopBar>
        <TableContainer>
          <AdminTable columns={columns} data={users} />
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default AssignFund;
