import React, { useRef, useState } from "react";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import { Styles, Top } from "./AdminTable.styled";
import { BiSort } from "react-icons/bi";
import { InputElement, SelectElement } from "../../Const/Input/Input";
import { SaveButtonElement } from "../../Const/Button/Button";

function Treasurerapprovaltab({ data, columns, serial_no }) {
  const inputRef = useRef(null);
  const [value1, setValue1] = useState();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const settingFilter = (e) => {
    setGlobalFilter(e.target.value);
    setValue1(e.target.value);
    inputRef.current?.focus();
  };

  React.useEffect(() => {
    // inputRef.current?.focus();
  }, []);

  return (
    <Styles>
        <Top></Top>

      <table {...getTableProps()} id = "my-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {serial_no && <th>Serial No.</th>}
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* <span
                    style={{
                      float: "right",
                      color: "#c4c4c4",
                    }}>
                    {column.isSorted ? (
                      column.isSortDesc ? (
                        <BiSort fontSize={12} />
                      ) : (
                        <BiSort fontSize={12} />
                      )
                    ) : column.isSortDesc ? (
                      <BiSort fontSize={12} />
                    ) : (
                      <BiSort fontSize={12} />
                    )}
                  </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {serial_no && <td>{i+1}</td>}
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <div className="pagination-buttons">
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canPreviousPage ? "#c4c4c4" : "#c4c4c4"}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}>
            {"<<"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canPreviousPage ? "#c4c4c4" : "#999999"}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}>
            {"<"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canNextPage ? "#c4c4c4" : "#999999"}
            onClick={() => nextPage()}
            disabled={!canNextPage}>
            {">"}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: "auto", height: "auto" }}
            type={!canNextPage ? "#c4c4c4" : "#999999"}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}>
            {">>"}
          </SaveButtonElement>
        </div>
        &nbsp;
        <div className="pagination-buttons">
          <span>
            Page&nbsp;
            <strong>
              {pageIndex + 1}&nbsp; of &nbsp; {pageOptions.length}
            </strong>
            &nbsp;
          </span>
          <span>|&nbsp; Go to page: </span>&nbsp;
          <InputElement
            type="number"
            style={{ width: "auto", height: "auto" }}
            placeholder=""
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}></InputElement>
          &nbsp;
          <SelectElement
            style={{ width: "auto", height: "auto" }}
            placeholder=""
            dropdown="true"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </SelectElement>
        </div>
      </div>
    </Styles>
  );
}
export default Treasurerapprovaltab;
