import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { ButtonsModal, Flex, FlexEnd, ModalContent, Overlay, P } from './AddEibStyle';

const ConfirmMemberTransactions = ({ setShowModal, confirmed }) => {
    const navigation = useNavigate()

    return (
        <Overlay onClick={() => { setShowModal(false) }} >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <Flex>
                    <h4 style={{ fontWeight: 500 }}>Update Confirmation</h4>
                </Flex>
                <P>Are you sure you want to update the member transaction?</P>
                <FlexEnd>
                    <ButtonsModal onClick={() => { setShowModal(false); confirmed(); }}>
                        Yes
                    </ButtonsModal>
                    <ButtonsModal onClick={() => { setShowModal(false) }}>
                        Cancel
                    </ButtonsModal>
                </FlexEnd>
            </ModalContent>
        </Overlay>
    )
}
export default ConfirmMemberTransactions

