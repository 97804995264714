import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import { listEibKunooz } from '../../../Const/Api/Api';
import { Colors } from '../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import {
  Button,
  Click,
  Container,
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
} from '../../MainFundCollector/MainFundCollector.styled';
import { JusEnd, Table } from '../ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { AddBtnDiv } from '../CompanyDetails/CompanyDetails/CompanyDetailsStyles';
import { AddEidModal } from '../Modals/Portals';
import { LoadingContainer } from '../Report/FundCollectorActivity/FundCollectorActivityStyles';
import { JustEnd, Main } from './EibKunoozStyle';
import * as XLSX from 'xlsx'
import { FaEdit } from 'react-icons/fa';
import EditInvestmentAmount from '../../MainFundCollector/Modal/EditInvestmentAmount';
import EditKunoozAmount from './EditKunoozAmount';
import { numberFormatter } from '../../../NumberFormatter';

const EibKunooz = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isDateFilterActive, setDateFilterActive] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [editData, setEditData] = useState({});

  const getEibKunooz = async () => {
    const data1 = [];
    const resp = await axios.get(listEibKunooz);
    setLoading(true);
    resp?.data?.map((item) => {
      data1.push({
        date: displayDate(item?.date),
        amount: (
          <>
            {item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0'} &nbsp;
            {/* <Click
              onClick={() => {
                setEditData(item)
                setShowEditModel(true)
              }}
            >
              <FaEdit />
            </Click> */}
          </>
        ),
      });
      setData(data1.reverse());
    });
  };
  useEffect(() => {
    getEibKunooz();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'EIB Kunooz Amount (AED)',
      accessor: 'amount',
    },
  ]);

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      setDateFilterActive(true);
      const filteredData = data.filter((item) => {
        console.log('item: ', item);
        const check = new Date(formatDate(item?.date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      {showEditModel && (
        <EditKunoozAmount setShowEditModel={setShowEditModel} getEibKunooz={getEibKunooz} data={editData} />
      )}
      <MenuBar secondary>
        <MenuText>EIB Kunooz</MenuText>
        <MenuRightDiv>
          <AddBtnDiv>
            <Button onClick={() => setShowModal(true)}>Add EIB Kunooz</Button>
          </AddBtnDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={dateFilterChangeHandler}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <JusEnd style={{ padding: '20px' }}>
        <a
          style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
          onClick={() => exportToExcel()}
        >
          Download
        </a>
      </JusEnd>
      <Main style={{ padding: '0' }}>
        {showModal && (
          <AddEidModal
            setShowModal={setShowModal}
            getEibKunooz={getEibKunooz}
          />
        )}
        <Table>
          {loading ? (
            <AdminTable
              columns={columns}
              data={isDateFilterActive ? dateFilteredData : data}
            />
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
        </Table>
      </Main>
    </Container>
  );
};

export default EibKunooz;
