import React, { useRef, useState } from 'react';
import AdminTable from '../../../Components/Table/AdminTable';
import { FaEdit } from 'react-icons/fa';
import {
  Button,
  Container,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from '../MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../Const/assets';
import { Click } from '../../SuperAdmin/AssignMain/AssignMainStyled';
import { EditDetails } from '../../Treasurer/Modals/Portals';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import axios from 'axios';
import {
  fundTransferDetailsCompany,
  fundTransferDetailsUser,
  ListAmount,
  transferToMainFundCollector_company,
  TransferToMainFundCollector_user,
} from '../../../Const/Api/Api';
import { useEffect } from 'react';
import ProfileReportModal from '../../Treasurer/CompanyDetails/CompanyDetails/ProfitReportModal';
import swal from 'sweetalert';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx'
import EditAmount from '../Modal/EditAmount';
import { numberFormatter } from '../../../NumberFormatter';

function FundTransferDetails() {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [amountType, setAmountType] = useState('Sip');
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [data2, setData2] = useState([]);
  const [compData, setCompData] = useState([]);
  const user = useSelector((state) => state.login.user);
  const [amountModal, setAmountModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState('');
  const [fullID, setFullID] = useState([]);
  const [amountTypeId, setAmountTypeId] = useState(1);
  const [memberDetails, setMemberDetails] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const modalRef = useRef(null);
  const [company, setCompany] = useState([])
  const [showEditModel, setShowEditModel] = useState(false);
  const [editData, setEditData] = useState({});

  const getData = async () => {
    const resp = await axios.get(`${fundTransferDetailsUser}?bmi_id=${user?.id}`);

    const data = resp?.data.map((item) => {
      const temp = {
        bmi_id: item?.bmi_id,
        member_name: item?.member_name,
        date_of_payment: displayDate(item?.date_of_payment),
        amount: item?.amount,
        remarks: item?.remarks,
        amount_cat_id: item?.amount_cat_id,
        amount_type: item?.amount_type,
        transaction_id: item?.user_transaction_id
      };

      return {
        bmi_id: item?.bmi_id,
        member_name: item?.member_name,
        date_of_payment: displayDate(item?.date_of_payment),
        new_amount: item?.amount,
        amount: (item?.transfer_verification ? item?.amount : (
          <>
            {numberFormatter(Math.round(Number(item?.amount) * 100) / 100)} &nbsp;
            <Click
              onClick={() => {
                setEditData(temp)
                setShowEditModel(true)
              }}
            >
              <FaEdit />
            </Click>
          </>
        )),
        remarks: item?.remarks,
        amount_type: item?.amount_type,
        user_transaction_id: item?.user_transaction_id,
        transfer_verification: item?.transfer_verification
          ? 'Approved'
          : item?.transfer
            ? 'Pending'
            : '',
      };
    });
    setData(data);
  };

  const transferHandler = () => {
    const data = {
      amount_cate_id: amountTypeId,
      idArray: JSON.stringify(fullID),
    };
    axios
      .post(
        amountTypeId == 4 || amountTypeId == 5
          ? transferToMainFundCollector_company
          : TransferToMainFundCollector_user,
        data
      )
      .then((resp) => {
        if (resp?.data?.code === 200) {
          swal({ text: resp?.data?.message, icon: 'success' });
          getCompData();
          getData();
        } else {
          swal({ text: resp?.data?.message, icon: 'error' });
        }
      })
      .catch((err) => err);
  };

  const getCompData = async () => {
    const resp = await axios.get(`${fundTransferDetailsCompany}?bmi_id=${user?.id}`);
    const data = resp?.data?.map((item) => {
      return {
        date: displayDate(item?.date),
        name: item?.name,
        new_amount: item?.amount,
        amount: numberFormatter(Math.round(Number(item?.amount) * 100) / 100),
        // amount: item?.amount_cat_id == 5 ? (
        //   <Click onClick={() => setAmountModal(!amountModal)}>
        //     {item?.amount}
        //   </Click>
        // ) : item?.amount,
        remarks: item?.remarks,
        collected_from: item?.collected_from,
        amount_type: item?.amount_cat_id,
        transfer: item?.transfer,
        transfer_verification: item?.transfer_verification
          ? 'Approved'
          : item?.transfer
            ? 'Pending'
            : '',
        edit: item?.transfer == 0 ? <Click onClick={() => { setEdit(true); setCompany(item) }}>Edit</Click> : '',
        amountT: item?.amount,
        amountCat: item?.amount_cat_id,
        id: item?.id,
      };
    });
    setCompData(data);
  };

  const filterData = () => {
    let total = 0;
    let fullId = [];

    if (amountType === 'Investment Return' || amountType === 'Profit') {
      let filteredArray = compData.filter((item) => {
        if (item?.amountCat == amountTypeId) {
          if (item?.transfer_verification === '') {
            total += +item?.new_amount;
            fullId.push(item?.id);
          }
        }
        return item?.amountCat == amountTypeId;
      });
      setData2(filteredArray);
      setTotalAmount(total);
      setFullID(fullId);
    } else {
      let filteredArray = data.filter((item) => {
        if (item?.amount_type == amountType) {
          if (item?.transfer_verification === '') {
            total += +item?.new_amount;
            fullId.push(item?.user_transaction_id);
          }
        }
        return item?.amount_type == amountType;
      });
      setData2(filteredArray);
    }

    setTotalAmount(total);
    setFullID(fullId);
  };
  useEffect(() => {
    filterData();
  }, [amountType, data]);

  const getAmountType = async () => {
    await axios.get(`${ListAmount}`).then((res) => setAmountData(res.data));
  };

  useEffect(() => {
    getAmountType();
    getData();
    getCompData();

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      // Close the modal
      setOpenDropDown(false);
    }
  };
  const columns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id',
    },
    {
      Header: 'Member Name',
      accessor: 'member_name',
    },
    {
      Header: 'Date of Payment',
      accessor: 'date_of_payment',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: 'Status',
      accessor: 'transfer_verification',
    },
    // {
    //   Header: '',
    //   accessor: 'edit',
    // },
  ]
  const columns2 = [
    {
      Header: 'Date of Payment',
      accessor: 'date', // accessor is the "key" in the data
    },
    {
      Header: 'Company Name',
      accessor: 'name',
    },
    {
      Header: 'Amount',
      accessor: 'amount',
    },
    {
      Header: 'Collected From',
      accessor: 'collected_from',
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: 'Status',
      accessor: 'transfer_verification',
    },
    {
      Header: 'Action',
      accessor: 'edit',
    },
  ]
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive
      ? dateFilteredData
      : amountType === 'Profit' ||
        amountType === 'Investment Return'
        ? data2
        : data2);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const filterClickHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      setIsDateFilterActive(true);
      if (amountTypeId === 4 || amountTypeId === 5) {
        const filteredData = compData.filter((item) => {
          const check = new Date(formatDate(item?.date));
          return dateBetweenCheck(startDate, endDate, check) && item;
        });
        setDateFilteredData(filteredData);
      } else {
        const filteredData = data2.filter((item) => {
          const check = new Date(formatDate(item?.date_of_payment));
          return dateBetweenCheck(startDate, endDate, check) && item;
        });
        setDateFilteredData(filteredData);
      }
    }
  };

  return (
    <Container>
      {
        showEditModel && (
          <EditAmount setShowEditModel={setShowEditModel} getData={getData} amountCategoryId={editData?.amount_cat_id} editAmount={editData?.amount} transactionId={editData?.transaction_id} bmiId={editData?.bmi_id} />
        )
      }
      {amountModal && (
        <ProfileReportModal
          setReport={setAmountModal}
          profitList={memberDetails}
        />
      )}
      {edit && <EditDetails company={company} editData={editData} setShowModal={setEdit} getCompData={getCompData} />}
      <MenuBar secondary>
        <MenuText>Fund Transfer Details</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={filterClickHandler}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <DropDown
              ref={modalRef}
              onClick={() => setOpenDropDown(!openDropDown)}
              width='150px'
              style={{ position: 'relative' }}
            >
              <span>{amountType ? amountType : 'Select'}</span>
              <ImgDiv>
                {openDropDown ? (
                  <img src={BlackArrowUp} alt='' />
                ) : (
                  <img src={BlackArrowDown} alt='' />
                )}
              </ImgDiv>
            </DropDown>
            <input
              type='text'
              style={{ width: 'auto', pointerEvents: 'none' }}
              value={`AED ${totalAmount}`}
            ></input>
            <Button onClick={transferHandler} style={{ padding: '12px' }}>
              Transfer
            </Button>
          </TableTopItems>
          <label
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
        </TableTopBar>
        {openDropDown ? (
          <DropDownDrawer>
            {amountData?.map((item) => (
              <DropDownItem
                onClick={() => [
                  setAmountType(item?.amount_type),
                  setOpenDropDown(false),
                  filterData(item?.amount_type),
                  setAmountTypeId(item?.id),
                  setIsDateFilterActive(false),
                  setStartDate(''),
                  setEndDate(''),
                ]}
              >
                <span>{item?.amount_type}</span>
              </DropDownItem>
            ))}
          </DropDownDrawer>
        ) : (
          ''
        )}

        <TableContainer>
          {
            <AdminTable
              columns={
                amountType === 'Profit' || amountType === 'Investment Return'
                  ? columns2
                  : columns
              }
              data={
                isDateFilterActive
                  ? dateFilteredData
                  : amountType === 'Profit' ||
                    amountType === 'Investment Return'
                    ? data2
                    : data2
              }
            />
          }
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default FundTransferDetails;

export const formatDate = (date) => {
  if (date) {
    const testDate = date.includes('/') ? date.replaceAll('/', '-') : date;
    const first = testDate.split('-')[0];
    if (first.length === 2) {
      return testDate.split('-').reverse().join('-');
    } else {
      return testDate;
    }
  }
};

export const displayDate = (date) => {
  if (date) {
    const testDate = date.includes('/') ? date.replaceAll('/', '-') : date;
    const first = testDate.split('-')[0];
    if (first.length === 4) {
      return testDate.split('-').reverse().join('-');
    } else {
      return testDate;
    }
  }
};

export const dateBetweenCheck = (startDate, endDate, check) => {
  if (startDate && check) {
    if (startDate !== '' && endDate === '') {
      var from = new Date(formatDate(startDate));
      var to = new Date(Date.now());
      return check >= from && check <= to;
    } else {
      var from = new Date(formatDate(startDate));
      var to = new Date(formatDate(endDate));
      return check >= from && check <= to;
    }
  }
};
