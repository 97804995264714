import styled from "styled-components";
import { Colors } from "../../Const/Colors/Theme";

const Container = styled.div`
width:100%;
height:100vh;
align-items:center;
display:flex;
justify-content:center;
background-color:${Colors.blue}
`

const LoginContainer = styled.div`

width:400px;
min-width: 300px;

padding:20px;

align-items:center;
display:flex;
flex-direction:column;
border:1px solid ${Colors.white};
border-radius:8px;
    @media screen and  (max-width : 400px){
        margin: 1rem;
        width: auto;
    }
`


const Logos = styled.div`
    width:100%;
    height:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    margin:5px 0;
    img {
        width:100px;
        height:100px;
    }
    `

const Loginbar = styled.div`
width:100%;
align-items:center;
display:flex;
flex-direction:row;
justify-content:center;
gap: 10px;
`

const Loginbtn = styled.button`
border-radius:8px;
width:200px;
// padding:5px 15px;
height:45px;
font-size:14px;
background-color:${Colors.blue};
border:1px solid ${Colors.white};
margin:0 0px;
color:${Colors.white};
cursor:pointer;
`

const SuperLogin = styled.div`
width:100%;
align-items:center;
justify-content:center;

`

const SLoginbtn = styled.button`
border-radius:8px;
width:100%;
// padding:5px 15px;
height:45px;
font-size:14px;
background-color:${Colors.blue};
border:1px solid ${Colors.white};
margin:12px 0px;
color:${Colors.white};
align-items:center;
justify-content:center;
cursor:pointer;
`

export {
    Container,
    LoginContainer,
    Logos,
    Loginbar,
    Loginbtn,
    SuperLogin,
    SLoginbtn

}