import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Item,
  LinkBtn,
  NavOption,
  Sidenav,
} from './SuperAdminsidebarStyled';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getPendingRequest } from '../../../Const/Api/Api';
import { CountDiv } from '../../Treasurer/SideNavbarStyled';
import { useSelector } from 'react-redux';

function SuperAdminsidebar() {
  const [isFundCollector, setIsFundCollector] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();
  // const [pendData, setPendData] = useState()

  const fundCollector = () => {
    setIsFundCollector((isFundCollector) => !isFundCollector);
  };
  const { PendingReq } = useSelector((state) => state.notification);
  const { menuOpen } = useSelector((state) => state.others);

  // const pendingReq = () => {
  //   axios.get(getPendingRequest).then(resp => {
  //     setPendData(resp?.data?.length)
  //   })
  // }
  // useEffect(() => {
  //   pendingReq()
  // },[pendData, countUpdate])

  return (
    <Container menuOpen={menuOpen}>
      <Sidenav>
        <NavOption>
          <LinkBtn
            click={
              location.pathname === '/fund-collection' ||
              location.pathname === '/fund-transfer-details'
                ? true
                : false
            }
            onClick={fundCollector}
          >
            <AiOutlinePlusSquare />
            User
          </LinkBtn>

          <Item>
            <Button
              click={
                location.pathname == '/super-admin/bmid-creation' ? true : false
              }
              onClick={() => {
                navigation('bmid-creation');
              }}
            >
              <span>-</span> BMID Creation
            </Button>
            <Button
              click={
                location.pathname == '/super-admin/pending-request' ||
                location.pathname == '/super-admin/pending-request/view'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('pending-request');
              }}
            >
              <div style={{ display: 'flex' }}>
                <span>-</span>{' '}
                <p style={{ paddingLeft: '5px' }}>Pending Request</p>
                {PendingReq ? (
                  <CountDiv>
                    <p>{PendingReq}</p>
                  </CountDiv>
                ) : null}
              </div>
            </Button>

            <Button
              click={
                location.pathname.includes('/super-admin/activemembers')
                  ? true
                  : false
              }
              onClick={() => {
                navigation('activemembers');
              }}
            >
              <span>-</span> Active Members
            </Button>
            <Button
              click={
                location.pathname.includes('/super-admin/deactivate-member')
                  ? true
                  : false
              }
              onClick={() => {
                navigation('deactivate-member');
              }}
            >
              <span>-</span> Inactive Members
            </Button>
            <Button
              click={
                location.pathname == '/super-admin/assignfund' ? true : false
              }
              onClick={() => {
                navigation('assignfund');
              }}
            >
              <span>-</span> Assign Fund Collector
            </Button>
            <Button
              click={
                location.pathname == '/super-admin/assign-treasurer'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('assign-treasurer');
              }}
            >
              <span>-</span> Assign Treasurer
            </Button>
            <Button
              click={
                location.pathname == '/super-admin/assign-main-fundcollector'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('assign-main-fundcollector');
              }}
            >
              <span>-</span> Assign Main Fund Collector
            </Button>
            <Button
              click={
                location.pathname == '/super-admin/terms-and-conditions'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('terms-and-conditions');
              }}
            >
              <span>-</span> Terms and Conditions
            </Button>
          </Item>
        </NavOption>
      </Sidenav>
    </Container>
  );
}

export default SuperAdminsidebar;
