import logo from './logo.svg';
import './App.css';
import Login from './Screens/Login/Login';
import Header from './Screens/Header/Header';
import SignIn from './Screens/Login/SignIn';
import MainFundCollector from './dashboards/MainFundCollector';
import BMITreasurer from './dashboards/BMITreasurer';
import SuperAdmin from './dashboards/SuperAdmin';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProtectedRoute from './Screens/Login/ProtectedRoute';
import axios from 'axios';
import {
  FundApproval,
  getDeactiveMembersList,
  getPendingRequest,
  getSuperAdmin,
  getTransferCompanyTransactionDetails,
  getTransferUserTransactionDetails,
  getVerifiedCompanyTransactionDetails,
  getVerifiedUserTransactionDetails,
  ListExpense,
  ListZakat,
  selectfundcollector,
  selecttreasurer,
} from './Const/Api/Api';
import {
  fundRecievedFromCollectors,
  InvestmentFund,
  inactiveMemberFund,
  PendingReq,
  approvalReq,
  expenseCount,
  zakatCount,
} from './features/notifications/notificationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setRole } from './features/login/loginSlice';

function App() {
  const role = localStorage.getItem('role');
  // console.log('superAdmin:app ', superAdmin);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [comData, setComData] = useState([]);
  const pending = data.length + comData.length;
  console.log('pending:111 ', pending);
  const [inactiveCount, setInactiveCount] = useState([]);
  const userId = localStorage.getItem('userid');
  console.log('inactiveCount: 555', inactiveCount.length);
  const [pendData, setPendData] = useState([]);
  const Role = useSelector((state) => state.login);
  console.log('Role:location ', Role);
  const user = useSelector((state) => state.login.user);
  console.log('user:location ', user);
  console.log(Role, 'Role');
  const [verfCom, setVerfCom] = useState([]);
  const [verfUsr, setVerfUsr] = useState([]);
  const location = window.location.pathname;
  const path = useLocation()

  const dispatch = useDispatch();
  useEffect(() => {
    console.log('location:z ', location.replace(/\/$/, '').substring(15));
    //   // const url = location.replace(/\/$/, "").split('/')
    //   // console.log('location11', url);

    //   getSuperAdmins();
    //   if (
    //     location.includes('/super-admin') ||
    //     location.includes('/fund-collector') ||
    //     location.includes('/bmi-treasurer')
    //   ) {
    //   } else {
    //     if (role === '0') {
    //       if (location == '/' || location == '/super-admin') {
    //         navigate('/super-admin/bmid-creation');
    //       } else {
    //         navigate(`/super-admin${location.replace(/\/$/, '').substring(12)}`);
    //       }
    //     }
    //     if (role === '1') {
    //       if (location == '/' || location == '/fund-collector') {
    //         navigate('/fund-collector/fund-collection');
    //       } else {
    //         console.log('hhhhhhh');
    //         navigate(
    //           `/fund-collector${location.replace(/\/$/, '').substring(15)}`
    //         );
    //       }
    //     }
    //     if (role === '2') {
    //       if (location == '/' || location == '/bmi-treasurer') {
    //         navigate('/bmi-treasurer/active-members');
    //       } else {
    //         navigate(
    //           `/bmi-treasurer${location.replace(/\/$/, '').substring(14)}`
    //         );
    //       }
    //     }
    //   }
  }, []);

  const checkAdmin = () => {
    axios({
      method: 'get',
      url: `${getSuperAdmin}?userid=${userId}`,
    }).then((resp) => {
      if (resp.data.length) {
        console.log(resp.data.length, 'Role');
        dispatch(setRole('superadmin'));
        dispatch(setUser(resp.data[0]));
      }
      console.log('akr get user admin', resp.data);
    });
  };

  const getFundCollect = () => {
    axios.get(`${selectfundcollector}/${userId}`).then((resp) => {
      console.log(resp.data, 'akr get fund col');
      if (resp.data.length) {
        dispatch(setUser(resp.data[0]));
        if (
          resp.data[0].fStatus === 0 &&
          resp.data[0].fund_collector_activity === 0
        ) {
          dispatch(setRole('fundcollector'));
        } else if (
          resp.data[0].fStatus === 1 &&
          resp.data[0].fund_collector_activity === 0
        ) {
          dispatch(setRole('mainfundcollector'));
        } else if (
          resp.data[0].fStatus === 1 &&
          resp.data[0].fund_collector_activity === 1
        ) {
          dispatch(setRole('superfundcollector'));
        }
      }
    });
  };
  const getTreasurer = () => {
    axios.get(`${selecttreasurer}/${userId}`).then((resp) => {
      console.log('resp:33421 ', resp);
      if (resp?.data.length) {

        dispatch(setRole('treasurer'));
        dispatch(setUser(resp.data[0]));
      }
      console.log(resp?.data[0], 'treasurer');
    });
  };
  // getting who is logged in
  useEffect(() => {
    if (localStorage.getItem('rl')) {
      if (localStorage.getItem('rl') == '00') {
        checkAdmin();
      } else if (localStorage.getItem('rl') == '01') {
        getTreasurer();
      } else if (localStorage.getItem('rl') == '02') {
        getFundCollect();
      }

    } else {
      navigate('/')
    }
  }, []);

  // navigating based on role

  useEffect(() => {
    console.log("hittt")
    const locArray = location.split('/');
    locArray.shift();
    const PreviousLocation = locArray.join('/');
    console.log(PreviousLocation, 'location');
    if (Role.role === 'superadmin') {
      if (location.includes('super-admin')) {
        navigate(PreviousLocation);
      } else {
        navigate('/super-admin/bmid-creation');
      }
    } else if (Role.role === 'mainfundcollector') {
      if (location.includes('fund-collector')) {
        navigate(PreviousLocation);
      } else {
        navigate('/fund-collector/inactive-member-fund');
      }
    } else if (Role.role === 'treasurer') {
      if (location.includes('bmi-treasurer')) {
        navigate(PreviousLocation);
      } else {
        navigate('/bmi-treasurer/active-members');
      }
    } else if (Role.role === 'fundcollector') {
      navigate('/fund-collector/fund-collection');
    }
  }, [Role.role]);

  const getUserTransaction = () => {
    axios.get(getTransferUserTransactionDetails).then((resp) => {
      const data1 = resp?.data?.filter((i) => i?.transfer_verification == '0');
      setData(data1);
    });
  };

  const getCompanyTransaction = () => {
    axios.get(getTransferCompanyTransactionDetails).then((resp) => {
      const data1 = resp?.data?.filter((i) => i?.transfer_verification == '0');
      setComData(data1);
    });
  };

  useEffect(() => {
    dispatch(approvalReq(data.length + comData.length));
  }, [comData, data]);
  const getInactive = () => {
    axios.get(getDeactiveMembersList).then((resp) => {
      const data1 = resp?.data.filter((i) => i?.status == 2);
      dispatch(inactiveMemberFund(data1.length));
    });
  };
  const getFundCount = () => {
    axios.get(FundApproval).then((resp) => {
      console.log(resp?.data.length, 'akr inv');
      dispatch(InvestmentFund(resp?.data.length));
    });
  };
  const getExpenseCount = () => {
    axios.get(ListExpense).then((resp) => {
      console.log(resp?.data.length, 'akr inv');
      dispatch(expenseCount(resp?.data.length));
    });
  };
  const getZakatCount = () => {
    axios.get(ListZakat).then((resp) => {
      console.log(resp?.data.length, 'akr inv');
      dispatch(zakatCount(resp?.data.length));
    });
  };
  const pendingReq = () => {
    axios.get(getPendingRequest).then((resp) => {
      setPendData(resp?.data);
      console.log(resp?.data.length, 'akr pend s');
      dispatch(PendingReq(resp?.data.length));
    });
  };
  const getVerifiedCompany = () => {
    axios.get(getVerifiedCompanyTransactionDetails).then((resp) => {
      let array = resp?.data?.filter((i) => i?.transferToBank == 0);
      setVerfCom(array);
    });
  };
  const getVerifiedUser = () => {
    axios.get(getVerifiedUserTransactionDetails).then((resp) => {
      let array = resp?.data?.filter((i) => i?.transferToBank == 0);
      setVerfUsr(array);
    });
  };
  useEffect(() => {
    dispatch(fundRecievedFromCollectors(verfUsr.length + verfCom.length));
  }, [verfUsr, verfCom]);

  useEffect(() => {
    console.log(location, 'location');
    if (location.includes('treasurer')) {
      console.log(location, 'treasurer  ');
      getUserTransaction();
      getCompanyTransaction();
    } else if (location.includes('fund-collector')) {
      console.log(location, 'fund col   ');
      getFundCount();
      getInactive();
      getVerifiedCompany();
      getVerifiedUser();
      getExpenseCount()
      getZakatCount()
    } else if (location.includes('super-admin')) {
      console.log(location, 'super adm  ');
      pendingReq();
    }
  }, [Role.role]);

  return (
    // <div className="App">
    //   {/* <Login/> */}
    //   {/* <SignIn/> */}
    //   <Header />
    //   <MainFundCollector />
    //   {/* <BMITreasurer/> */}
    //   {/* <SuperAdmin/> */}
    // </div>
    <>
      <Routes>
        <Route
          path='/'
          element={
            <>
              <Header /> <Login />
            </>
          }
        />
        <Route
          path='/signin'
          element={
            <>
              <Header /> <SignIn />
            </>
          }
        />
        {Role.role === 'mainfundcollector' || Role.role === 'fundcollector' || Role.role === 'superfundcollector' ? <Route
          path='/fund-collector/*'
          element={
            <>
              <ProtectedRoute>
                <Header /> <MainFundCollector />
              </ProtectedRoute>
            </>
          }
        /> : ''}
        {Role.role === 'treasurer' && <Route
          path='/bmi-treasurer/*'
          element={
            <>
              <ProtectedRoute>
                <Header /> <BMITreasurer />
              </ProtectedRoute>
            </>
          }
        />}
        {Role.role === 'superadmin' && <Route
          path='/super-admin/*'
          element={
            <>
              <ProtectedRoute>
                <Header /> <SuperAdmin />
              </ProtectedRoute>
            </>
          }
        />}
        {/* <Route path = '/super-admin/*' element = {<><Header/> <SuperAdmin/> </>}/> */}
      </Routes>
    </>
  );
}

export default App;
