import axios from 'axios';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { deactivatebySuperadmin, getActiveMembersList } from '../../../Const/Api/Api';
import { ButtonsModal, Flex, FlexEnd, ModalContent, Overlay, P } from './AddEibStyle';

const ConfirmMod = ({ setShowModal, bmi_id }) => {
    console.log('bmi_id:777 ', bmi_id);
    const navigation = useNavigate()


    const deactiveUser = () => {
        axios.post(deactivatebySuperadmin, { bmi_id })
            .then(resp => {
                console.log(resp.data, "sassasa")
                if (resp?.data?.code == 200) {
                    swal({ text: "Deactivated", icon: "success" })
                }
                else {

                    swal({ text: resp?.data?.message, icon: "warning" })
                }
                navigation('/super-admin/activemembers')

            }).catch(e => {
                console.log("error", e)
            })
    }
    return (
        <Overlay onClick={() => { setShowModal(false) }} >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <Flex>
                    <h4 style={{ fontWeight: 500 }}>Deactivate Confirmation</h4>
                </Flex>
                <P>Are you sure you want to deactivate ?</P>
                <FlexEnd>
                    <ButtonsModal onClick={() => { setShowModal(false); deactiveUser() }}>
                        Yes
                    </ButtonsModal>
                    <ButtonsModal onClick={() => { setShowModal(false) }}>
                        Cancel
                    </ButtonsModal>
                </FlexEnd>
            </ModalContent>
        </Overlay>
    )
}
export default ConfirmMod

