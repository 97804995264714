import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsX } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import {
  InsertExpense,
  selecttreasurer,
  UpdateExpense,
} from '../../../../Const/Api/Api';
import { formatDate } from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import {
  Container,
  Modal,
  First,
  Second,
  Fst,
  Scnd,
  Lft,
  Ryt,
} from './AddExpenseStyled';

function EditExpenses(props) {
  const { date, amount, id, purpose } = props.editDetails;
  console.log('props', props.editDetails);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({});
  console.log('Inputs=>', inputs);
  const [treasurer, setTreasurer] = useState([]);
  console.log('treasurer: ', treasurer);

  // const getTreasurer = () => {
  //   axios
  //     .get(`${selecttreasurer}/${localStorage.getItem('userid')}`)
  //     .then((resp) => {
  //       setTreasurer(resp?.data);
  //     });
  // };
  // useEffect(() => {
  //   getTreasurer();
  // }, []);
  const user = useSelector((state) => state.login.user);

  const handleClick = (e) => {
    // e.preventDefault()
    axios
      .post(`${UpdateExpense}`, {
        id: id,
        date: formatDate(date),
        amount: amount,
        treasurer: user.id,
        purpose: purpose,
      })
      .then((res) => {
        console.log('res', {treasurer: user.id});
        // setAddexpen()
        if (res.data.code == 200) {
          swal({text : "Added expense" , icon:"success"})
          props.getExpenses();
          props.setIsEdit(false);
        }else{
          swal({text : res.data.message , icon:"success"})

        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <Modal>
        <First>
          <p>Update Expense </p>
          <BsX
            size={25}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.setIsEdit(false);
            }}
          />
        </First>
        <hr />
        <Second>
          <Fst>
            <Lft>
              <label>Date</label>
              <input
                type='date'
                value={formatDate(date)}
                onChange={(e) => {
                  props.setEditDetails((prev) => ({
                    ...prev,
                    date: e.target.value,
                  }));
                }}
              />
            </Lft>
            <Ryt>
              <label>Amount</label>
              <input
                type='number'
                value={amount}
                onChange={(e) => {
                  props.setEditDetails((prev) => ({
                    ...prev,
                    amount: e.target.value,
                  }));
                }}
              />
            </Ryt>
          </Fst>
          <Scnd>
            <label>Purpose</label>
            <input
              type='text'
              value={purpose}
              onChange={(e) => {
                props.setEditDetails((prev) => ({
                  ...prev,
                  purpose: e.target.value,
                }));
              }}
            />
          </Scnd>
          <button onClick={handleClick}>Update</button>
        </Second>
      </Modal>
    </Container>
  );
}

export default EditExpenses;
