import styled from "styled-components";
import { Colors } from "../../../Const/Colors/Theme";

export const DateDiv = styled.div`
    height: 40px;
    position: relative;
    border:none;
    font-size:16px;
    padding:0 10px;
    outline:none;
    border:1px solid ${Colors.medgrey};
    border-radius: 5px;
    width: 147px;
    >div{
        height: 100%;
        >div{
            height: 100%;
        }
    }
    input{
        border:none;
        height: 100%;
        width:90%;
        font-size:16px;
        padding:0 10px;
        cursor: pointer;
        outline:none;
        background: transparent;
    }
    svg{
        position: absolute;
        height: 20px;
        width: 20px;
        top: 9px;
        right: 10px;
        pointer-events:none;
    }
`

export const Name = styled.input`
    height: 40px;
    position: relative;
    border:1px solid ${Colors.medgrey};
    width:170px;
    outline:none;
    border-radius: 5px;
    padding:0 10px;
    @media (max-width: 360px) {
        width:260px;
    }
`
export const BMIId = styled.input`
    height: 40px;
    position: relative;
    width:140px;
    outline:none;
    border:1px solid ${Colors.medgrey};
    border-radius: 5px;
    padding:0 10px;
    @media (max-width: 360px) {
        width:260px;
    }
`

export const Update = styled.button`
    height: 40px;
    width: 100%;
    margin: 20px 0 8px;
    background-color:${Colors.blue};
    color:${Colors.white};
    border-radius: 4px;
    border:none;
    cursor: pointer;
    display:block;
    @media (max-width: 360px) {
        width:260px;
    }
`

export const Label = styled.label`
     padding: 5px 20px ;
     display:block;
`
export const Select = styled.select`
    border:1px solid ${Colors.medgrey};
    border-radius: 5px;
    padding:11px 13px;
    width:155px;
    background-color:${Colors.white};
    option{
        background-color:${Colors.white};
    }
`
export const TextArea = styled.textarea`
    height:100px;
    width:400px;
    outline:none;
    padding:8px;
    border:1px solid ${Colors.medgrey};
    border-radius: 5px;
    resize: none;
    @media (max-width: 450px) {
        width:100%;
    }
`
export const MarT = styled.div`
    margin-top:20px;
`

export const FlexR = styled.div`
    display:flex;
    align-items:center;
    flex-wrap:wrap;
    gap:1rem;
    margin-bottom:10px;
    label{
        width:fit-content;
    }
    @media (max-width: 450px) {
        flex-direction:column;
        align-items:normal;
        input{
            width:100%
        }
    }
`

export const ModalContent = styled.div`
    min-width:300px;
    padding:20px;
    @media (max-width: 550px) {
        width:82%;
    }
    background-color:${Colors.white};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    h4{
        
        font-weight: 400;
    }
    hr{
        border-top: none;
        margin-bottom:8px;
    }
`