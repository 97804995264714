import axios from 'axios';
import React, { useState } from 'react';
import { BsX } from 'react-icons/bs';
import { UpdateCompanyInvestment } from '../../../Const/Api/Api';
import { Container, Modal, First, Second, Divs } from '../../SuperAdmin/Modal/AssignFundStyled';
import swal from 'sweetalert';

function EditInvestmentAmount({ setShowEditModel, getFundApprovalList, data = {} }) {
    const [amount, setAmount] = useState(data?.invested_amount)
    let a = data;

    const SumbitHandler = async () => {
        const data = {
            company_id: a?.id,
            amount: amount
        };

        await axios({
            method: 'POST',
            url: UpdateCompanyInvestment,
            data,
        })
            .then((response) => {
                if (response?.data?.code == 200) {

                    setShowEditModel(false)
                    getFundApprovalList()
                    swal({ text: response?.data?.message, icon: 'success' });
                } else {
                    swal({ text: response?.data?.message, icon: 'warning' });
                }
            })
            .catch((err) => {
                swal({ text: 'Error', icon: 'error' });
            });
    };

    return (
        <Container>
            <Modal>
                <First>
                    <p>Edit Amount</p>
                    <BsX size={25} onClick={() => setShowEditModel(false)} />
                </First>
                <hr />
                <Second>
                    <Divs>
                        <label style={{ paddingBottom: '10px' }}>Edit investment amount</label>
                    </Divs>
                    <Divs>
                        <input
                            type='text'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </Divs>
                    <button onClick={SumbitHandler}>submit</button>
                </Second>
            </Modal>
        </Container>
    );
}

export default EditInvestmentAmount;
