import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../../MainFundCollector/MainFundCollector.styled'
import { ButtonDiv, FieldDiv, InputField, Modal, ModalContainer, ModalContent, ModalHeader } from './CompanyDetailsStyles'
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios'
import { InsertCompany } from '../../../../Const/Api/Api'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'

const CompanyModal = (props) => {
    // const { treasurer, getTreasurer } = User()
    // console.log('treasurer: ', treasurer[0]);
    // const id = treasurer[0]?.id
    const user = useSelector((state) => state.login.user);
    const [values, setValues] = useState({
        company_id: props?.data?.length + 1,
        name: '',
        location: '',
        investment_starting_date: '',
        invested_amount: '',
        treasure_id: user?.id,
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const handleSubmit = () => {
        axios({
            method: 'post',
            url: InsertCompany,
            data: values
        }).then(resp => {
            if(resp?.data?.code == 200){
                swal({text: "Company added", icon: "success"})
                props?.getComDetails()
                props.setCompanyModal(false)
            }else{
                swal({text: resp?.data?.message, icon: "warning"})
            }
        })
    }
    // useEffect(() => {
    //     getTreasurer()
    // }, [])
    return (
        <Modal onClick={() => props.setCompanyModal(false)}>
            <ModalContainer style={{width: '35%'}} onClick={(e) => { e.stopPropagation() }}>
                <ModalHeader>
                    <h1>Add Company</h1>
                    <span onClick={() => props.setCompanyModal(false)} ><AiOutlineClose size={20} /></span>
                </ModalHeader>
                <ModalContent>
                    <FieldDiv>
                        <p>Company ID</p>
                        <InputField type="text" name="company_id" value={values.company_id}  />
                    </FieldDiv>
                    <FieldDiv>
                        <p>Company Name</p>
                        <InputField type="text" name="name" value={values.name} onChange={(e) => handleChange(e)} />
                    </FieldDiv>
                    <FieldDiv>
                        <p>Location</p>
                        <InputField type="text" name="location" onChange={(e) => handleChange(e)} value={values.location} />
                    </FieldDiv>
                    <FieldDiv>
                        <p>Investment Starting Date</p>
                        <InputField type="Date" name="investment_starting_date" onChange={(e) => handleChange(e)} value={values.investment_starting_date} />
                    </FieldDiv>
                    <FieldDiv>
                        <p>Total Investment Amount</p>
                        <InputField type="text" name="invested_amount" onChange={(e) => handleChange(e)} value={values.invested_amount} />
                    </FieldDiv>
                    <ButtonDiv>
                        <button onClick={() => { handleSubmit() }}>Add</button>
                    </ButtonDiv>
                </ModalContent>

            </ModalContainer>
        </Modal>
    )
}

export default CompanyModal