import { createPortal } from "react-dom";
import AddSip from "../AssignSip/AddSip";
import AddEib from "./AddEib";
import ConfirmMod from "./ConfirmDeactivate";
import CreateBmiId from "./CreateBmiId";
import EditModal from "./EditModal";
import LoadingModal from "./LoadingModal";
import SuccessModal from "./SuccessMod";

const AddEidModal = ({ setShowModal, getEibKunooz }) => {
    const container = document.getElementById("modal");
    return createPortal(<AddEib setShowModal={setShowModal} getEibKunooz={getEibKunooz} />, container);
}
const AddSipModal = ({ setShowModal, bmi, getMonthlySip}) => {
    const container = document.getElementById("modal");
    return createPortal(<AddSip setShowModal={setShowModal} bmi = {bmi} getMonthlySip={getMonthlySip}/>, container);
}
const ConfirmModal = ({ setShowModal, bmi_id }) => {
    const container = document.getElementById("modal");
    return createPortal(<ConfirmMod setShowModal={setShowModal} bmi_id={bmi_id} />, container);
}
const SuccessModals = ({ setShowModal, text }) => {
    const container = document.getElementById("modal");
    return createPortal(<SuccessModal setShowModal={setShowModal} text={text} />, container);
}
const CreateBMID = ({ setShowModal, getBmiId, len }) => {
    const container = document.getElementById("modal");
    return createPortal(<CreateBmiId setShowModal={setShowModal} getBmiId={getBmiId} len={len} />, container);
}
const EditDetails = ({ setShowModal ,editData, company, getCompData }) => {
    const container = document.getElementById("modal");
    return createPortal(<EditModal editData = {editData} company = {company}  setShowModal={setShowModal} getCompData={getCompData} />, container);
}
const LoadingMod = ({ setShowModal, mail }) => {
    const container = document.getElementById("modal");
    return createPortal(<LoadingModal setShowModal={setShowModal} mail={mail} />, container);
}

export { AddEidModal, AddSipModal, ConfirmModal, SuccessModals, CreateBMID, EditDetails, LoadingMod, };