import styled from "styled-components";
import { Colors, size } from "../../../../Const/Colors/Theme";

export const FundCHead = styled.div`
    display: flex;
    /* width: 100%; */
    /* flex-wrap: wrap; */
    padding: 2rem 50px;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  @media screen and (max-width: ${size.Tablet}) {
    width: auto;
    gap: 1rem;
  }
  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
`
export const FundCDropDown = styled.div`
     position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${Colors.border};
  padding: 10px 10px;
  width: ${(props) => props.width};
  border-radius: 5px;
  align-items: center;
  span {
    font-size: 12px;
  }
`
export const FundCollector = styled.div`
    /* width: 100%; */
    height: 0;
    /* position: absolute;
    left: 18px  ;
    top: 18%;
  @media screen and (max-width: ${size.MobileL}){
    top: 0;
    left: 12px;
  } */
`
export const TableDivFundC = styled.div`
     width: ${(props) => props.width};
  border: 1px solid ${Colors.border};
  border-collapse: collapse;
  border-radius: 5px;
  th {
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid ${Colors.border};
    border-top: 1px solid ${Colors.border};
  }
  td {
    text-align: center;
    font-size: 15px;
    font-weight: 400px;
    padding: 20px;
  }
  @media screen and (max-width: ${size.Tablet}) {
    width: 100%;
  }
`
export const DropDownDrawerF = styled.div`
  margin: 0 5px;
  padding: 5px;
  width: auto;
  border-bottom: 1px solid ${Colors.border};
  :nth-child(6) {
    border-bottom: none;
  }
`
export const ContainerFund = styled.div`
  width: 100%;
  /* @media screen and (max-width: ${size.Laptop}) {
    margin-left: 0;
  } */
`
export const TableContainerF = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  margin-top: 5rem;
  /* overflow-x: auto; */
  @media screen and (max-width: ${size.Tablet}) {
    overflow-x: auto;
    padding-top: 1rem;

  }
  @media screen and (max-width: ${size.MobileL}){
    margin-top: 8rem;
  }
`;
export const DownloadDiv1 = styled.div`
 
`

export const FundHeader = styled.div`
  display: flex;
    gap: 15px;
    @media screen and (max-width: 470px){
    flex-direction: column;
  }
`
export const LoadingContainer = styled.div`
display: flex;
width: 100%;
height: 65vh;
justify-content: center;
align-items: center;
`