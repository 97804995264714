import React, { useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../../Const/assets';
import axios from 'axios';
import { investmentreturnIn } from '../../../../../Const/Api/Api';
import { useEffect } from 'react';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../../../NumberFormatter';

function InvestmentReturn() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmnt, setTotalAmnt] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('')
  const getData = () => {
    axios.get(investmentreturnIn).then((res) => {
      setBankAmount(res?.data?.amount)
      const data1 = [];
      let total = 0;
      res?.data?.details.map((item, index) => {
        data1.push({
          serialNo: index + 1,
          transfer_date: displayDate(item?.transferToBank_date),
          company_name: item?.name,
          fund_collector_id: item?.fund_collector_id,
          collected_from: item?.collected_from,
          invested_amount: item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0',
          remarks: item?.remarks,
        });
        total = total + parseInt(item?.amount);
      });
      setLoading(true);
      setData(data1);
      setTotalAmnt(total);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Serial No.',
        accessor: 'serialNo',
      },
      {
        Header: 'Transfer Date',
        accessor: 'transfer_date',
      },
      {
        Header: 'Company Name',
        accessor: 'company_name',
      },
      {
        Header: 'Fund collector ID',
        accessor: 'fund_collector_id',
      },
      {
        Header: 'Collected From',
        accessor: 'collected_from',
      },
      {
        Header: 'Remark',
        accessor: 'remarks',
      },
      {
        Header: 'Amount (AED)',
        accessor: 'invested_amount',
      },
    ],
    []
  );

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.transfer_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Investment Return</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <p>Amount Available in bank</p>

            {/* <input
              type='text'
              placeholder='Enter Amount'
              value={`AED ${bankAmount ? bankAmount?.toFixed(2) : ''}`}
              style={{ width: 'fit-content', pointerEvents: 'none' }}
            ></input> */}
            <div style={{
              width: 'fit-content', outline: 'none', pointerEvents: 'none', border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '7px', borderRadius: '5px', fontSize: '14px'
            }}
            >{'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}</div>
          </TableTopItems>
          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBar>

        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default InvestmentReturn;
