import React from 'react';
import {
  CheckTable,
  CheckTableDiv,
  FieldDiv,
  InputField,
  Modal,
  ModalContainer,
  ModalContainerR,
  ModalContent,
  ModalHeader,
} from './CompanyDetailsStyles';
import { AiOutlineClose } from 'react-icons/ai';
import { displayDate } from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import { numberFormatter } from '../../../../NumberFormatter';

const ProfileReportModal = (props) => {
  return (
    <Modal onClick={() => props.setReport(false)}>
      <ModalContainerR
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader>
          <h1>Profit Report</h1>
          <span onClick={() => props.setReport(false)}>
            <AiOutlineClose size={20} />
          </span>
        </ModalHeader>
        <ModalContent>
          <CheckTableDiv>
            <CheckTable>
              <tr>
                <th>Serial No.</th>
                <th>Date</th>
                <th>Month</th>
                <th>Year</th>
                <th>Profit (AED)</th>
              </tr>
              {props?.profitList?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{displayDate(item?.date)}</td>
                  <td>{item?.month}</td>
                  <td>{item?.year}</td>
                  <td>{numberFormatter(Math.round(item?.amount * 100) / 100)}</td>
                </tr>
              ))}
            </CheckTable>
          </CheckTableDiv>
        </ModalContent>
      </ModalContainerR>
    </Modal>
  );
};

export default ProfileReportModal;
