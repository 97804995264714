import styled from "styled-components";
import { Colors, fonts } from "../../../../Const/Colors/Theme";



export const Main = styled.div`
    padding:30px;
    padding-top:50px;
    @media(max-width:767px){
        padding-top:150px;
    }
`

export const Title = styled.div`
    padding:15px 0 9px 1rem;
    font-weight:${fonts.fontweight3};
    background-color:${Colors.medgrey};
    margin-bottom:40px;
`

export const GridFull = styled.div`
    display:grid;
    gap: 10px;
    grid-template-columns: 33% 33% 36%;
    @media(max-width:1600px){
        grid-template-columns: 40% 60%;
    }
    @media  (max-width:1200px) and (min-width:1024px){
        grid-template-columns: 100%;
    }
    @media(max-width:1024px){
        grid-template-columns: 50% 50%;
    }
    @media(max-width:830px){
        grid-template-columns: 100%;
    }
`

export const Row = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:30px;
    height:fit-content;
    width:fit-content;

`

export const Row1 = styled.div`
    display:flex;
    align-items:baseline;
    margin-bottom:30px;
`

export const Value = styled.div`
    flex-wrap: wrap;
    padding-left:20px;
    text-transform:capitalize;
    a{
        display:flex;
        background-color:${Colors.medgrey};
        justify-content: center;
        align-items: center;
        padding: 5px;
        margin-top:10px;
    }
    p{
        word-break: break-all;
    }
`

export const Key = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:${props => props.width};
    @media(max-width:830px){
        width:250px;
    }
    @media(max-width:540px){
        width:160px;
    }
    span{
        font-weight:${fonts.fontweight3};
        text-transform:capitalize;
        padding-right: 10px;
    }

`
export const Key1 = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:200px;
    span{
        font-weight:${fonts.fontweight3};
        text-transform:capitalize;
        padding-right: 10px;
    }

`

export const DocsLink = styled.div`
    display:flex;
    align-items:baseline;    
    gap:1rem;
    margin-bottom:20px;
    flex-wrap: wrap;
`
export const Flex = styled.div`
    display:flex;
    align-items:baseline;   
    gap: 10px; 
    margin-bottom:7px;
    svg{
        cursor: pointer;
    }
`
export const Span1 = styled.span`
        color:${Colors.lightgrey};
`
export const A = styled.a`
    display:flex;
    background-color:${Colors.medgrey};
    color:${Colors.blue};
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top:10px;
    width: auto;

`

export const Flex2 = styled.div`
    display: flex;
    gap: 5px;
    width:215px;
`

export const GridTwo = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap:1rem;
    @media(max-width:740px){
        grid-template-columns:1fr;
    }
`
export const GridTwoM = styled.div`
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap:80px;
    @media(max-width:1500px){
        grid-template-columns: 1fr;
    }
`
export const Table = styled.table`
    table-layout: fixed;
    width:100%;
    border-spacing: 0;
    border:1px solid ${Colors.medgrey}; 
    @media(max-width:830px){
        width: 800px;
    }
    th{
        border-bottom:1px solid ${Colors.medgrey}; 
        border-right:1px solid ${Colors.medgrey}; 
        font-weight:${fonts.fontweight3};
        height:60px;
    }
    th:last-child{
        border-right:none;
    }
    td{
        border-right:1px solid ${Colors.medgrey}; 
    }
    td:last-child{
        border-right:none;
    }
    tbody{
        tr{
            height:50px;
            td{
                align-items:center;
                text-align:center;
            }
        }
    }
`

export const Overflow = styled.div`
    @media(max-width:830px){
        overflow-x:scroll;
    }
`

export const Deactivate = styled.div`
    width: 183px;
    height: 55px;
    background-color: ${Colors.lightred};
    border-radius:4px;
    color: ${Colors.white};
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
`