import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from '../../../Components/Table/AdminTable';
import { getDeactiveMembersList } from '../../../Const/Api/Api';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
  Container,
} from '../../MainFundCollector/MainFundCollector.styled';
import {
  A,
  Overflow,
  Table,
} from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { Buttons } from '../../Treasurer/InactiveMembers/InactiveMembersStyle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import { TableTopBar, TableTopItems } from '../ActiveMem/ActiveMemberStyled';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../NumberFormatter';

const DeactiveMember = () => {
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const getDeactvMem = async () => {
    const data1 = [];
    const resp = await axios.get(getDeactiveMembersList);
    resp?.data?.map((item) => {
      data1.push({
        availableToInvest: item?.availableToInvest,
        availableToWithdraw: numberFormatter(Math.round(item?.availableToWithdraw * 100) / 100),
        bmi_id: item?.bmi_id,
        full_name: item?.full_name,
        id: item?.id,
        status:
          item?.status == 3
            ? 'Settled'
            : item?.status == 2
              ? 'Approved'
              : 'Pending',
        joining_date: displayDate(item?.joining_date),
        inactive_date: displayDate(item?.inactive_date),
        total_amount: numberFormatter(Math.round(item?.total_amount * 100) / 100),
        col: (
          <A
            onClick={() => {
              navigation('view', { state: { id: item?.id } });
            }}
          >
            View Details
          </A>
        ),
      });
      setData(data1);
    });
  };
  useEffect(() => {
    getDeactvMem();
  }, []);

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.inactive_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'BMIP ID',
        accessor: 'bmi_id',
      },
      {
        Header: 'Member Name',
        accessor: 'full_name',
      },
      {
        Header: 'Starting Date',
        accessor: 'joining_date',
      },
      {
        Header: 'Inactive Date',
        accessor: 'inactive_date',
      },
      {
        Header: 'Total Amount',
        accessor: 'total_amount',
      },

      {
        Header: 'Fund Available to withdrawal',
        accessor: 'availableToWithdraw',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        accessor: 'col',
        // Cell: cellInfo => {
        //   return (
        //     <A onClick={() => {
        //       navigation('/deactivate-members/view', { state: { userId: cellInfo.value } });

        //     }}>View Details</A>
        //   )
        // }
      },
    ],
    []
  );
  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Inactive Members</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
        <TableTopBar>
          <TableTopItems>

          </TableTopItems>
          <label style={{ float: "right", color: Colors.blue, cursor: "pointer" }} onClick={() => exportToExcel()} >
            Download
          </label>
        </TableTopBar>
          <AdminTable
            columns={columns}
            data={dateFilt ? dateFilteredData : data}
          />
        </Table>
      </Overflow>
    </Container>
  );
};

export default DeactiveMember;
