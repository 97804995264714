import styled from "styled-components";
import { Colors } from "../../../../Const/Colors/Theme";


export const A = styled.span`
    text-decoration:underline;
    color:${Colors.blue};
    cursor: pointer;
`
export const JusEnd = styled.div`
    display:flex;
    @media (max-width:768px) {
        /* width:180%; */
    }
    justify-content:end;
`
export const Table = styled.div`
    padding:30px;
    display: flex;
        /* width: 100%; */
        overflow: auto;
        /* margin: 30px; */
    flex-direction: column;
    .search-div{
        padding-right:0;
        input{
            width:100%;
        }
    }
    table{
        padding:1rem;
    }
    @media(max-width:768px){
        /* overflow-x: scroll; */
        margin: 15px;
        padding:0;
    }
`
export const Overflow = styled.div`
/* position: absolute; */

`