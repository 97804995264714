import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import swal from 'sweetalert';
import { server_api } from '../../../../Api/Api';
import {
  approveUser,
  getUserDetails,
} from '../../../../Const/Api/Api';
import { Download } from '../../../../dashboards/BMITreasurerStyle';
import {
  Container,
  MenuBar,
  MenuRightDiv,
  MenuText,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { ConfirmModal } from '../../Modals/Portals';

import BankDetails from './BankDetails';
import Beneficiary from './Beneficiary';
import SingleRow from './SingleRow';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  A,
  Deactivate,
  DocsLink,
  Flex,
  Flex2,
  GridFull,
  GridTwo,
  GridTwoM,
  Main,
  Overflow,
  Span1,
  Table,
  Title,
} from './ViewMembersStyle';
import { FlexR } from './BeneficiaryStyle';

const ViewMembers = () => {
  const { state } = useLocation();

  const location = useLocation();
  const pathName = location.pathname;
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [proof, setProof] = useState([]);
  const [beneficiary, setBeneficiary] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const bmi_id = data?.bmi_id;

  const userDetails = async () => {
    const resp = await axios.get(`${getUserDetails}/${state?.id}`);
    setData(resp?.data);
    setProof(resp?.data?.proof_details[0]);
    setBeneficiary(resp?.data?.user_beneficiary[0]);
    setCompanyDetails(resp?.data?.company_details);
  };

  useEffect(() => {
    userDetails();
  }, []);

  const [verify, setVerify] = useState();

  // const verifyUser = async () => {
  //   const resp = await axios.post(`${approveUser}/${state?.id}`);
  //   console.log(resp, 'respomseeee');
  //   if (resp?.data?.code == 200) {
  //     setVerify(resp?.data?.code);
  //     swal({ text: 'User', icon: 'success' });
  //   } else {
  //     swal({
  //       text: 'Something went wrong',
  //       icon: 'error',
  //     });
  //   }
  // };

  const verifyUser = () => {
    axios.get(`${approveUser}/${state?.id}`).then((resp) => {
      if (resp?.data?.code == 200) {
        setVerify(resp?.data?.code);
        swal({ text: resp?.data?.message, icon: 'success' });
        navigation('/super-admin/pending-request');
      } else {
        swal({
          text: resp?.data?.message,
          icon: 'warning',
        });
      }
    });
  };

  const exportPage = () => {
    html2canvas(document.querySelector('#capture')).then((canvas) => {
      // document.body.appendChild(canvas);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', [450, 800]);
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('download.pdf');
    });
  };

  return (
    <Container>
      <div>
        {showModal && (
          <ConfirmModal setShowModal={setShowModal} bmi_id={bmi_id} />
        )}
        <MenuBar secondary>
          <Flex>
            <BsArrowLeft onClick={() => navigation(-1)} />
            <MenuText>
              {pathName.includes('/active-members') ||
                pathName.includes('/activemembers/view')
                ? 'Active Members'
                : 'View Details'}
            </MenuText>
          </Flex>
          <MenuRightDiv>
            {pathName.includes('/activemembers/view') && (
              <Deactivate onClick={() => setShowModal(true)}>
                Deactivate
              </Deactivate>
            )}
            {pathName.includes('/pending-request/view') &&
              (verify == 200 ? (
                <Deactivate style={{ background: 'green', cursor: 'no-drop' }}>
                  Approved
                </Deactivate>
              ) : (
                <Deactivate
                  style={{ background: 'green' }}
                  onClick={() => verifyUser()}
                >
                  Approve Member
                </Deactivate>
              ))}
            <Download onClick={() => exportPage()}>Download</Download>
          </MenuRightDiv>
        </MenuBar>
      </div>
      <Main id='capture'>
        <Title>Personal Details</Title>
        <GridFull>
          <div>
            <SingleRow title='full name' value={data?.full_name == "undefined" ? 'No data available' : data?.full_name} size='160px' />
            <SingleRow title='Mobile no' value={data?.contact == "undefined" ? 'No data available' : data?.contact} size='160px' />
            <SingleRow title='Date of Birth' value={data?.dob == "undefined" ? 'No data available' : data?.dob} size='160px' />
            <SingleRow
              title='Passport number'
              value={data?.passport_no == "undefined" ? 'No data available' : data?.passport_no}
              size='160px'
              type='download'
              url={`${server_api}/${data?.passport_upload}`}
              docName='Passport.jpg'
            />
            <FlexR style={{ marginTop: "0px", marginBottom: "20px" }}>
              <Span1 style={{ display: 'block' }}>
                Passport Expiry Date &nbsp;:
              </Span1>
              <span>{data?.passport_expiry == "undefined" ? 'No data available' : data?.passport_expiry}</span>
            </FlexR>
          </div>
          <div>
            <SingleRow
              title='BMIP Membership No'
              value={data?.bmipno == "undefined" ? 'No data available' : data?.bmipno}
              size='190px'
            />
            <SingleRow
              title='Mobile No (Opitonal)'
              value={data?.contact2 == "undefined" || data?.contact2 == 0 || String(data?.contact2).trim() == '' || data?.contact2 == null ? '-' : data?.contact2}
              size='190px'
            />
            <SingleRow title='Email ID' value={data?.email == "undefined" ? 'No data available' : data?.email} size='190px' />
            <SingleRow
              title='Country of Residence'
              value={data?.countryOfResidence == "undefined" ? 'No data available' : data?.countryOfResidence}
              size='190px'
            />
          </div>

          <div>
            <SingleRow
              title='BMIP Joining Date'
              value={data?.joining_date == "undefined" ? 'No data available' : data?.joining_date}
              size='170px'
            />
            <div>
              <b>ID</b>
            </div>
            <DocsLink>
              {data?.proof_details?.map((item) => {
                return (
                  <div>
                    <Flex>
                      <Flex2 style={{ width: 'unset' }}>
                        {item?.id}. <Span1>{item?.type} ID :</Span1>
                        {item?.proof_no}
                      </Flex2>
                      <div>
                        <A
                          href={`${server_api}/${item?.image}`}
                          download
                          target='_blank'
                        >
                          {item?.type}.jpg
                        </A>
                      </div>
                    </Flex>
                    {item?.id == 2 || item?.id == 3 ?
                      <SingleRow
                        title='Date of Expiry '
                        value={item?.expiry_date == "undefined" ? 'No data available' : item?.expiry_date}
                        size='205px'
                      />
                      : ''}

                  </div>
                );
              })}
            </DocsLink>

          </div>
        </GridFull>
        <GridTwoM>
          { data?.countryOfResidence != "INDIA" &&
            <div>
              <Title>Residence Address</Title>
              <GridTwo>
                <SingleRow title='City' value={data?.resi_city == "undefined" ? 'No data available' : data?.resi_city} size='210px' />
                <SingleRow title='Street Name/ Area' value={data?.street == "undefined" ? 'No data available' : data?.street} size='210px' />
                <SingleRow
                  title='Nearest Landmark '
                  value={data?.resi_landmark == "undefined" ? 'No data available' : data?.resi_landmark}
                  size='210px'
                />
                <SingleRow title='P.O Box ' value={data?.po_box == "undefined" ? 'No data available' : data?.po_box} size='210px' />
                <SingleRow
                  title='Apartment/Building /Villa'
                  value={data?.resi_house_name == "undefined" ? 'No data available' : data?.resi_house_name}
                  size='210px'
                />
              </GridTwo>
            </div>
          }
          <div>
            <Title>Home Country Address</Title>
            <GridTwo>
              <SingleRow
                title='House Name'
                value={data?.resi_house_name == "undefined" ? 'No data available' : data?.resi_house_name}
                size='210px'
              />
              <SingleRow
                title='Nearest Landmark '
                value={data?.resi_landmark == "undefined" ? 'No data available' : data?.resi_landmark}
                size='210px'
              />
              <SingleRow title='Post ' value={data?.post} size='210px' />
              <SingleRow
                title='Contact Number(India)'
                value={data?.homeAddressContact == "undefined" ? 'No data available' : data?.homeAddressContact}
                size='210px'
              />
              <SingleRow title='District' value={data?.district == "undefined" ? 'No data available' : data?.district} size='210px' />
              <SingleRow title='PIN' value={data?.pincode == "undefined" ? 'No data available' : data?.pincode} size='210px' />
              <SingleRow title='State' value={data?.state == "undefined" ? 'No data available' : data?.state} size='210px' />
            </GridTwo>
          </div>

          {
            data?.india && data?.india?.length > 0 &&
            <div>
              <BankDetails
                location='India'
                bankName={data?.india?.bank_name == "undefined" ? 'No data available' : data?.india?.bank_name}
                acHolderName={data?.india?.acc_name == "undefined" ? 'No data available' : data?.india?.acc_name}
                // swift={data?.india?.swift}
                acNumber={data?.india?.acc_no == "undefined" ? 'No data available' : data?.india?.acc_no}
                currency={data?.india?.currency == "undefined" ? 'No data available' : data?.india?.currency}
                ifsc={data?.india?.ifsc_code == "undefined" ? 'No data available' : data?.india?.ifsc_code}
                branch={data?.india?.branch == "undefined" ? 'No data available' : data?.india?.branch}
              />
            </div>
          }
          {data?.abroad == 0 ? (
            <div>
              {/* <BankDetails
                location='India'
                bankName={data?.bank_name}
                acHolderName={data?.acc_name}
                swift={data?.swift}
                acNumber={data?.acc_no}
                currency={data?.currency}
                ifsc={data?.ifsc_code}
                branch={data?.branch}
              /> */}
            </div>
          ) : (
            <div>
              <BankDetails
                location='Abroad'
                bankName={data?.bank_name == "undefined" ? 'No data available' : data?.bank_name}
                acHolderName={data?.acc_name == "undefined" ? 'No data available' : data?.acc_name}
                swift={data?.swift == "undefined" || data?.swift == "null" ? 'No data available' : data?.swift}
                acNumber={data?.acc_no == "undefined" ? 'No data available' : data?.acc_no}
                ifsc={data?.ifsc_code == "undefined" ? 'No data available' : data?.ifsc_code}
                currency={data?.currency == "undefined" ? 'No data available' : data?.currency}
              />
            </div>
          )}
        </GridTwoM>

        {data?.user_beneficiary?.map((item) => {;
          return (
            <Beneficiary
              relation={item?.relation == "undefined" ? 'No data available' : item?.relation}
              address={item?.address == "undefined" ? 'No data available' : item?.address}
              number={item?.contact == "undefined" ? 'No data available' : item?.contact}
              share={item?.beneficiary_share == "undefined"  ? 'No data available' : item?.beneficiary_share}
              dob={item?.dob == "undefird" ? 'No data available' : item?.dob}
              name={item?.name == "undefined" ? 'No data available' : item?.name}
              bankAcDetails=''
              branch={item?.branch == "undefined" ? 'No data available' : item?.branch} 
              bankName={item?.bank_name == "undefined" ? 'No data available' : item?.bank_name}         
              ifsc={item?.ifsc == "undefined" ? 'No data available' : item?.ifsc}
              docUrl={`${server_api}/${item?.passport_upload}`}
              docName={item?.document == "undefined" ? 'No data available' : item?.document}
              passDetails={item?.passport_no == "undefined" ? 'No data available' : item?.passport_no}
              acc_no={item?.acc_no == "undefined" ? 'No data available' : item?.acc_no}
              national_id_proof_type={item?.national_id_proof_type == "undefined" ? 'No data available' : item?.national_id_proof_type}
              document={item?.document == "undefined" ? 'No data available' : item?.document}
              passport_expiry={item?.passport_expiry == "undefined" ? 'No data available' : item?.passport_expiry}
            />
          );
        })}
        
        {location.pathname == '/super-admin/pending-request/view' ? null : (
          <div>
            <Title>Company details</Title>
            <Overflow>
              <Table>
                <thead>
                  <tr>
                    <th>Invested Companies</th>
                    <th>Invested Date</th>
                    <th>Invested Amount</th>
                    <th>Profit</th>
                    <th>Return</th>
                  </tr>
                </thead>
                {companyDetails &&
                  companyDetails?.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>{item?.company_name}</td>
                          <td>{item?.invested_date}</td>
                          <td>{item?.invested_amount.toFixed(3)}</td>
                          <td>{item?.profit.toFixed(3)}</td>
                          <td>{item?.investment_return.toFixed(3)}</td>
                        </tr>
                      </tbody>
                    );
                  })}
              </Table>
            </Overflow>
          </div>
        )}
      </Main>
    </Container>
  );
};

export default ViewMembers;
