import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import AdminTable from '../../../../Components/Table/AdminTable';
import { getExpenseSummary } from '../../../../Const/Api/Api';
import {
  Button,
  Container,
  ContentDiv,
  DateWrapper,
  FormContainer,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
} from '../../../MainFundCollector/MainFundCollector.styled';
import { LoadingContainer } from '../FundCollectorActivity/FundCollectorActivityStyles';
import { DowloadDiv } from './ExpenseSummaryStyles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../Const/Colors/Theme';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../NumberFormatter';

const ExpenseSummary = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const columns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id', // accessor is the "key" in the data
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Fund Collector ID',
      accessor: 'fundcollector',
    },
    {
      Header: 'Total Expense Collected (AED)',
      accessor: 'totalExpenseCollected',
    },
    {
      Header: 'Total Expense Debited (AED)',
      accessor: 'totalExpenseDebited',
    },
    {
      Header: 'Purpose',
      accessor: 'col6',
    },
    {
      Header: 'Balance (AED)',
      accessor: 'balance',
    },
  ];

  const getSummary = () => {
    // if(fromDate == '' && toDate == ''){
    //   alert("Enter a valid date!")
    // }
    axios
      .get(`${getExpenseSummary}?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        setLoading(true);

        const data1 = [];
        res.data?.map((item) => {
          data1.push({
            bmi_id: item?.bmi_id,
            name: item?.name,
            status: item?.status,
            col6: item?.purpose,
            fundcollector: item?.fundcollector
              ? (item?.fundcollector).split(' ')[0]
              : '',
            totalExpenseCollected: item?.totalExpenseCollected ? numberFormatter(Math.round(Number(item?.totalExpenseCollected) * 100) / 100) : '0',
            totalExpenseDebited:
            item?.totalExpenseDebited ? numberFormatter(Math.round(Number(item?.totalExpenseDebited) * 100) / 100) : '0',
            balance: item?.balance ? numberFormatter(Math.round(Number(item?.balance) * 100) / 100) : '0',
          });
          setData(data1);
        });
      });
  };
  useEffect(() => {
    getSummary();
  }, []);

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Expense Summary</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setFromDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type='Date' onChange={(e) => setToDate(e.target.value)} />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={getSummary}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <DowloadDiv>
        <label
          style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
          onClick={() => exportToExcel()}
        >
          Download
        </label>
      </DowloadDiv>
      <ContentDiv>
        {loading ? (
          <TableContainer>
            <AdminTable columns={columns} data={data} />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv>
    </Container>
  );
};

export default ExpenseSummary;
