import React, { useState } from 'react';
import {
  Button,
  Container,
  Item,
  LinkBtn,
  NavOption,
  Sidenav,
  Buttons,
  RequestsDiv,
  CountDiv,
} from './SideNavbarStyled';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  getTransferCompanyTransactionDetails,
  getTransferUserTransactionDetails,
} from '../../Const/Api/Api';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function SideNavbar() {
  const [isFundCollector, setIsFundCollector] = useState(false);
  const location = useLocation();
  console.log('location: ', location.pathname);
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [comData, setComData] = useState([]);
  // const pending = data.length + comData.length;
  const { approvalReq } = useSelector((state) => state.notification);
  const { menuOpen } = useSelector((state) => state.others);
  console.log(approvalReq, 'redux');
  const fundCollector = () => {
    setIsFundCollector((isFundCollector) => !isFundCollector);
  };

  return (
    <Container menuOpen={menuOpen}>
      <Sidenav>
        <NavOption>
          <LinkBtn
            click={
              location.pathname === '/bmi-treasurer/active-members' ||
              location.pathname === '/bmi-treasurer/inactive-members' ||
              location.pathname === '/fund-transfer-details' ||
              location.pathname === '/bmi-treasurer/assign-sip' ||
              location.pathname === '/bmi-treasurer/eib-kunooz' ||
              location.pathname === '/bmi-treasurer/others' ||
              location.pathname == '/bmi-treasurer/active-members/view' ||
              location.pathname == '/bmi-treasurer/inactive-members/view'
                ? true
                : false
            }
            onClick={fundCollector}
          >
            <AiOutlinePlusSquare />
            User
          </LinkBtn>

          <Item>
            <Button
              click={
                location.pathname == '/bmi-treasurer/active-members' ||
                location.pathname == '/bmi-treasurer/active-members/view'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('active-members');
              }}
            >
              <span>-</span> Active Members
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/inactive-members' ||
                location.pathname == '/bmi-treasurer/inactive-members/view'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('inactive-members');
              }}
            >
              <span>-</span> Inactive Members
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/eib-kunooz' ? true : false
              }
              onClick={() => {
                navigation('eib-kunooz');
              }}
            >
              <span>-</span> EIB Kunooz
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/assign-sip' ? true : false
              }
              onClick={() => {
                navigation('assign-sip');
              }}
            >
              <span>-</span> Assign Monthly SIP
            </Button>
          </Item>
          <LinkBtn
            click={
              location.pathname === '/bmi-treasurer/fund-collector-activity' ||
              location.pathname === '/bmi-treasurer/pending-advance' ||
              location.pathname === '/bmi-treasurer/investment-summary' ||
              location.pathname === '/bmi-treasurer/account-summary' ||
              location.pathname === '/bmi-treasurer/profit-summary' ||
              location.pathname === '/bmi-treasurer/expense-summary'
                ? true
                : false
            }
            onClick={fundCollector}
          >
            <AiOutlinePlusSquare />
            Report
          </LinkBtn>

          <Item>
            <Button
              click={
                location.pathname == '/bmi-treasurer/fund-collector-activity'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('fund-collector-activity');
              }}
            >
              <span>-</span> Fund collector Activity
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/pending-advance'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('pending-advance');
              }}
            >
              <span>-</span> Pending/Advance
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/investment-summary'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('investment-summary');
              }}
            >
              <span>-</span> Investment Summary
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/account-summary'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('account-summary');
              }}
            >
              <span>-</span> Account Summary
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/profit-summary'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('profit-summary');
              }}
            >
              <span>-</span> Profit Summary
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/expense-summary'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('expense-summary');
              }}
            >
              <span>-</span> Expense Summary
            </Button>
          </Item>

          <LinkBtn
            click={
              location.pathname === '/bmi-treasurer/request' ||
              location.pathname === '/bmi-treasurer/monthly-sip' ||
              location.pathname === '/bmi-treasurer/expense' ||
              location.pathname === '/bmi-treasurer/profit' ||
              location.pathname === '/bmi-treasurer/investment-return' ||
              location.pathname === '/bmi-treasurer/expense-out' ||
              location.pathname === '/bmi-treasurer/investment' ||
              location.pathname === '/bmi-treasurer/zakat'
                ? true
                : false
            }
            onClick={fundCollector}
          >
            <AiOutlinePlusSquare />
            Bank details
          </LinkBtn>
          <Item>
            <RequestsDiv>
              <Buttons
                click={
                  location.pathname == '/bmi-treasurer/request' ? true : false
                }
                onClick={() => {
                  navigation('request');
                }}
              >
                <span>-</span>{' '}
                <a style={{ textDecoration: 'underline' }}>Approval request</a>
              </Buttons>
              {approvalReq == 0 ? '' :
              <CountDiv>
                <p>{approvalReq}</p>
              </CountDiv>}
            </RequestsDiv>
            <Buttons
              click={
                location.pathname === '/bmi-treasurer/monthly-sip' ||
                location.pathname === 'bmi-treasurer/monthly-sip' ||
                location.pathname === '/bmi-treasurer/expense' ||
                location.pathname === '/bmi-treasurer/profit' ||
                location.pathname === '/bmi-treasurer/others' ||
                location.pathname === '/bmi-treasurer/investment-return'
                  ? true
                  : false
              }
            >
              <span>-</span> <p>IN</p>
            </Buttons>
            <Button
              click={
                location.pathname == '/bmi-treasurer/monthly-sip' ? true : false
              }
              onClick={() => {
                navigation('monthly-sip');
              }}
            >
              <span>-</span> Monthly SIP
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/expense' ? true : false
              }
              onClick={() => {
                navigation('expense');
              }}
            >
              <span>-</span> Expense
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/profit' ? true : false
              }
              onClick={() => {
                navigation('profit');
              }}
            >
              <span>-</span> Profit
            </Button>

            <Button
              click={
                location.pathname == '/bmi-treasurer/investment-return'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('investment-return');
              }}
            >
              <span>-</span> Investment return
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/others' ? true : false
              }
              onClick={() => {
                navigation('others');
              }}
            >
              <span>-</span> Others
            </Button>
            <Buttons
              click={
                location.pathname === '/bmi-treasurer/expense-out' ||
                location.pathname === '/bmi-treasurer/investment' ||
                location.pathname === '/bmi-treasurer/zakat'
                  ? true
                  : false
              }
            >
              <span>-</span> <p>OUT</p>
            </Buttons>
            <Button
              click={
                location.pathname == '/bmi-treasurer/expense-out' ? true : false
              }
              onClick={() => {
                navigation('expense-out');
              }}
            >
              <span>-</span> Expense
            </Button>
            <Button
              click={
                location.pathname == '/bmi-treasurer/investment' ? true : false
              }
              onClick={() => {
                navigation('investment');
              }}
            >
              <span>-</span> All Investments
            </Button>
            <Button
              click={location.pathname == '/bmi-treasurer/zakat' ? true : false}
              onClick={() => {
                navigation('zakat');
              }}
            >
              <span>-</span> Zakat
            </Button>
          </Item>

          <LinkBtn
            click={
              location.pathname === '/bmi-treasurer/company-details'
                ? true
                : false
            }
            onClick={fundCollector}
          >
            <AiOutlinePlusSquare />
            Company Details
          </LinkBtn>
          <Item>
            <Button
              click={
                location.pathname == '/bmi-treasurer/company-details'
                  ? true
                  : false
              }
              onClick={() => {
                navigation('company-details');
              }}
            >
              <span>-</span> Company Details
            </Button>
          </Item>
        </NavOption>
      </Sidenav>
    </Container>
  );
}

export default SideNavbar;
