import React from 'react'
import { Key, Key1, Row, Row1, Value } from './ViewMembersStyle'

const SingleRow = ({ title, value, type, url, docName, size }) => {
    if (type === 'download') {
        return (
            <Row1>
                <Key width={size}>
                    <span>{title}</span>
                    <div>:</div>
                </Key>
                <Value>
                    {value}
                    {value && <a href={url} target="_blank" download>{docName}</a>}
                </Value>
            </Row1>
        )
    }

    return (
        <Row>
            <Key width={size}>
                <span>{title}</span>
                <div>:</div>
            </Key>
            <Value>
                <p>
                    {value}
                </p>
            </Value>
        </Row>
    )
}

export default SingleRow