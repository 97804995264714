import React, { useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
  Buttons,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../../Const/assets';
import AddZakat from '../../Modal/AddZakat';
import EditZakat from '../../Modal/EditZakat';
import axios from 'axios';
import { addZakat, ListZakat } from '../../../../../Const/Api/Api';
import { useEffect } from 'react';
import { A } from '../../../ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import { TableTopBarZakat } from '../../../CompanyDetails/CompanyDetails/CompanyDetailsStyles';
import { Colors } from '../../../../../Const/Colors/Theme';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../../NumberFormatter';

function Zakat() {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [editDetails, setEditDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nav, setNav] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('');



  const getZakat = () => {
    axios.get(ListZakat).then((res) => {
      setLoading(true);
      const data1 = [];
      setBankAmount(res?.data?.amount);
      res?.data?.details.reverse().map((item) => {
        data1.push({
          id: item?.id,
          date: displayDate(item?.date),
          fromDate: displayDate(item?.from_date),
          toDate: displayDate(item?.to_date),
          profit: item?.profit ? numberFormatter(Math.round(Number(item?.profit) * 100) / 100) : '0',
          name: item?.name,
          zakat: item?.zakat ? numberFormatter(Math.round(Number(item?.zakat) * 100) / 100) : '0',
          treasurer_name: item?.treasurer_name,
          zakat_details: item?.zakat_details,
          col: (
            <A
              onClick={() => {
                setNav(true);
                setEditDetails(item);
              }}
            >
              Edit
            </A>
          ),
        });
        setData(data1);
      });
    });
  };
  useEffect(() => {
    nav && setEdit(!edit);
  }, [editDetails]);

  useEffect(() => {
    getZakat();
  }, []);

  const columns = [
    {
      Header: 'Date',
      accessor: 'date', // accessor is the "key" in the data
    },
    {
      Header: 'From date',
      accessor: 'fromDate',
    },
    {
      Header: 'To date',
      accessor: 'toDate',
    },
    {
      Header: 'Profit',
      accessor: 'profit',
    },
    {
      Header: 'Zakat',
      accessor: 'zakat',
    },
    {
      Header: 'Treasurer',
      accessor: 'name',
    },
    {
      Header: 'Zakat details',
      accessor: 'zakat_details',
    },
    {
      Header: '',
      accessor: 'col',
    },
  ];

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  //console.log("bankAmount", bankAmount);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Container>
      {open === true ? <AddZakat setOpen={setOpen} getZakat={getZakat} /> : ''}
      {edit === true ? (
        <EditZakat
          setEdit={setEdit}
          setEditDetails={setEditDetails}
          setNav={setNav}
          editDetails={editDetails}
          getZakat={getZakat}
        />
      ) : (
        ''
      )}
      <MenuBar secondary>
        <MenuText>Zakat</MenuText>
        <MenuRightDiv>
          <Buttons
            style={{ padding: '10px 15px' }}
            onClick={() => setOpen(true)}
          >
            Add Zakat
          </Buttons>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBarZakat>
          <TableTopItems>
            <p>Amount Available in bank</p>
            {/* <input
              type='text'
              value={'AED ' + `${bankAmount ? bankAmount?.toFixed(2) : ''}`}
              style={{ width: 'fit-content', outline: 'none', pointerEvents: 'none' }}
            ></input> */}
            <div
              style={{
                width: 'fit-content',
                outline: 'none',
                pointerEvents: 'none',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                padding: '7px',
                borderRadius: '5px',
                fontSize: '14px',
              }}
            >
              {'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}
            </div>
          </TableTopItems>

          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBarZakat>

        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default Zakat;
