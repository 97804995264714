import React, { useEffect, useState } from 'react';
import {
  Container,
  LoginContainer,
  Head,
  Bminum,
  SignBtn,
} from './SignInStyled';
import { Logo } from '../../Const/assets';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  LoginFundCollector,
  logInSuperAdmin,
  loginTresurer,
} from '../../Const/Api/Api';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { setRole, setUser } from '../../features/login/loginSlice';

function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state?.user) {
      navigate('/');
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (location.state.role === 0) {
      axios({
        method: 'post',
        url: `${logInSuperAdmin}`,
        data: values,
      }).then((response) => {
        console.log(response?.data, 'aaaaa akr superadmn');
        if (response?.data?.code == 200) {
          swal({ text: 'Logged In Successfully', icon: 'success' }).then(() => {
            dispatch(setRole('superadmin'));
            dispatch(setUser(response.data.data[0]));
            navigate('/super-admin/bmid-creation');
            // navigate('/super-admin/bmid-creation');
            // localStorage.setItem('role', 0);
          });
          localStorage.setItem('userid', response?.data?.data[0]?.userid);
        } else {
          swal({ text: 'Something went wrong', icon: 'error' });
        }
      });
    }

    if (location.state.role === 1) {
      axios
        .post(`${LoginFundCollector}`, {
          bmi_id: values?.email,
          password: values?.password,
        })
        .then((response) => {
          console.log(response, response.data.data, '++++++++++++++');
          if (response?.data?.code == 200) {
            swal({ text: 'Logged In Successfully', icon: 'success' }).then(
              () => {
                dispatch(setUser(response.data.data));
                if (
                  response.data.data.fstatus === 0 &&
                  response.data.data.fund_collector_activity === 0
                ) {
                  dispatch(setRole('fundcollector'));
                  navigate('/fund-collector/fund-collection');
                } else if (
                  response.data.data.fstatus === 1 &&
                  response.data.data.fund_collector_activity === 0
                ) {
                  dispatch(setRole('mainfundcollector'));
                  navigate('/fund-collector/inactive-member-fund');
                } else if (
                  response.data.data.fstatus === 1 &&
                  response.data.data.fund_collector_activity === 1
                ) {
                  dispatch(setRole('superfundcollector'));
                  navigate('/fund-collector/fund-collection');
                }
                // }
              }
            );
            localStorage.setItem('userid', response?.data?.data?.userid);
          } else {
            swal({ text: 'Something went wrong', icon: 'error' });
          }
        });
    }

    if (location.state.role === 2) {
      axios
        .post(`${loginTresurer}`, {
          bmi_id: values?.email,
          password: values?.password,
        })
        .then((response) => {
          console.log(response?.data?.data?.userid, 'aaaaa');
          if (response?.data?.code == 200) {
            swal({ text: 'Logged In Successfully', icon: 'success' }).then(
              () => {
                dispatch(setRole('treasurer'));
                dispatch(setUser(response.data.data));
                navigate('/bmi-treasurer/active-members');
                // localStorage.setItem('role', 2);
              }
            );
            localStorage.setItem('userid', response?.data?.data?.userid);
            localStorage.setItem('id', response?.data?.data?.id);
          } else {
            swal({ text: 'Something went wrong', icon: 'error' });
          }
        });
    }
  };
  return (
    <Container>
      <LoginContainer>
        <Head>
          <p>{state?.user} Login</p>
        </Head>
        <form onSubmit={handleSubmit}>
          {state?.user == 'Super Admin' ? (
            <Bminum>
              <label>Email</label>
              <input
                name='email'
                type='email'
                value={values?.email}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Bminum>
          ) : (
            <Bminum>
              <label>BMID Number</label>
              <input
                name='email'
                type='text'
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Bminum>
          )}
          <Bminum>
            <label>Password</label>
            <input
              name='password'
              type='password'
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Bminum>

          <SignBtn type='submit'>Sign In</SignBtn>
        </form>
      </LoginContainer>
    </Container>
  );
}

export default SignIn;
