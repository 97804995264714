import styled from "styled-components";
import {Colors} from '../Const/Colors/Theme.js'

export const Main = styled.div`
    margin-top:102px;
    margin-left:250px;
    width:100%;
    position:relative;
    @media (max-width:1024px){
        margin-left:0px;
    }
    
`
export const Download = styled.div`
    text-decoration:underline;
    font-size:16px;
    color:${Colors.blue};
    cursor:pointer;
    position:relative;
    display:block;
    width:fit-content;
    right:0;`
