import axios from 'axios';
import React, { useState } from 'react'
import { BsX } from "react-icons/bs";
import swal from 'sweetalert';
import { InsertTreasurer } from '../../../Const/Api/Api';
import { Dropdown1 } from '../../MainFundCollector/FundCollection/FundCollection.styled';
import {
      Container,
      Modal,
      First,
      Second,
      Rows,
      Div,
      Divs,
      DivDate
} from './AssignTreasurerStyled'
import Select from 'react-select'

function AssignTreasurers({ setAssign, bmi_id, name, starting_date, treasure, getUsers, allBmi, ids }) {

      const [bmiId, setBmiId] = useState("")
      const [nameE, setNameE] = useState("")
      const [joiningDate, setJoiningDate] = useState("")
      const [treasurerStatus, setTreasurerStatus] = useState("")
      const [selectedOption, setSelectedOption] = useState(null);
      console.log('selectedOption: ', selectedOption);

      const getTreasurer = (e) => {
            console.log("🚀 ~ file: EditTreasurer.js:23 ~ getTreasurer ~ e", parseInt(e.target.value));
            setTreasurerStatus(parseInt(e.target.value))
      }
      const SumbitHandler = async () => {
            const data = {
                  bmi_id: selectedOption?.value,
                  starting_date: joiningDate,
                  treasurer: parseInt(treasurerStatus)

            }
            console.log("🚀 ~ file: AssignTreasurers.js:33 ~ SumbitHandler ~ data", data)
            await axios({
                  method: 'POST',
                  url: InsertTreasurer,
                  data
            }).then(response => {
                  if (response?.data.code == 200) {
                        swal({ text: "Treasurer assigned", icon: "success" })
                        setAssign(false);
                        getUsers()
                  }else{
                        swal({ text: response?.data.message, icon: "warning" })
                  }
            }).catch(err => {
                  swal({ text: 'Error', icon: "error" })
            })
      }
      return (
            <Container>
                  <Modal>
                        <First>
                              <p>Assign</p><BsX size={25} onClick={() => setAssign(false)} style={{ cursor: "pointer" }} />
                        </First>
                        <hr />
                        <Second>
                              <Rows>
                                    <Div>
                                          <input type="radio" id='1' name='fund' value='1' onChange={e => getTreasurer(e)} />
                                          <label>Treasurer</label>
                                    </Div>
                                    <br />
                                    <Div>
                                          <input type="radio" id='1' name='fund' value='0' onChange={e => getTreasurer(e)} />
                                          <label>Joint Treasurer</label>
                                    </Div>
                              </Rows>
                              {/* <Divs>
                                    <label>BMIP ID</label>
                                    <input type="text" value={bmiId} onChange={e => setBmiId(e.target.value)} />
                              </Divs> */}
                              {/* <Dropdown1 name="bmi_id" id="id" onChange={(e) => setBmiId(e.target.value)}>
                                    <option value="select">Select BMIP ID </option>

                                    {allBmi?.map((item) => {
                                          return (
                                                <option value={item?.id}>{item?.bmi_id} - {item?.name}</option>
                                          )
                                    })}
                              </Dropdown1> */}
                              <Divs>
                                    <label style={{ paddingBottom: "10px" }}>BMIP ID</label>
                                    <Select
                                          defaultValue={selectedOption}
                                          onChange={setSelectedOption}
                                          options={ids}
                                    />
                              </Divs>

                              <Divs>
                                    <label>Member Name</label>
                                    <input type="text" style={{ pointerEvents: "none" }} value={selectedOption?.value && allBmi?.filter(i => i?.id == selectedOption?.value)[0]?.full_name} onChange={e => setNameE(e.target.value)} />
                              </Divs>
                              <DivDate>
                                    <label>Starting date</label>
                                    <input type="date" value={joiningDate} onChange={(e) => setJoiningDate(e.target.value)} />
                              </DivDate>
                              <button onClick={SumbitHandler}>submit</button>
                        </Second>

                  </Modal>


            </Container>
      )
}

export default AssignTreasurers