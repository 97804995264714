import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from '../../../Components/Table/AdminTable';
import {
  approved_inactive_members,
  getDeactiveMembersList,
  selecttreasurer,
} from '../../../Const/Api/Api';
import { Colors } from '../../../Const/Colors/Theme';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
  Container,
} from '../../MainFundCollector/MainFundCollector.styled';
import {
  A,
  JusEnd,
  Overflow,
  Table,
} from '../ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { SuccessModals } from '../Modals/Portals';
import { Buttons } from './InactiveMembersStyle';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { LoadingContainer } from '../Report/FundCollectorActivity/FundCollectorActivityStyles';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../NumberFormatter';

const InactiveMembers = () => {
  const navigation = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [treasurer, setTreasurer] = useState([]);
  // const getTreasurer = () => {
  //   axios
  //     .get(`${selecttreasurer}/${localStorage.getItem('userid')}`)
  //     .then((resp) => {
  //       console.log('resp: 2222', resp?.data[0].id);
  //       setTreasurer(resp?.data);
  //     });
  // };
  const user = useSelector((state) => state.login.user);
  const inactivMembers = (id) => {
    const data = {
      bmi_id: id,
      treasurer_id: user?.id,
    };
    axios.post(`${approved_inactive_members}`, data).then((resp) => {
      getDeactvMem();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1540);
    });
  };

  const getDeactvMem = async () => {
    const resp = await axios.get(getDeactiveMembersList);
    console.log(resp.data, 'rrrrr');
    setLoading(true);
    const data1 = [];
    resp?.data?.map((item) => {
      data1.push({
        availableToInvest: item?.availableToInvest,
        availableToWithdraw: item?.availableToWithdraw ? numberFormatter(Math.round(Number(item?.availableToWithdraw) * 100) / 100) : '0',
        bmi_id: item?.bmi_id,
        full_name: item?.full_name,
        id: item?.id,
        joining_date: item?.joining_date,
        total_amount: item?.total_amount ? numberFormatter(Math.round(Number(item?.total_amount) * 100) / 100) : '0',
        inactiveDate: displayDate(item?.inactive_date),
        col: (
          <A
            onClick={() => {
              navigation('view', { state: { id: item?.id } });
            }}
          >
            View Details
          </A>
        ),
        col1:
          item?.status == 1 ? (
            <Buttons
              onClick={() => {
                inactivMembers(item?.id);
              }}
            >
              Approve
            </Buttons>
          ) : (
            <label style={{ color: Colors.green }}>Approved</label>
          ),
      });
    });
    setData(data1);
  };
  useEffect(() => {
    // getTreasurer();
    getDeactvMem();
  }, []);

  const columns = [
    {
      Header: 'BMIP ID',
      accessor: 'bmi_id', // accessor is the "key" in the data
    },
    {
      Header: 'Member Name',
      accessor: 'full_name',
    },
    {
      Header: 'Starting Date',
      accessor: 'joining_date',
    },
    {
      Header: 'Inactive Date',
      accessor: 'inactiveDate',
    },
    {
      Header: 'Total Amount (AED)',
      accessor: 'total_amount',
    },
    {
      Header: 'Amount Available to Withdrawal (AED)',
      accessor: 'availableToWithdraw',
    },
    {
      Header: 'Status',
      accessor: 'col1',
    },

    {
      Header: '',
      accessor: 'col',
    },
  ];

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };
  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.inactiveDate));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <Container>
      <MenuBar secondary>
        {success && <SuccessModals setShowModal={setSuccess} text='Approved' />}

        <MenuText>Inactive Members</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
          <JusEnd>
            <a
              style={{ color: Colors.blue, cursor: 'pointer' }}
              onClick={() => exportToExcel()}
            >
              Download
            </a>
          </JusEnd>
          {loading ? (
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          ) : (
            <LoadingContainer>
              <lottie-player
                src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
                background='transparent'
                speed='.5'
                style={{ width: '100px', height: '100px' }}
                loop
                autoplay
              ></lottie-player>
            </LoadingContainer>
          )}
        </Table>
      </Overflow>
    </Container>
  );
};

export default InactiveMembers;
