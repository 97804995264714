import React, { useEffect } from 'react';
import {
  Container,
  Subcontainer,
  Topbar,
  Editdet,
  Buttons,
} from './AddZakatStyled';
import { AiOutlineClose } from 'react-icons/ai';
import { useState } from 'react';
import axios from 'axios';
import {
  addZakat,
  selecttreasurer,
  UpdateZakat,
} from '../../../../Const/Api/Api';
import { Data } from './EditZakatStyled';
import { formatDate } from '../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const EditZakat = ({
  setEdit,
  setEditDetails,
  setNav,
  editDetails,
  getZakat,
}) => {
  const [date, setDate] = useState(formatDate(editDetails.date));
  const [fromDate, setfromDate] = useState(formatDate(editDetails.from_date));
  const [toDate, setToDate] = useState(formatDate(editDetails.to_date));
  const [profit, setProfit] = useState(editDetails.profit);
  const [zakat, setZakat] = useState(editDetails.zakat);
  const [zakatDetails, setZakatDetails] = useState(
    editDetails.zakat_details || ''
  );
  console.log(profit, 'akr', zakat, 'from', fromDate, 'to', toDate);



  // const getTreasurer = () => {
  //   axios
  //     .get(`${selecttreasurer}/${localStorage.getItem('userid')}`)
  //     .then((resp) => {
  //       setTreasurer(resp?.data);
  //     });
  // };
  // useEffect(() => {
  //   getTreasurer();
  // }, []);
  const user = useSelector((state) => state.login.user);
  const updateHandler = async () => {
    const data = {
      date,
      from_date: fromDate,
      to_date: toDate,
      profit: profit,
      zakat,
      treasurer: user.id,
      id: editDetails?.id,
      zakat_details: zakatDetails,
    };
    await axios
      .post(UpdateZakat, data)
      .then(function (response) {
        if(response?.data?.code == 200){
          swal({text: "Zakat added" , icon: "success"})
          getZakat();
          console.log(response);
          setEdit(false);
        }else{
          swal({text: response.data.message , icon: "warning"})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProfit = async () => {
    if (fromDate && fromDate) {
      const resp = await axios.post(addZakat, { fromDate, toDate });
      setProfit(resp.data.profit);
      setZakat(resp.data.zakat);
    }
  };

  return (
    <Container>
      <Subcontainer>
        <Topbar>
          <p>Edit Zakat</p>
          <AiOutlineClose
            onClick={() => {
              setEdit(false);
              setEditDetails && setEditDetails(false);
              setNav(false);
            }}
          />
        </Topbar>
        <Data>
          {/* <Editdet>
            <p>Date</p>
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type='date'
            />
          </Editdet> */}
          {/* <Editdet>
            <p>From Date</p>
            <input
              value={fromDate}
              onChange={(e) => {
                setfromDate(e.target.value);
                getProfit();
              }}
              type='date'
            />
          </Editdet>
          <Editdet>
            <p>To Date</p>
            <input
              value={toDate}
              onChange={(e) => {
                getProfit();
                setToDate(e.target.value);
              }}
              type='date'
            />
          </Editdet> */}
          {/* <Editdet>
            <p>Profit</p>
            <input value={profit} onChange={(e) => setProfit(e.target.value)} />
          </Editdet>
          <Editdet>
            <p>Zakat</p>
            <input value={zakat} onChange={(e) => setZakat(e.target.value)} />
          </Editdet> */}
          <Editdet>
            <p>Zakat Details</p>
            <textarea
              value={zakatDetails}
              onChange={(e) => setZakatDetails(e.target.value)}
            />
          </Editdet>
          <Buttons onClick={() => updateHandler()}>Update</Buttons>
        </Data>
      </Subcontainer>
    </Container>
  );
};

export default EditZakat;
