import React, { useEffect, useRef, useState } from 'react';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useFilters,
} from 'react-table';
import { Styles } from './AdminTable.styled';
import { BiSort } from 'react-icons/bi';
import { InputElement, SelectElement } from '../../Const/Input/Input';
import { SaveButtonElement } from '../../Const/Button/Button';
import { useLocation } from 'react-router-dom';
import GrandTotalTable from './GrandTotalTable';

export const customFilterFunction = (rows, id, filterValue) =>
  rows.filter((row) => {
    return row.original.year.includes(filterValue);
  });

function AdminTable({ data, columns, years, serial_no, allGrandTotalData={} }) {
  const location = useLocation();
  const pathName = location.pathname;
  const inputRef = useRef(null);
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();

  const [field, setField] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [columnFilters, setColumnFilters] = useState();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    // setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const settingFilter = (e) => {
    setGlobalFilter(e.target.value);
    setValue1(e.target.value);
    inputRef.current?.focus();
  };
  const changeFilter = (e) => {
    setFilter('year', e.target.value);
  };

  React.useEffect(() => {
    // inputRef.current?.focus();
  }, []);

  return (
    <Styles>
      {/* <h3>{heading}</h3> */}
      {pathName.includes('/assign-sip') ? (
        <div className='flex'>
          <div className='filter-div'>
            <select
              onChange={(e) => changeFilter(e)}
              name='year'
              id='year'
              defaultValue='none'
            >
              <option value='none' disabled>
                Year
              </option>
              {years?.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className='search-div'>
            <InputElement
              ref={inputRef}
              placeholder='Search'
              value={value1}
              onChange={(e) => settingFilter(e)}
            ></InputElement>
          </div>
        </div>
      ) : pathName.includes('investment-fund-approval') ||
        pathName.includes('pending-request') ? (
        <br />
      ) : (
        <div className='search-div'>
          <InputElement
            ref={inputRef}
            placeholder='Search'
            value={value1}
            onChange={(e) => settingFilter(e)}
          ></InputElement>
        </div>
      )}

      {/* </div> */}

      <table {...getTableProps()} id='my-table'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {serial_no && <th>Serial No.</th>}
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* <span
                    style={{
                      float: "right",
                      color: "#c4c4c4",
                    }}>
                    {column.isSorted ? (
                      column.isSortDesc ? (
                        <BiSort fontSize={12} />
                      ) : (
                        <BiSort fontSize={12} />
                      )
                    ) : column.isSortDesc ? (
                      <BiSort fontSize={12} />
                    ) : (
                      <BiSort fontSize={12} />
                    )}
                  </span> */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {serial_no && <td>{i + 1}</td>}
                {row.cells.map((cell) => {
                  return (
                    <td style={{
                      backgroundColor: cell.row.original.status == 3 ? '#d0d0d0' : 'white'
                    }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {
        Object.keys(allGrandTotalData).length > 0 ? 
        (
          <GrandTotalTable allGrandTotalData={allGrandTotalData} />
        )
        :
        (
          <></>
        )
      }

      <div className='pagination'>
        <div className='pagination-buttons'>
          <SaveButtonElement
            style={{ width: 'auto', height: 'auto' }}
            type={!canPreviousPage ? '#c4c4c4' : '#999999'}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: 'auto', height: 'auto' }}
            type={!canPreviousPage ? '#c4c4c4' : '#999999'}
            onClick={(e) => {
              e.preventDefault();
              previousPage();
            }}
            disabled={!canPreviousPage}
          >
            {'<'}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: 'auto', height: 'auto' }}
            type={!canNextPage ? '#c4c4c4' : '#999999'}
            onClick={(e) => {
              e.preventDefault();
              nextPage();
            }}
            disabled={!canNextPage}
          >
            {'>'}
          </SaveButtonElement>
          &nbsp;
          <SaveButtonElement
            style={{ width: 'auto', height: 'auto' }}
            type={!canNextPage ? '#c4c4c4' : '#999999'}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </SaveButtonElement>
        </div>
        &nbsp;
        <div className='pagination-buttons'>
          <span>
            Page&nbsp;
            <strong>
              {pageIndex + 1}&nbsp; of &nbsp; {pageOptions.length}
            </strong>
            &nbsp;
          </span>
          <span>|&nbsp; Go to page: </span>&nbsp;
          <InputElement
            type='number'
            style={{ width: 'auto', height: 'auto' }}
            placeholder=''
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          ></InputElement>
          &nbsp;
          <SelectElement
            style={{ width: 'auto', height: 'auto' }}
            placeholder=''
            dropdown='true'
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </SelectElement>
        </div>
      </div>
    </Styles>
  );
}
export default AdminTable;
