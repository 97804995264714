import React, { useEffect, useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
  Click,
  Buttons,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import AddExpense from '../../Modal/AddExpense';
import { getExpesnseOut } from '../../../../../Const/Api/Api';
import axios from 'axios';
import EditExpenses from '../../Modal/EditExpenses';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import { Colors } from '../../../../../Const/Colors/Theme';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx';
import { numberFormatter } from '../../../../../NumberFormatter';

function Expenses() {
  const [data, setData] = useState([]);
  const [addexpen, setAddexpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('');

  const columns = [
    {
      Header: 'Serial No.',
      accessor: 'no', // accessor is the "key" in the data
    },
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Amount (AED)',
      accessor: 'amount',
    },
    {
      Header: 'Purpose',
      accessor: 'purpose',
    },
    {
      Header: '',
      accessor: 'action',
    },
  ];

  const getExpenses = () => {
    axios.get(getExpesnseOut).then((res) => {
      setBankAmount(res?.data?.amount);
      setLoading(true);
      const data1 = [];
      res?.data?.details.reverse().map((item, index) => {
        data1.push({
          no: index + 1,
          date: displayDate(item?.date),
          amount: item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0',
          purpose: item?.purpose,
          action: (
            <Click
              onClick={() => {
                setEditDetails(item);
                setIsEdit(true);
              }}
            >
              Edit
            </Click>
          ),
        });
        setData(data1);
      });
    });
  };
  useEffect(() => {
    getExpenses();
  }, []);

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };
  //console.log("bankAmount", bankAmount);
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      {addexpen === true ? (
        <AddExpense setAddexpen={setAddexpen} getExpenses={getExpenses} />
      ) : (
        ''
      )}
      {isEdit && (
        <EditExpenses
          setIsEdit={setIsEdit}
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          getExpenses={getExpenses}
        />
      )}
      <MenuBar secondary>
        <MenuText>Expense</MenuText>
        <MenuRightDiv>
          <Buttons
            style={{ padding: '10px 15px' }}
            onClick={() => setAddexpen(true)}
          >
            Add Expense
          </Buttons>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <p>Amount Available in bank</p>
            <div
              style={{
                width: 'fit-content',
                outline: 'none',
                pointerEvents: 'none',
                border: '1px solid rgba(0, 0, 0, 0.3)',
                padding: '7px',
                borderRadius: '5px',
                fontSize: '14px',
              }}
            >
              {'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}
            </div>
          </TableTopItems>
          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBar>
        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default Expenses;
