import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Colors, size } from '../../../Const/Colors/Theme';

const open = 'translateX(-120%)';
const close = 'translateX(0)';

export const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 250px;
  background-color: ${Colors.white};
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
  @media screen and (max-width: ${size.Laptop}) {
    transition: all 0.5s ease;
    transform: ${(props) => (props.menuOpen ? open : close)};
  }
`;

export const Sidenav = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 100px;
  padding-top: 40px;
`;

export const NavOption = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Button = styled.button`
  background-color: white;
  border: none;
  color: ${(props) => (props.click ? `${Colors.blue}` : '#000000')};
  margin-top: 5px;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;

  span {
    color: rgba(0, 0, 0, 0.4);
    margin-right: 5px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  align-items: baseline;
  border-left: 1px solid ${'#c4c4c4'};
  gap: 10px;
  padding-top: 12px;
`;

export const LinkBtn = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => (props.click ? `${Colors.blue}` : '#000000')};
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  gap: 5px;
  cursor: pointer;
  justify-content: normal;
  font-size: 18px;
  font-weight: 400;
`;

export const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #4668e9;
  }
`;
