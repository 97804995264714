import React from 'react';
import {
  CheckTable,
  CheckTableDiv,
  Modal,
  ModalContainerR,
  ModalContent,
  ModalHeader,
} from '../../Treasurer/CompanyDetails/CompanyDetails/CompanyDetailsStyles';
import { AiOutlineClose } from 'react-icons/ai';
import { numberFormatter } from '../../../NumberFormatter';

const AmountModal = (props) => {
  console.log('props: ', props?.amountDetails);
  return (
    <Modal>
      <ModalContainerR>
        <ModalHeader>
          <h1>{props.name == null ? 'All' : props?.name}</h1>
          <span onClick={() => props.setAmountModal(false)}>
            <AiOutlineClose size={20} />
          </span>
        </ModalHeader>
        <ModalContent>
          <CheckTableDiv>
            <CheckTable>
              <tr>
                <th>Serial No.</th>
                <th>BMIP ID</th>
                <th>Member Name</th>
                <th>Remark</th>
                <th>Amount</th>
              </tr>
              {props?.amountDetails.reverse()?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.bmipno}</td>
                    <td>{item?.name}</td>
                    <td>{item?.remarks}</td>
                    <td>{item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : '0'} </td>
                  </tr>
                );
              })}
            </CheckTable>
          </CheckTableDiv>
        </ModalContent>
      </ModalContainerR>
    </Modal>
  );
};

export default AmountModal;
