import styled from "styled-components";
import { Colors, size } from "../../Const/Colors/Theme";

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.blue};
  height: 102px;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  padding: 0 30px;
`;
export const LogoConatiner = styled.div`
  display: flex;
  align-items: center;
  /* margin: 10px 22px; */
`;
export const DrawerIcon = styled.div`
  @media screen and (min-width: 1025px) {
    display: none;
  }
`;
export const DrawerItems = styled.div`
  margin-top: 30px;
`;
export const LogoWrapper = styled.div`
  img {
    width: 81px;
    height: 81px;
  }
`;
export const MenuItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const HeaderText = styled.div`
  margin-left: 10px;
  color: ${Colors.white};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  @media screen and (max-width: ${size.Tablet}) {
    font-size: 18px;
  }
`;

export const MenuItemContainer = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  @media screen and (max-width: ${size.Tablet}) {
    gap: 30px;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  img {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;
export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  img {
    cursor: pointer;
    width: 10px;
  }
  span {
    cursor: pointer;
    color: ${Colors.white};
    font-size: 15px;
    font-weight: 400;
    @media screen and (max-width: ${size.Tablet}) {
      font-size: 14px;
    }
  }
`;
export const PopupModal = styled.div`
  position: absolute;
  display: flex;
  right: 0;
  background-color: ${Colors.white};
  margin-top: 80px;
  border: 1px solid ${Colors.border};
  border-radius: 2px;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  span {
    display: flex;
    gap: 4px;
    align-items: center;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
  }
 
`;
export const UserDiv = styled.div`
  display: none;
  
  /* img{
    width: 0px;
    height: 20px;
  } */
  @media screen and (max-width: 600px) {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }
`
export const ProfIcon = styled.img`
  width: 30px;
`
export const PopupModal1 = styled.div`
   position: absolute;
   right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
  margin-top: 80px;
  border: 1px solid ${Colors.border};
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  span {
    color: black;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    gap: 5px;
    :hover{
      background-color: #dddbdb99;
    }
  }
  img{
    color: ${Colors.black};
    width: 10px;
  }
   @media screen and (min-width: 600px) {
    display: none;
  }
`
export const PendingCount = styled.div`
 background: red;
    width: 14px;
    border-radius: 9px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
        top: 0;
    right: 0;
  span{
    font-size: 10px;
    color: white;
    font-weight: 900;
  }
  @media screen and (max-width: 600px){
        top: 42px;
    right: 108px;
    
  }

`