import React, { useState } from 'react';
import AdminTable from '../../../../../Components/Table/AdminTable';
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from '../../../../MainFundCollector/MainFundCollector.styled';
import { BlackArrowDown, BlackArrowUp } from '../../../../../Const/assets';
import axios from 'axios';
import { getBankInOfProfit } from '../../../../../Const/Api/Api';
import { useEffect } from 'react';
import { LoadingContainer } from '../../../Report/FundCollectorActivity/FundCollectorActivityStyles';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Colors } from '../../../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  formatDate,
} from '../../../../MainFundCollector/FundTransferDetails/FundTransferDetails';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../../../NumberFormatter';

function Profit() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmnt, setTotalAmnt] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [dateFilt, setDateFilt] = useState(false);
  const [bankAmount, setBankAmount] = useState('')

  const getProfits = () => {
    axios.get(getBankInOfProfit).then((res) => {
      setBankAmount(res?.data?.amount)
      setLoading(true);
      const data1 = [];
      let total = 0;
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      res?.data?.details.reverse().map((item, index) => {
        data1.push({
          serialNo: index + 1,
          transferToBank_date: item?.transferToBank_date,
          companyName: item?.name,
          fund_collector_id: item?.fund_collector_id,
          collected_from: item?.collected_from,
          amount:item?.amount ? numberFormatter(Math.round(Number(item?.amount) * 100) / 100) : item?.collected_amount,
          month: months[+item?.month - 1],
          remarks: item?.remarks,
        });
        total = total + parseInt(item?.amount);
      });
      setData(data1);
      setTotalAmnt(total);
    });
    // const data = resp?.data.map((item, index) => {
    //   setLoading(true);
    //   const d = new Date((item?.transferToBank_date).split("-").reverse().join("-"));
    //   const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    //   let month = months[d.getMonth()];
    //   return {
    // serialNo: index + 1,
    // transferToBank_date: item?.transferToBank_date,
    // companyName: item?.name,
    // fund_collector_id: item?.fund_collector_id,
    // collected_from: item?.collected_from,
    // amount: item?.amount,
    // month: month,
    // remarks: item?.remarks

    //   }
    // });
    setData(data);
  };
  useEffect(() => {
    getProfits();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Serial No.',
        accessor: 'serialNo',
      },
      {
        Header: 'Transfer Date',
        accessor: 'transferToBank_date',
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Fund collector ID',
        accessor: 'fund_collector_id',
      },
      {
        Header: 'Month',
        accessor: 'month',
      },
      {
        Header: 'Collected From',
        accessor: 'collected_from',
      },
      {
        Header: 'Remark',
        accessor: 'remarks',
      },
      {
        Header: 'Amount (AED)',
        accessor: 'amount',
      },
    ],
    []
  );

  const exportPdf = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#my-table' });
    doc.save('table.pdf');
  };
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dateFilt ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.transferToBank_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
      console.log('filteredData: ', filteredData);
    }
  };
  //console.log("bankAmount", bankAmount);

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Profit</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input
                type='Date'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </DateWrapper>
          </RightItemsWrapper>
          <Button
            onClick={() => {
              dateFilterChangeHandler();
              setDateFilt(true);
            }}
          >
            Apply
          </Button>
        </MenuRightDiv>
      </MenuBar>
      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>
            <p>Amount Available in bank</p>
            {/* <input
              type='text'
              value={'AED ' + `${bankAmount ? bankAmount?.toFixed(2) : ''}`}
              style={{ width: 'fit-content' }}
            /> */}
            <div style={{
              width: 'fit-content', outline: 'none', pointerEvents: 'none', border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '7px', borderRadius: '5px', fontSize: '14px'
            }}
            >{'AED ' + `${bankAmount ? numberFormatter(Math.round(Number(bankAmount) * 100) / 100) : ''}`}</div>
          </TableTopItems>
          <a
            style={{ float: 'right', color: Colors.blue, cursor: 'pointer' }}
            onClick={() => {
              exportToExcel();
            }}
          >
            Download
          </a>
        </TableTopBar>
        {loading ? (
          <TableContainer>
            <AdminTable
              columns={columns}
              data={dateFilt ? dateFilteredData : data}
            />
          </TableContainer>
        ) : (
          <LoadingContainer>
            <lottie-player
              src='https://assets4.lottiefiles.com/packages/lf20_vf08px4p.json'
              background='transparent'
              speed='.5'
              style={{ width: 'fit-content', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
          </LoadingContainer>
        )}
      </ContentDiv1>
    </Container>
  );
}

export default Profit;
