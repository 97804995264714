const numberFormatter = (number) => {
    number = Math.round(Number(number) * 100) / 100;

    if(number == -0){
        return 0;
    }

    let formatted = new Intl.NumberFormat('ar-ae').format(Number(number));

    if(formatted == '-0'){
        formatted = 0;
    }

    return formatted;
}

module.exports = {
    numberFormatter,
}