import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminTable from './../../../Components/Table/AdminTable';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import { DateWrapper, MenuBar, MenuRightDiv, MenuText, RightItemsWrapper, Container } from './../../MainFundCollector/MainFundCollector.styled';
import { Overflow, Table } from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle'
import { SuccessModals } from '../../Treasurer/Modals/Portals';
import { Button } from '../../MainFundCollector/MainFundCollector.styled';
import axios from 'axios';
import { ApprovingZakat, ListZakat } from '../../../Const/Api/Api';
import { Colors } from '../../../Const/Colors/Theme';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import swal from 'sweetalert';
import { displayDate } from '../FundTransferDetails/FundTransferDetails';
import { zakatCount } from '../../../features/notifications/notificationSlice';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx'
import { numberFormatter } from '../../../NumberFormatter';

const Zakat = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([])
    const current = new Date();
    const currentDate = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const date = {
        ransferToBank_date: currentDate
    }
    const [id, setId] = useState()
    const [bank, setBank] = useState(false)

    const getZakat = () => {
        const data1 = []
        axios.get(ListZakat).then((resp) => {
            resp?.data?.details?.reverse().map((item, index) => {
                data1.push({
                    from_date: item?.from_date,
                    to_date: item?.to_date,
                    date: displayDate(item?.date),
                    profit: item?.profit ? numberFormatter(Math.round(Number(item?.profit) * 100) / 100) : '0',
                    zakat: item?.zakat ? numberFormatter(Math.round(Number(item?.zakat) * 100) / 100) : '0',
                    name: item?.name,
                    zakat_details: item?.zakat_details,
                    col: item?.transferFromBank == 1 ? <Button style={{pointerEvents: "none" , background: "red" , padding: '8px 10px', backgroundColor: "#999999" }}> Transfer from bank</Button> :<Button style={{ padding: '8px 10px'  }} disabled={item?.transferFromBank
                        == 1 ? true : false} onClick={() => approvingZakat(item?.id)}>Transfer from Bank</Button>
                })
                // setId(item?.id)
            })
            setData(data1)
        })
    }
    useEffect(() => {
        getZakat()
    }, [])
    const approvingZakat = (ids) => {
        axios.post(ApprovingZakat, { id: ids }).then((res) => {
            if (res?.data?.code == 200) {
                dispatch(zakatCount(res.data.length));
                getZakat()
                setBank(true)
                swal({ text: res.data.message, icon: "success" })
            } else {
                swal({ text: res.data.message, icon: "warning" })
            }
        }).catch((err) => {
            console.log(err);
        })
    }





    const columns = [
        {
            Header: "Date",
            accessor: "date", // accessor is the "key" in the data
        },
        {
            Header: "From Date",
            accessor: "from_date",
        },
        {
            Header: "To Date",
            accessor: "to_date",
        },
        {
            Header: "Profit",
            accessor: "profit",
        },
        {
            Header: "Zakat",
            accessor: "zakat",
        },
        {
            Header: "Treasurer Name",
            accessor: "name",
        },
        {
            Header: "Zakat details",
            accessor: "zakat_details",
        },
        {
            Header: "",
            accessor: "col",
            // Cell: cellInfo => {
            //     return (
            //       <Button style={{padding:'8px 10px'}}>Transfer to Bank</Button>
            //     )
            //   }
        },

    ]
    // const exportPdf = () => {
    //     const doc = new jsPDF()
    //     doc.autoTable({ html: '#my-table' })
    //     doc.save('table.pdf')
    // }
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAsExcel(excelBuffer, 'data.xlsx');
      };
    
      const saveAsExcel = (buffer, fileName) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <Container>
            <MenuBar secondary>
                <MenuText>Zakat</MenuText>
                <MenuRightDiv>
                    {/* <RightItemsWrapper>
                        <DateWrapper>
                            <span>From</span>
                            <input type="Date" />
                        </DateWrapper>
                        <DateWrapper>
                            <span>To</span>
                            <input type="Date" />
                        </DateWrapper>
                    </RightItemsWrapper> */}
                    {/* <Button>Apply</Button> */}

                </MenuRightDiv>
            </MenuBar>
            <Overflow>
                <Table>
                    <label style={{ textAlign: "right", color: Colors.blue, cursor: "pointer" }} onClick={() => exportToExcel()} >
                        Download
                    </label>
                    <AdminTable columns={columns} data={data} />
                </Table>
            </Overflow>
        </Container>
    )
}

export default Zakat