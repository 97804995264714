import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/Table/AdminTable";
import {
  Button,
  Container,
  ContentDiv,
  ContentDiv1,
  DateWrapper,
  DropDown,
  DropDownDrawer,
  DropDownItem,
  FormGroup,
  ImgDiv,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  TableContainer,
  TableTopBar,
  TableTopItems,
} from "./ActiveMemberStyled";
import { BlackArrowDown, BlackArrowUp } from "../../../Const/assets";
import { useNavigate } from "react-router-dom";
import { A } from "../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle";
import axios from "axios";
import { getActiveMembersList } from "../../../Const/Api/Api";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { Colors } from "../../../Const/Colors/Theme";
import { dateBetweenCheck, formatDate } from "../../MainFundCollector/FundTransferDetails/FundTransferDetails";
import * as XLSX from 'xlsx'
import { numberFormatter } from "../../../NumberFormatter";

function Activemembers() {
  const navigation = useNavigate();
  const [data, setData] = useState([])
  const [date, setDate] = useState()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isDateFilterActive, setDateFilterActive] = useState(false);

  const getActiveMem = () => {
    axios.get(getActiveMembersList).then((resp) => {
      const data1 = []
      resp?.data?.map((item) => {
        data1.push({
          availableToInvest: item?.availableToInvest ? numberFormatter(Math.round(Number(item?.availableToInvest) * 100) / 100) : '0',
          availableToWithdraw: item?.availableToWithdraw ? numberFormatter(Math.round(Number(item?.availableToWithdraw) * 100) / 100) : '0',
          bmi_id: item?.bmi_id,
          full_name: item?.full_name,
          id: item?.id,
          joining_date: item?.joining_date,
          total_amount: item?.total_amount ? numberFormatter(Math.round(Number(item?.total_amount) * 100) / 100) : '0',
          inactiveDate: item?.inactive_date,
          col: <A onClick={() => {
            navigation('view', { state: { id: item?.id } });
          }}>View Details</A>
        })
        setData(data1)
        setDate(data1?.joining_date)
      })
    }).catch((e) => console.log(e))
  }
  useEffect(() => {
    getActiveMem()
  }, [navigation])

  const columns = React.useMemo(
    () => [
      {
        Header: "BMIP ID",
        accessor: "bmi_id",
      },
      {
        Header: "Member Name",
        accessor: "full_name",
      },
      {
        Header: "Starting Date",
        accessor: "joining_date",
      },
      {
        Header: "Total Amount",
        accessor: "total_amount",
      },
      {
        Header: "Fund Available to invest",
        accessor: "availableToInvest",
      },
      {
        Header: "Fund Available to withdrawal",
        accessor: "availableToWithdraw",
      },
      {
        Header: "",
        accessor: "col",
        // Cell: cellInfo => {
        //   return (
        //     <A onClick={() => {
        //       navigation('view', { state: { getActiveMembers: getActiveMem() } });
        //     }}>View Details</A>
        //   )
        // }
      },
    ],
    []
  );


  // const exportPdf = () => {
  //   const doc = new jsPDF()
  //   doc.autoTable({ html: '#my-table' })
  //   doc.save('table.pdf')
  // }
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dateFilterChangeHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else if (startDate === '') {
      alert('Please select from date ');
    } else {
      setDateFilterActive(true);
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.joining_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  return (
    <Container>
      <MenuBar secondary>
        <MenuText>Active Members</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input type="Date" name="start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type="Date" name="end-date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </DateWrapper>

          </RightItemsWrapper>
          <Button onClick={() => dateFilterChangeHandler()}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>

      <ContentDiv1>
        <TableTopBar>
          <TableTopItems>

          </TableTopItems>
          <label style={{ float: "right", color: Colors.blue, cursor: "pointer" }} onClick={() => exportToExcel()} >
            Download
          </label>
        </TableTopBar>


        <TableContainer>
          <AdminTable columns={columns} data={isDateFilterActive ? dateFilteredData : data} />
        </TableContainer>
      </ContentDiv1>
    </Container>
  );
}

export default Activemembers;
