import styled from 'styled-components';
import { Colors, fonts } from '../../Const/Colors/Theme';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: ${Colors.blue};
`;

const LoginContainer = styled.div`
  width: 400px;

  background-color: ${Colors.white};
  // align-items:flex-start;
  display: flex;
  flex-direction: column;
  // border:1px solid ${Colors.white};
  border-radius: 8px;
`;
const Head = styled.div`
  border-bottom: 1px solid ${Colors.border};

  p {
    font-size: 24px;
    color: ${Colors.black};
    font-weight: ${fonts.fontweight4};
    margin: 5px 0;
    padding: 20px;
  }
`;
const Bminum = styled.div`
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  margin: 8px 0;
  label {
    font-size: 14px;
    margin: 5px 0;
  }
  input {
    padding: 10px;
    /* height:28px; */
    border-radius: 8px;
    border: 1px solid ${Colors.border};
    outline: none;
  }
`;

const SignBtn = styled.button`
  display: flex;
  width: 90%;
  margin: 20px;
  background-color: ${Colors.blue};
  color: ${Colors.white};
  border-radius: 5px;
  height: 45px;
  border: none;
  // margin:8px 0;
  font-size: 14px;
  justify-content: center;
  align-items: center;
`;

export { Container, LoginContainer, Head, Bminum, SignBtn };
