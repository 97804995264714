import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ButtonsModal, Flex, FlexEnd, ModalContent, Overlay, P } from './AddEibStyle';
import GIF from '../../../images/success.gif'
import { ImgGid, Success, SucDiv } from '../../MainFundCollector/InactiveMemberFund/inactiveMemberFundStyle';
const SuccessModal = ({ setShowModal, text }) => {
    const navigation = useNavigate()
    return (
        <Overlay onClick={() => { setShowModal(false) }} >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <SucDiv>
                    <ImgGid src={GIF} alt="gif" />
                    <Success>{text}</Success>
                </SucDiv>
            </ModalContent>
        </Overlay>
    )
}
export default SuccessModal

