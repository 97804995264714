import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from './../features/notifications/notificationSlice';
import loginReducer from './../features/login/loginSlice';
import otherReducer from './../features/others/othersSlice';
export const store = configureStore({
  reducer: {
    notification: notificationReducer,
    login: loginReducer,
    others: otherReducer,
  },
});
