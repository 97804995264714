import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createPortal } from 'react-dom';
import {
  Amount,
  Date,
  DateDiv,
  Flex,
  Label,
  ModalContent,
  Overlay,
  Pdiv,
  Relative,
  Update,
} from './AddEibStyle';
import { BsCalendar4 } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import axios from 'axios';
import { insertEibKunooz } from '../../../Const/Api/Api';
import swal from 'sweetalert';

const AddEib = ({ setShowModal, getEibKunooz }) => {
  const dateInput = useRef();
  const [startDate, setStartDate] = useState('');
  const [values, setValues] = useState({
    date: '',
    amount: '',
  });
  console.log('values: ', values);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const addEib = () => {
    axios({
      method: 'post',
      url: insertEibKunooz,
      data: values,
    }).then((resp) => {
      if (resp.data.code === 200) {
        getEibKunooz();
        swal({ text: "EIB kunooz Added", icon: 'success' });
      } else {
        swal({ text: resp?.data?.message, icon: 'warning' });
      }
      console.log(resp.data, 'dffdaae');
    });
  };

  return (
    <Overlay
      onClick={() => {
        setShowModal(false);
      }}
    >
      <ModalContent
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Flex>
          <h4>Add EIB Kunooz</h4>
          <GrClose
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Flex>
        <hr />
        <Pdiv>
          <Label htmlFor='date'>Date</Label>
          <DateDiv>
            <input
              type='date'
              name='date'
              value={values.date}
              onChange={(e) => handleChange(e)}
            />
          </DateDiv>
          <Label htmlFor='amount'>EIB Kunooz Amount</Label>
          <Amount
            type='text'
            name='amount'
            value={values.amount}
            onChange={(e) => handleChange(e)}
          />
          <Update
            onClick={() => {
              setShowModal(false);
              addEib();
            }}
          >
            Update
          </Update>
        </Pdiv>
      </ModalContent>
    </Overlay>
  );
};
export default AddEib;
