import React from 'react'
import SingleRow from './SingleRow'
import { GridTwo, Title } from './ViewMembersStyle'

const BankDetails = ({location , bankName , acHolderName, acNumber, ifsc ,branch, ibanNumber, swift, currency  }) => {
  console.log('ifsc: ', ifsc);
  return (
    <div>
        <Title>Bank Details({location})</Title>
        <GridTwo>
            {bankName && <SingleRow title='Bank Name' value={bankName} size='190px' />}
            {acNumber && <SingleRow title='Account Number' value={acNumber} size='190px' />}
            {acHolderName && <SingleRow title='Account Holder Name' value={acHolderName} size='190px' />}
            {ifsc && <SingleRow title='Branch IFSC' value={ifsc} size='190px' />}
            {branch && <SingleRow title='Branch' value={branch} size='190px' />}
            {currency && <SingleRow title='Currency' value={currency} size='190px' />}
            {swift && <SingleRow title='SWIFT' value={swift} size='190px' />}
            {ibanNumber && <SingleRow title='IBAN Number' value={ibanNumber} size='190px' />}
        </GridTwo>
    </div>
  )
}

export default BankDetails