export const Colors = {
  blue: "#346ECA",
  white: "#fff",
  border: "rgba(1, 1, 1, 0.2)",
  white: "#ffffff",
  black: "#000000",
  primary: "#46a995",
  disabled: "#c4c4c4",
  grey: "#999999",
  inputborder: "#ddd",
  green: "#00a658",
  red: "#d8505a",
  lightred:'#FF424E',
  info: "#5fcff5",
  warning: "#fcb819",
  white_smoke: "#f4f3f3",
  blackish_grey: "#e9edf0",
  yellowishGreen: "#b3c100",
  lightViolet: "#488a99",
  brown: "#484848",
  offWhite: "#c6d4fc",
  lightGrey: "#e3e3e3",
  transparentBlack: "#000000cf",
  bg: "#edf1eb",
  dark_grey: " #707070",
  medgrey:'#D9D9D9',
  lightgrey:'rgba(0,0,0,0.6)',
  lightgreen: "#6EB735",
};

export const fonts = {
  fontweight1: "300",
  fontweight2: "400",
  fontweight3: "500",
  fontweight4: "600",
  fontweight5: "bold",
};

export const size = {
  Desktop: "2560px",
  LaptopL: "1440px",
  Laptop: "1024px",
  Tablet: "768px",
  MobileL: "425px",
  MobileM: "375px",
  MobileS: "320px",
  heading: "24px",
  font: "16px",
};
