import React from 'react';
import { Route, Routes } from 'react-router-dom';

import SideNav from '../Screens/MainFundCollector/SideNav/SideNav';
import FundCollection from '../Screens/MainFundCollector/FundCollection/FundCollection';
import FundTransferDetails from '../Screens/MainFundCollector/FundTransferDetails/FundTransferDetails';
import InactiveMemberFund from '../Screens/MainFundCollector/InactiveMemberFund/InactiveMemberFund';
import InvestmentFundApproval from '../Screens/MainFundCollector/InvestmentFundApproval/InvestmentFundApproval';
import Expense from '../Screens/MainFundCollector/Expense/Expense';
import Zakat from '../Screens/MainFundCollector/Zakat/Zakat';
import FundRecievedCollectors from '../Screens/MainFundCollector/FundRecievedCollectors/FundRecievedCollectors';

function MainFundCollector() {
  return (
    <div className='sidebar__container'>
      <SideNav />
      <Routes>
        <Route path='/fund-collection' element={<FundCollection />} />
        <Route
          path='/fund-transfer-details'
          element={<FundTransferDetails />}
        />
        <Route path='/inactive-member-fund' element={<InactiveMemberFund />} />
        <Route
          path='/investment-fund-approval'
          element={<InvestmentFundApproval />}
        />
        <Route path='/expense' element={<Expense />} />
        <Route path='/zakat' element={<Zakat />} />
        <Route
          path='/fund-recieved-collectors'
          element={<FundRecievedCollectors />}
        />
      </Routes>
    </div>
  );
}

export default MainFundCollector;
