import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  NotContent,
  NotDetails,
  NotDropdownMenu,
  NotHead,
  NotificatnDiv,
} from './NotificationStyle';
import BMILogo from '../../Const/assets/Logo.png';
import axios from 'axios';
import { viewNotification } from '../../Const/Api/Api';
import { useSelector } from 'react-redux';

const Notification = ({ data, setNotf, getNotifications, notId, menu }) => {
  console.log('notIdsss:====> ', notId);
  console.log('data: ', data);
  const moment = require('moment');
  const currentDate = moment();
  const date = currentDate.format('YYYY-MM-DD');

  const handleSubmit = (item) => {
    const data = {
      bmi_id: notId,
      id: item?.id,
    };
    console.log('data: ', data);
    axios.post(viewNotification, data).then((resp) => {
      console.log(resp.data, 'notification status');
      getNotifications();
    });
  };

  return (
    <NotificatnDiv menu>
      <NotHead>
        {' '}
        <p>Notifications</p>{' '}
        <span onClick={() => setNotf(false)}>
          {' '}
          <IoClose />
        </span>
      </NotHead>
      <hr />

      <NotDropdownMenu>
        {data?.map((item) => {
          return (
            <>
              <NotContent
                onClick={() => handleSubmit(item)}
                style={{
                  background: item?.view_status == 1 ? '#ffffff' : '#ebebeb',
                }}
              >
                <img src={BMILogo} alt='logo' />
                <NotDetails>
                  <p>{item?.content}</p>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <label>
                      {moment(date).diff(moment(item?.date)) /
                        (1000 * 60 * 60 * 24) ==
                        0
                        ? 'Today'
                        : `${moment(date).diff(moment(item?.date)) /
                        (1000 * 60 * 60 * 24)
                        }d`}
                    </label>
                  </div>
                </NotDetails>
              </NotContent>
            </>
          );
        })}
      </NotDropdownMenu>
    </NotificatnDiv>
  );
};

export default Notification;
