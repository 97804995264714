import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminTable from './../../../Components/Table/AdminTable';
import { Download } from '../../../dashboards/BMITreasurerStyle';
import {
  DateWrapper,
  MenuBar,
  MenuRightDiv,
  MenuText,
  RightItemsWrapper,
  Button,
  Container,
  Click,
} from './../../MainFundCollector/MainFundCollector.styled';
import {
  Overflow,
  Table,
} from '../../Treasurer/ActiveMembers/ActiveMembers/ActiveMembersStyle';
import { SuccessModals } from '../../Treasurer/Modals/Portals';
import { Buttons } from '../../Treasurer/InactiveMembers/InactiveMembersStyle';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  ApprovalCompanyInvestment,
  FundApproval,
} from '../../../Const/Api/Api';
import { Colors } from '../../../Const/Colors/Theme';
import {
  dateBetweenCheck,
  displayDate,
  formatDate,
} from '../FundTransferDetails/FundTransferDetails';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { InvestmentFund } from '../../../features/notifications/notificationSlice';
import * as XLSX from 'xlsx'
import EditInvestmentAmount from '../Modal/EditInvestmentAmount';
import { FaEdit } from 'react-icons/fa';
import { numberFormatter } from '../../../NumberFormatter';

const InvestmentFundApproval = () => {
  const navigation = useNavigate();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateFilteredData, setDateFilteredData] = useState([]);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();
  const columns = [
    {
      Header: 'Sl No.',
      accessor: 'index', // accessor is the "key" in the data
    },
    {
      Header: 'Company Name',
      accessor: 'name',
    },
    {
      Header: 'Invested Amount',
      accessor: 'invested_amount',
    },
    {
      Header: 'Invested Date',
      accessor: 'investment_starting_date',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Treasurer Name',
      accessor: 'treasurer_name',
    },
    {
      Header: '',
      accessor: 'col7',
    },
  ];

  const getFundApprovalList = () => {
    axios.get(`${FundApproval}`).then((res) => {
      const data1 = [];
      if (res.data.code !== 401) {
        dispatch(InvestmentFund(res.data.length));
        res?.data?.map((item, index) => {
          data1.push({
            index: index + 1,
            id: item?.id,
            name: item?.name,
            location: item?.location,
            investment_starting_date: displayDate(
              item?.investment_starting_date
            ),
            new_invested_amount: item?.invested_amount ? (Math.round(Number(item?.invested_amount) * 100) / 100) : '0',
            invested_amount: (
              <>
                {numberFormatter(Math.round(Number(item?.invested_amount) * 100) / 100)} &nbsp;
                <Click
                  onClick={() => {
                    setEditData(item)
                    setShowEditModel(true)
                  }}
                >
                  <FaEdit />
                </Click>
              </>
            ),
            inactivated_date: displayDate(item?.inactivated_date),
            status: item?.status,
            investment_return: item?.investment_return,
            treasure_id: item?.treasure_id,
            treasurer_name: item?.treasurer_name,
            col7: (
              <Buttons onClick={() => handleClick(item?.id)}>Approve</Buttons>
            ),
          });
          setData(data1);
        });
      } else {
        setData([]);
        dispatch(InvestmentFund(0));
      }
    });
  };
  const handleClick = async (id) => {
    await axios
      .post(`${ApprovalCompanyInvestment}`, { id })
      .then((res) => {
        console.log('res=>', res);
        if (res.data.code == 200) {
          getFundApprovalList();
          swal({ text: res?.data?.message, icon: 'success' });
        } else {
          swal({ text: res?.data?.message, icon: 'warning' });
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getFundApprovalList();
  }, []);

  const filterClickHandler = () => {
    if (startDate === '' && endDate === '') {
      alert('Please select a date ');
    } else {
      setIsDateFilterActive(true);
      const filteredData = data.filter((item) => {
        const check = new Date(formatDate(item?.investment_starting_date));
        return dateBetweenCheck(startDate, endDate, check) && item;
      });
      setDateFilteredData(filteredData);
    }
  };

  // const exportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.autoTable({ html: '#my-table' });
  //   doc.save('table.pdf');
  // };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(isDateFilterActive ? dateFilteredData : data);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'data.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container>
      {showEditModel && (
        <EditInvestmentAmount setShowEditModel={setShowEditModel} getFundApprovalList={getFundApprovalList} data={editData} />
      )}
      <MenuBar secondary>
        <MenuText>Investment fund approval</MenuText>
        <MenuRightDiv>
          <RightItemsWrapper>
            <DateWrapper>
              <span>From</span>
              <input
                type='Date'
                onChange={(e) => setStartDate(e.target.value)}
              />
            </DateWrapper>
            <DateWrapper>
              <span>To</span>
              <input type='Date' onChange={(e) => setEndDate(e.target.value)} />
            </DateWrapper>
          </RightItemsWrapper>
          <Button onClick={filterClickHandler}>Apply</Button>
        </MenuRightDiv>
      </MenuBar>
      <Overflow>
        <Table>
          <label
            style={{
              width: 'fit-content',
              margin: '0 0 0 auto',
              color: Colors.blue,
              cursor: 'pointer',
            }}
            onClick={() => exportToExcel()}
          >
            Download
          </label>
          <AdminTable
            columns={columns}
            data={isDateFilterActive ? dateFilteredData : data}
          />
        </Table>
      </Overflow>
    </Container>
  );
};

export default InvestmentFundApproval;
