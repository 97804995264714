import React, { useEffect, useState } from "react";
import {
  Container,
  LoginContainer,
  Logos,
  Loginbar,
  Loginbtn,
  SuperLogin,
  SLoginbtn,
} from "./LoginStyled";
import { Logo } from "../../Const/assets";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  console.log("user: ", user);
  useEffect(() => {
    if (user == "Treasurer") {
      navigate("/signin", { state: { user: user, role: 2 } });
    } else if (user == "Fund Collector") {
      navigate("/signin", { state: { user: user, role: 1 } });
    } else if (user == "Super Admin") {
      navigate("/signin", { state: { user: user, role: 0 } });
    }  
  }, [user]);
  return (
    <Container>
      <LoginContainer>
        <Logos>
          <img src={Logo} alt="" />
        </Logos>
        <Loginbar>
          <Loginbtn
            onClick={() => {
              setUser("Treasurer");
              localStorage.setItem('rl', '01')
            }}>
            Login as Treasurer
          </Loginbtn>
          <Loginbtn
            onClick={() => {
              setUser("Fund Collector");
              localStorage.setItem('rl', '02')
            }}>
            Login as Fund Collector
          </Loginbtn>
        </Loginbar>
        <SuperLogin>
          <SLoginbtn
            onClick={() => {
              setUser("Super Admin");
              localStorage.setItem('rl', '00')
            }}>
            Super Admin
          </SLoginbtn>
        </SuperLogin>
      </LoginContainer>
    </Container>
  );
}

export default Login;
