import React, { useState } from 'react'
import { Amount, Flex, Label, ModalContent, Overlay, Update, Sub } from '../Modals/AddEibStyle'
import { BsCalendar4 } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'
import axios from 'axios'
import { updateMonthlySip } from '../../../Const/Api/Api'
import swal from 'sweetalert'

const AddSip = ({ setShowModal, bmi, getMonthlySip }) => {
    console.log('bmi: ', bmi);
    const [values, setValues] = useState({
        year: bmi?.Year,
        amount: bmi?.Monthly_Sip,
        bmi_id: bmi?.bmi_id,
        id: bmi?.id
    })
    console.log('values: ', values);
    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }
    const updateMonSip = async () => {
        await axios({
            method: 'post',
            url: updateMonthlySip,
            data: values
        }).then(resp => {
            if (resp?.data?.code == 200) {
                swal({ text: "Assigned monthly SIP", icon: "success" })
            } else {
                swal({ text: resp?.data?.message, icon: "warning" })
            }
            getMonthlySip()
        }).catch(e => {
            swal({ text: "Error", icon: "error" })
        })
    }

    return (
        <Overlay onClick={() => { setShowModal(false) }} >
            <ModalContent onClick={(e) => { e.stopPropagation() }}>
                <Flex>
                    <h4>{bmi?.Monthly_Sip == "0" ? "Add Monthly SIP" : "Edit Monthly SIP"} </h4>
                    <GrClose onClick={() => { setShowModal(false) }} />
                </Flex>
                <hr />
                <Sub>
                    <Label htmlFor='text' >Year</Label>
                    <Amount type="number" name="year" value={values.year} onChange={(e) => handleChange(e)} />
                    <Label htmlFor='amount' >Monthly SIP (AED)</Label>
                    <Amount type="number" name="amount" value={values.amount} onChange={(e) => handleChange(e)} />
                    <Update onClick={() => { setShowModal(false); updateMonSip() }}>{bmi?.Monthly_Sip == "0" ? "Add" : "Update"}</Update>
                </Sub>
            </ModalContent>

        </Overlay>
    )
}
export default AddSip